import getTrackingContext from '@/plugins/vue-clickstream/get-tracking-context'
import { onPageView } from '@/router/tracking'
import Vue from 'vue'

const beforeEachConfig = (router, store) => {
  router.beforeEach((to, from, next) => {
    store.dispatch('errors/clearHttpStatus', { root: true })
    store.dispatch('auth/checkAuthStatus')

    if (__BROWSER__) {
      if (window.googletag && typeof window.googletag.destroySlots === 'function') {
        window.googletag.destroySlots()
      }
    }

    next()
  })
}

/**
 * @param {import('vue-router').Route} to
 * @param {import('vue-router').Route} from
 * @returns
 */
function isFirstPage (to, from) {
  // on first page "from.name" is empty
  return !from.name
}

/**
 * it fixes the previous route of first page
 * @param {import('vue-router').default} router
 * @returns {import('vue-router').Route}
 */
function makeFakePreviousRoute (router) {
  if (!document.referrer) {
    return undefined
  }

  const referrer = new URL(document.referrer)

  if (referrer.host !== document.location.host) {
    return undefined
  }

  const route = router.resolve(referrer.pathname)
  return route.resolved
}

/**
 *
 * @param {import('vue-router').default} router
 */
const afterEachConfig = (router) => {
  router.afterEach((to, from) => {
    if (__BROWSER__) {
      let previous = from

      if (isFirstPage(to, from)) {
        previous = makeFakePreviousRoute(router)
        Vue.$clickstream.push(getTrackingContext)
      }

      Vue.$clickstream.push(onPageView(to, { previous }))
    }
  })
}

export default {
  beforeEachConfig,
  afterEachConfig,
}

