import { getByPath, getListing } from '@/api/page'
import transformerListing from '@/utils/transformers/listing'
import transformWidgets from '@/utils/transformers/widgets'
import { LISTING } from '@/utils/constants'
import transformerPublisher from '@/utils/transformers/publisher'
import localStorage from '@/storage/local-storage'
import basePageView from '@/utils/tracking/listing/base-page-view'
import { buildListingTitle, buildNormalizedSchemaImages } from '@/utils/listing'
import { useSelect } from '../core/use-select'
import { getDeduplicationGlueVariant } from '../listing-deduplication'

function fixSchema (schema, { listingLink }) {
  if (!schema) {
    return schema
  }

  return {
    ...schema,
    name: buildListingTitle(listingLink),
    image: buildNormalizedSchemaImages(schema.image, listingLink),
  }
}

// TODO: this is doing too much, we need to lower this func complexity
// eslint-disable-next-line
const listingFetch = async (store, { currentFullPath, routeQuery }) => {
  try {
    const select = useSelect(store)

    const { glueEndpoint } = store.rootGetters
    const { domain } = store.rootState.context
    let variations = store.rootGetters['ab/getAllVariationNames']
    const hideSummary = true

    const glueVariant = select(getDeduplicationGlueVariant)

    if (glueVariant) {
      variations = (variations ?? []).concat(glueVariant)
    }

    const response = await getByPath(
      glueEndpoint,
      currentFullPath,
      {
        includeFields: LISTING.INCLUDE_FIELDS,
        variations,
        domain,
        hideSummary,
        images: 'webp',
      },
      routeQuery,
    ) || {}
    // By some reason our response can be invalid (listing not found/dependency error/api down/etc),
    // response doesn't return our listing
    if (!response.data) return

    const {
      data: {
        listing,
        account,
        accountStats,
        medias,
        page: {
          metaContent,
          widgets,
          advertiserLinks,
          listingLinks,
          uriFragments: {
            address: alternateAddress,
          },
        },
        priceHistory,
        preview,
        schema,
        lastModified,
        abmi,
      },
    } = response

    const {
      rootState: {
        context: {
          glossary,
        },
      },
    } = store

    const businessTypeContext = localStorage.getBusinessTypeContext()
    const listingData = transformerListing({
      listing,
      medias,
      preview,
      priceHistory,
      alternateAddress,
      listingLinks,
      businessTypeContext,
      glossary,
    })

    if (!preview && (listing.status !== 'ACTIVE' || listing.deletedAt) && !listingData.isDefaulterInactive) {
      store.dispatch('errors/setHttpStatus', 404, { root: true })
      return
    }

    // WARNING: The order matters be careful!!!
    const publisher = transformerPublisher(account, accountStats, advertiserLinks)
    store.commit('page/setMetaContent', metaContent, { root: true })
    store.commit('page/setWidgets', transformWidgets(widgets), { root: true })
    store.commit('setPublisher', publisher)
    store.commit('setListing', listingData)
    store.commit('setLastModified', lastModified)
    store.commit('setAdvertiserPartner', abmi)

    const fixed = fixSchema(schema, listingData)
    store.commit('schema/setJson', fixed, { root: true })

    const trackingData = basePageView({ listing: listingData, publisher })
    store.commit('page/setPageViewTrackingData', trackingData, { root: true })
  } catch (e) {
    const { code, location } = e

    if (location) {
      throw e
    }

    store.dispatch('errors/setHttpStatus', code, { root: true })
  }
}

const advertiserPhonesFetch = async (store, listingId) => {
  if (store.state.listing.advertiserContact) return

  const { glueEndpoint } = store.rootGetters
  const { domain } = store.rootState.context
  const variations = store.rootGetters['ab/getAllVariationNames']

  const { data } = await getListing(glueEndpoint, listingId, {
    includeFields: 'listing(advertiserContact),account(config)',
    variations,
    domain,
  })

  store.commit('setListing', { ...store.state.listing, ...data.listing })
}

export default {
  listingFetch,
  advertiserPhonesFetch,
}
