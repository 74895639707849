import { getAttendanceQualityInfo } from '@/api/page'

const getAttendanceQuality = async (store, advertiserId) => {
  const emptyData = {
    stars: null,
    totalFeedbacks: 0,
    answeredRate: 0,
    totalRatings: 0,
    answeredLeads: 0,
    listAttendanceImprove: [],
    listAttendanceLiked: [],
  }
  try {
    const { glueEndpoint } = store.rootGetters
    const { domain } = store.rootState.context
    store.commit('setIsLoading', true)
    const { data } = await getAttendanceQualityInfo(glueEndpoint, advertiserId, { domain })
    store.commit('setAttendanceQualityInfo', !data ? emptyData : data)
    store.commit('setIsLoading', false)
  } catch {
    store.commit('setAttendanceQualityInfo', emptyData)
    store.commit('setIsLoading', false)
  }
}

export default {
  getAttendanceQuality,
}
