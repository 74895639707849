// @ts-check

/**
 * @param {import('./state').AuthState} state
 * @param {string} token
 */
const grantCredentials = (state, token) => {
  state.token = token
  state.authenticated = true
}

/**
 * @param {import('./state').AuthState} state
 */
const revokeCredentials = (state) => {
  state.token = null
  state.authenticated = false
}

/**
 * @param {import('./state').AuthState} state
 * @param {import('./state').User} user
 */
const setUser = (state, user) => {
  state.user = user
}

/**
 * @param {import('./state').AuthState} state
 */
const deleteUser = (state) => {
  state.user = null
}

/**
 * @param { string | null } [name]
 * @returns {[firstName: string, lastName: string]}
 */
function splitName (name) {
  const [firstName, lastName = ''] = (name ?? '').split(' ')
  return [firstName, lastName]
}

/**
 * @param { string | null } [phoneNumber]
 * @returns {[ddd: string, telefone: string]}
 */
function splitPhoneNumber (phoneNumber) {
  const ddd = phoneNumber?.slice(0, 2) ?? ''
  const telefone = phoneNumber?.slice(2) ?? ''
  return [ddd, telefone]
}

/**
 * @param {import('./state').AuthState} state
 * @param {import('../leads/state').Lead} contact
 * @returns {void}
 */
export function updateContactInfo (state, contact) {
  if (state.authenticated) {
    // do nothing, we shouldn't update the information obtained from the authentication service
    return
  }

  const [firstName, lastName] = splitName(contact.name)
  const [dddCelular, celular] = splitPhoneNumber(contact.phoneNumber)

  state.user = {
    ...state.user,
    nome: firstName,
    sobrenome: lastName,
    email: contact.email,
    dddCelular,
    celular,
    termsOptIns: contact.acceptTerms,
    // ...lead
  }
}

export default {
  setUser,
  deleteUser,
  updateContactInfo,
  revokeCredentials,
  grantCredentials,
}
