import { getAddressResume } from '@/filters/address'

export default {
  getPopup: (state) => state.popup,

  getPopupImage: (state) => {
    const { listing } = state.popup
    const { images = [] } = listing || {}

    if (images && images.length) {
      return images.find((image) => image.type === 'IMAGE')
    }

    return {}
  },

  getPopupLink: (state) => {
    const { link = {} } = state.popup

    return link
  },

  getPopupAddress: (state) => {
    const { listing = {} } = state.popup
    const { address = {} } = listing || {}

    return getAddressResume({ address })
  },
}
