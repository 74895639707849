import Vue from 'vue'
import cuid from 'cuid'

const groupId = cuid()
let cacheStatus

const processEvent = (obj) => {
  const payload = {
    groupId,
    cacheStatus,
    id: obj.id,
    name: obj.name,
    delta: obj.delta,
    value: obj.value,
    valueRounded: Math.round(obj.name === 'CLS' ? obj.value * 1000 : obj.value),
    deltaRounded: Math.round(obj.name === 'CLS' ? obj.delta * 1000 : obj.delta),
    attribution: obj.attribution,
  }

  Vue.$clickstream.track('webvitals', { ...payload, type: 'COREWEBVITALS' })
}

const sendGluePageTime = (gluePageTime) => {
  if (!gluePageTime) return

  processEvent({
    id: `glue-page-time-${cuid()}`,
    name: 'glue-page-time',
    value: gluePageTime,
    delta: gluePageTime,
  })
}

const sendGlueContentManagerTime = (glueContentManagerTime) => {
  if (!glueContentManagerTime) return

  processEvent({
    id: `glue-content-manager-time-${cuid()}`,
    name: 'glue-content-manager-time',
    value: glueContentManagerTime,
    delta: glueContentManagerTime,
  })
}

const sendAsyncDataTime = (asyncDataTime) => {
  if (!asyncDataTime) return

  processEvent({
    id: `ssr-async-data-time-${cuid()}`,
    name: 'ssr-async-data-time',
    value: asyncDataTime,
    delta: asyncDataTime,
  })
}

const sendRenderTime = (renderTime) => {
  if (!renderTime) return

  processEvent({
    id: `ssr-render-time-${cuid()}`,
    name: 'ssr-render-time',
    value: renderTime,
    delta: renderTime,
  })
}

const runWebVitalsTracking = async (additionalData) => {
  const {
    onLCP, onFID, onCLS, onTTFB, onFCP, onINP,
  } = await import(/* webpackChunkName: "webVitalsAttribution" */ 'web-vitals/attribution')

  cacheStatus = additionalData?.cacheStatus

  const run = () => {
    onFID(processEvent)
    onCLS(processEvent)
    onLCP(processEvent)
    onFCP(processEvent)
    onTTFB(processEvent)
    onINP(processEvent)

    sendGluePageTime(additionalData?.glue?.page)
    sendGlueContentManagerTime(additionalData?.glue?.contentManager)
    sendAsyncDataTime(additionalData?.ssr.asyncData)
    sendRenderTime(additionalData?.renderTime)
  }

  setTimeout(run, 2000) // Await load of GTM variables (platform, anonymous_id, etc)...
}

export default runWebVitalsTracking
