import { HttpStatusError } from '@/utils/error'

const routerReady = (router) => new Promise((resolve, reject) => {
  router.onReady(resolve, reject)
})

const handleRouteGuardError = (error) => {
  if (error instanceof HttpStatusError) {
    const url = error.location
    if (/^(\w+:)?\/\//.test(url)) {
      window.location.assign(url)
      return false
    }

    return url
  }

  return false
}

export {
  routerReady,
  handleRouteGuardError,
}
