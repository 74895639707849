import { getListingStats } from '@/api/page'

const fetchListingStats = async (store, listingId) => {
  store.commit('setLoading', true)
  try {
    const { glueEndpoint } = store.rootGetters
    const { domain } = store.rootState.context

    const { data } = await getListingStats(glueEndpoint, listingId, { domain })

    store.commit('setListingStats', data)
  } finally {
    store.commit('setLoading', false)
  }
}

export default {
  fetchListingStats,
}
