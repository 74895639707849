import { mapState, mapGetters } from 'vuex'
import { icArrowSmallDown, icUser } from '@vivareal/lina-icons'
import {
  makeMenu,
  makeSubMenu,
} from '@/fixture/header'
import { hiddeBodyScroll } from '@/utils/scroll'
import auth from '@/utils/auth'
import zapLogo from '@/assets/logos/brand-zap/zap.svg'
import { defineComponent } from 'vue'
import submenu from './components/oz-header-submenu'
import ozHeaderUser from './components/oz-header-user'
import ozDropdownMenu from './components/oz-dropdown-menu'

export default defineComponent({
  name: 'new-header',

  data () {
    return {
      icons: {
        icArrowSmallDown,
        icUser,
      },
      showFullscreen: false,
      zapLogo,
      logged: false,
      menuIsOpen: false,
      firstClick: false,
    }
  },

  computed: {
    ...mapState({
      isShowRefineSearchOnMobile: (state) => state.results.isShowRefineSearchOnMobile,
      isShowMobileMapButton: (state) => state.results.isShowMobileMapButton,
      isMapOpen: (state) => state.results.isMapOpen,
      savedSearchModal: (state) => state.ui.modals.savedSearchModal,
      featureToggle: (state) => state.context.featureToggle,
    }),

    ...mapGetters({
      user: 'auth/user',
      isLogged: 'auth/isLogged',
      fredoUrl: 'fredoUrl',
      isMobile: 'isMobile',
      isDesktop: 'isDesktop',
    }),

    headerIndex () {
      return {
        'new-header': true,
        'new-header__header-index--5': this.menuIsOpen,
        'new-header__header-index--4': this.isShowRefineSearchOnMobile || this.isMapOpen || (this.savedSearchModal.show && this.isMobile),
      }
    },

    menu () {
      const { fredoUrl } = this
      const isSeasonalCampaignActive = this.featureToggle?.seasonal_campaign_active_viva

      const { DESKTOP, MOBILE, MENU_BUTTONS } = makeMenu({ fredoUrl, isSeasonalCampaignActive })

      const loggedItems = MENU_BUTTONS.filter((item) => item.logged === this.logged)

      const headerMenuMobile = this.fillItemsMobile(MOBILE)

      return [
        ...DESKTOP,
        ...loggedItems,
        ...headerMenuMobile,
      ]
    },

    submenu () {
      const { fredoUrl } = this
      const isSeasonalCampaignActive = this.featureToggle?.seasonal_campaign_active_viva

      const { DESKTOP, MOBILE } = makeSubMenu({ fredoUrl, isSeasonalCampaignActive })

      const headerSubmenuMobile = this.fillItemsMobile(MOBILE)

      return [
        ...DESKTOP,
        ...headerSubmenuMobile,
      ]
    },
  },

  mounted () {
    this.logged = !!this.isLogged
  },

  methods: {
    handleLogin () {
      const { origin } = window.location
      const { loginUrl, currentFullPath } = this.$store.getters
      const redirectUrl = `${origin}${currentFullPath}`
      const fullLoginUrl = new URL(loginUrl)

      fullLoginUrl.searchParams.set('itl_id', 1000062)
      fullLoginUrl.searchParams.set('itl_name', 'zap_-_botao-header_entrar_to_zap_login')

      this.track({ name: 'Entrar', href: fullLoginUrl.origin })

      setTimeout(() => {
        window.location = auth.createLoginUrl(fullLoginUrl.href, 'entrar', redirectUrl)
      }, 100)
    },

    handleLogout () {
      this.logged = false
      this.track({ name: 'Sair', href: '' })
    },

    track (item = {}) {
      this.$clickstream.trackEvent({
        header: {
          subject: {
            link: item.name || '',
            url: item.href || '',
          },
        },
        event: 'headerLinkClicked',
      })
    },

    fillItemsMobile (items) {
      return items.map((item) => ({ ...item, mobile: true }))
    },

    setFullscreen (fullscreen) {
      this.menuIsOpen = fullscreen
      this.showFullscreen = fullscreen

      if (this.firstClick) {
        this.menuIsOpen = false
        this.showFullscreen = false
      }

      this.firstClick = !this.firstClick

      if (this.isMobile) hiddeBodyScroll()
    },

    onMenuFocusOut () {
      if (!this.isMobile) this.firstClick = false
    },
  },

  components: {
    submenu,
    ozHeaderUser,
    ozDropdownMenu,
  },
})
