/* eslint-disable max-classes-per-file */
// @ts-check

import cookieStorage from '@/storage/cookie-storage'
import { Tracker, Brand } from '@olxbr/tracking-sdk'
import { useSelect } from '@/store/core/use-select'
import { snakeCaseKeys } from '@/utils/transform-case'
import getTrackingContext from './get-tracking-context'

const generateSchema = async (...args) => {
  const { generateSchema: run } = await import('@vivareal/tracking')
  return run(...args)
}

const logger = console

/**
 * @template {{}} P
 * @typedef {P & { type: string }} StaticTrackingPayload
 */

/**
 * @template {string} T
 * @template {{}} P
 * @typedef {{ event: T } & StaticTrackingPayload<P>} StaticTrackingEvent
 */

/**
 * @template {{}} P
 * @typedef {Selector<StaticTrackingPayload<P>>} DynamicTrackingPayload
 */

/**
 * @template {string} T
 * @template {{}} P
 * @typedef {Selector<StaticTrackingEvent<T, P>>} DynamicTrackingEvent
 */

/**
 * @template {string} T
 * @template {{}} P
 * @typedef {StaticTrackingEvent<T, P> | DynamicTrackingEvent<T, P>} TrackingEvent
 */

/**
 * @template {{}} P
 * @typedef {StaticTrackingPayload<P> | DynamicTrackingPayload<P>} TrackingPayload
 */

/**
 * @typedef {{
 *  dataLayer: object[]
 *  debug: boolean
 *  store: import('vuex').Store<any>
 * }} TrackingOptions
 */

/**
 * @template T
 * @param {T | Selector<T>} selector
 * @returns {selector is Selector<T>}
 */
function isSelector (selector) {
  return typeof selector === 'function'
}

/**
 * @template T
 * @typedef { Promise<T> & {
 *  resolve(value: T): void
 *  snapshot(): T | undefined
 *  readonly done: boolean
 * }} PromiseHandler
 */

/** Plugin main class */
export default class ClickstreamPlugin {
  /** @param {TrackingOptions} options */
  constructor (options) {
    if (!options) {
      throw new Error('Options argument required')
    }

    /** @type {Tracker | undefined} */
    this.tracker = __BROWSER__ && process.env.NODE_ENV !== 'test'
      ? new Tracker({ brand: Brand.ZAP })
      : undefined

    /** @type {unknown[]} */
    this.gtm = options.dataLayer ?? []

    this.debug = options.debug ?? false

    const select = useSelect(options.store)

    /** @type <T>(selector: T | Selector<T>) => T */
    this.select = (selector) => (isSelector(selector) ? select(selector) : selector)
  }

  /**
   * @deprecated
   * @param {TrackingEvent<string, {}>} event
   * @returns {Promise<void>}
   */
  async trackEvent (event) {
    const data = this.select(event)
    const deviceSentTimestamp = Date.now()
    const userId = cookieStorage.getUserId()
    const payload = { ...data, ...await generateSchema(data) }

    return this.push(snakeCaseKeys({
      ...payload,
      userId: payload.userId || userId,
      deviceSentTimestamp,
    }))
  }

  /**
   * Send event to Tracking SDK
   *
   * @param {string} event
   * @param {TrackingPayload<{}>} payload
   * @returns {Promise<void>}
   */
  async track (event, payload) {
    const { type, ...props } = this.select(payload)
    const context = this.select(getTrackingContext)

    try {
      await this.tracker?.track(event, props, {
        dataLayer: false,
        context: { ...context, type },
      })

      this.log(event, props)
    } catch (err) {
      logger.error(err)
    }
  }

  /**
   * Push event to dataLayer without transformations
   *
   * @deprecated
   * @param {TrackingEvent<string, {}>} input
   * @returns {Promise<void>}
   */
  async push (input) {
    const event = {
      ...this.select(input),
      device_sent_timestamp: Date.now(),
    }

    try {
      await this.gtm.push(event)
      this.log(event.event, event)
    } catch (err) {
      logger.error(err)
    }
  }

  log (name, props) {
    if (this.debug) {
      logger.log(`%c${name}:`, 'color: #e53935', props ?? {}, new Date().toLocaleTimeString())
    }
  }
}

