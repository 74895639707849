// @ts-check

import { LEAD_TYPE } from '@/utils/constants'
import defineSelectorsOf from '../core/define-selectors-of'
import useAldo from '../core/use-aldo'
import { useSelect } from '../core/use-select'
import { DEDUPLICATION, getVariationOf } from '../experimentations'
import { getLeadContact } from '../leads/selectors'
import duplicatesModule, { canFetchNextPage } from './duplicates'
import stageModule, { ListingDeduplicationStage, inferTrackingScreen, inferTrackingSubject } from './stage'
import assert from '@/utils/assert'
import { buildLeadMessage } from '@/utils/transformers/messages/lead-message'
import { getGlossary, isFeatureEnabled } from '../context/selectors'
import { hasDuplicates } from '@/utils/listing'
import leadPanelClickedTracker from '@/utils/tracking/lead/lead-panel-clicked'
import modalViewTracker from '@/utils/tracking/deduplication/modal-view'
import logger from '@/utils/logger'
import tracker from '@/utils/tracking/lead/lead-clicked'
import rankingRenderedTracker from '@/utils/tracking/deduplication/ranking-rendered'
import encodePagination from '@/utils/tracking/deduplication/encode-pagination'
import LEAD_SUBJECT from '@/utils/constants/tracking/lead/subjects'

const PAGE_SIZE = 100

export { ListingDeduplicationStage }

/** @type {SelectorFactory<ListingDeduplicationState>} */
const defineSelector = defineSelectorsOf('listingDeduplication')

/**
 * @typedef {Object} ShowingDuplicatesScreen
 * @property {string} SelectableDuplicatesDrawer
 * @property {string} DuplicatesDrawer
 * @property {string} RecommendationCard
 */

export const ShowingDuplicatesScreen = /** @type {ShowingDuplicatesScreen} */ ({
  SelectableDuplicatesDrawer: 'DEDUPLICATION MULTIPLE LISTING CARD',
  DuplicatesDrawer: 'DEDUPLICATION LISTING CARD',
  RecommendationCard: 'POST LEAD',
})

export const isDeduplicationEnabled = defineSelector(
  (state, select) => {
    const [, { enabled }] = select(getVariationOf(DEDUPLICATION))
    return enabled && select(isFeatureEnabled('deduplication_new_zap'))
  },
)

/** it returns the listing that started the deduplication flow */
export const getDeduplicationSource = defineSelector(({ stage: { current: { source } } }) => {
  assert(source, { error: 'There is no source listing in the deduplication' })
  return source
})

export const isSubmittingLead = defineSelector((state) => state.submitting)

export const getDeduplicationStage = defineSelector((state) => state.stage.current.type)

/**
 *
 * @param {Selector<import('./duplicates').Listing>} getListing
 * @returns
 */
export function getDuplicatesCount (getListing) {
  return defineSelector((state, select) => {
    if (!select(isDeduplicationEnabled)) {
      return 0
    }

    /**
     * @param {import('./duplicates').Listing | undefined} listing
     * @returns {number | undefined}
     */
    function pickDuplicatesCount (listing) {
      return listing && ((listing.listingsCount ?? 1) - 1)
    }

    return (getListing && pickDuplicatesCount(select(getListing)))
        ?? state.duplicates?.content?.total
        ?? pickDuplicatesCount(state.stage?.current?.source)
  })
}

export const getPageSize = defineSelector(
  (state) => state?.duplicates?.filters?.pageSize ?? PAGE_SIZE,
)

export const getPreviousDeduplicationStage = defineSelector((state) => state.stage.previous?.type)

/** it returns the listings that was selected by the user */
export const getInterests = defineSelector((state) => {
  const { listing } = state.stage.current
  let { listings: interests = [] } = state.stage.current

  if (listing) {
    interests = interests.concat(listing)
  }

  return interests
})

export const getPreviousInterests = defineSelector((state) => state.stage.previous?.listings ?? [])

export const isDuplicatesSelectable = defineSelector((state, select) => {
  const [, { canSelectDuplicates }] = select(getVariationOf(DEDUPLICATION))
  return canSelectDuplicates
})

export const getDeduplicationGlueVariant = defineSelector((state, select) => {
  const enabled = select(isDeduplicationEnabled)
  const [, { glueVariant }] = select(getVariationOf(DEDUPLICATION))
  return enabled ? glueVariant : undefined
})

/**
 *
 * @param {Selector<import('./duplicates').Listing>} [getListing]
 * @returns
 */
export function canIncludeDuplicates (
  getListing = getDeduplicationSource,
) {
  return defineSelector(
    (state, select) => !select(isDuplicatesSelectable)
      && hasDuplicates(select(getListing)),
  )
}

export const getDuplicates = defineSelector(
  ({ duplicates: { content }, stage: { current: { source } } }) => content?.items
    .filter(({ id }) => id !== source?.id) ?? [],
)

export const getSelectedListing = defineSelector(({ stage: { current: { listing } } }) => listing)

export const getMaxSimultaneosLeads = defineSelector(() => 20)

export const getCanFetchNextPage = defineSelector(({ duplicates }) => canFetchNextPage(duplicates))

/**
 * Ensure that all duplicates are from the same source
 *
 * @param {import('@/store/listing/state').Listing} source
 * @param {import('./stage').Listings} dupls
 */
function assertSource (source, dupls) {
  assert(dupls && dupls.every((dupl) => dupl.sourceId === source.sourceId), {
    error: 'Duplicates must be from the same source',
  })
}

/**
 * @typedef {{
 *  listing: import('@/store/listing/state').Listing;
 *  duplicates?: import('./duplicates').Duplicate[];
 * }} WriteMessageOptions
 */

/**
 * @typedef {object} ListingDeduplicationState
 * @property {boolean} submitting
 * @property {import('./duplicates').DuplicatesState} duplicates
 * @property {import('./stage').State} stage
 */

/**
 * @typedef {Omit<import('@/store/leads/state').Lead, 'id'> & {
 *  type: import('@/utils/constants/leads').LeadType
 *  reUse?: boolean
 *  listing?: import('./stage').ListingLike
 *  listings?: import('./stage').Listings
 *  includeDuplicates?: boolean
 *  tracking?: TrackingOptions
 * }} SubmitOptions
 */

/**
 * @param {import('vuex').ActionContext<ListingDeduplicationState & {
 *   stage: import('./stage').State
 * }, any>} context
 * @param {SubmitOptions} options
 * @returns {import('@/store/listing/state').Listing}
 */
function inferSource (context, { listing }) {
  let { source } = context.state.stage.current
  const { type: stage } = context.state.stage.current

  if (!source && stage === ListingDeduplicationStage.Initial) {
    // it means that the deduplication flow was not started,
    // the lead is coming from the listing page

    source = /** @type {import('@/store/listing/state').Listing} */ (listing)
  }

  assert(source, { error: 'It isn\'t possible to infer the listing source' })

  return source
}

/**
 * @param {import('vuex').ActionContext<ListingDeduplicationState, any>} context
 * @param {{ source: import('@/store/listing/state').Listing } & SubmitOptions} options
 * @returns {Promise<[LeadMode, import('./stage').Listings]>}
 */
async function pickListingsToSubmit (context, {
  listing,
  listings,
  includeDuplicates,
  source,
}) {
  const select = useSelect(context)

  listings ??= context.state.stage.current.listings

  if (!listings?.length) {
    listing ??= context.state.stage.current.listing
    assert(listing)

    const target = listing
    const [, { alwaysSendLeadToDuplicates }] = select(getVariationOf(DEDUPLICATION))

    listings = [target]

    if (includeDuplicates && alwaysSendLeadToDuplicates) {
      await context.dispatch('duplicates/sync', { listing: source })

      const duplicates = select(getDuplicates)
        .filter(({ id }) => id !== target.id)
        .slice(0, alwaysSendLeadToDuplicates)

      listings = listings.concat(duplicates)
    }
  }

  return [listing ? 'SINGLE' : 'MULTIPLE', listings]
}

/**
 * if single, it means that the flow started with a single interest
 * @typedef {'SINGLE' | 'MULTIPLE'} LeadMode
 */

/**
 * @param {import('vuex').ActionContext<ListingDeduplicationState, any>} context
 * @param {SubmitOptions} input
 * @returns {Promise<{
 *    source: import('@/store/listing/state').Listing
 *    tracking?: TrackingOptions
 *    listings: import('./stage').Listings
 *    mode: LeadMode
 *  } & import('../core/use-aldo').BatchInput
 * >}
 */
// eslint-disable-next-line complexity
async function inferMissingSubmitOptions (context, input) {
  const select = useSelect(context)
  const source = inferSource(context, input)

  const contact = select(getLeadContact)
  const [mode, listings] = await pickListingsToSubmit(context, { ...input, source })

  return {
    type: input.type,
    acceptTerms: input.acceptTerms ?? true,
    name: input.name || contact?.name,
    email: input.email || contact?.email,
    phoneNumber: input.phoneNumber || contact?.phoneNumber,
    message: input.message || buildLeadMessage(source, {
      glossary: select(getGlossary),
      omitPrice: true,
    }),
    reUse: input.reUse || false,
    businessType: source.pricingInfo?.businessType ?? 'SALE',
    tracking: input.tracking,
    source,
    mode,
    listings,
  }
}

/** @typedef {Awaited<ReturnType<typeof inferMissingSubmitOptions>>} LeadOptions */

/**
 * @typedef {import('@/utils/tracking/lead/lead-clicked').TrackingProps & {
 *   pagination?: import('@/utils/tracking/lead/lead-clicked').PaginationProps
 *   listingPosition?: number
 * }} TrackingOptions
 */

/**
 * @param {import('vuex').ActionContext<ListingDeduplicationState, any>} context
 * @param {InferTrackingInput} input
 * @returns {number | undefined}
 */
function inferListingPosition (context, { listing }) {
  const stage = context.state.stage.current

  if (!stage.source || stage.source.id === listing.id) {
    // it means that the lead is the source of the deduplication flow, so it isn't paginated
    // TODO: handle with pagination on the results page
    return undefined
  }

  const { duplicates } = context.state

  return duplicates.content?.items.findIndex(({ id }) => id === listing.id)
}

/**
 * @param {import('vuex').ActionContext<ListingDeduplicationState, any>} context
 * @param {InferTrackingInput} input
 * @returns {import('@/utils/tracking/lead/lead-clicked').PaginationProps | undefined}
 */
function inferPaginationProps (context, input) {
  const stage = context.state.stage.current
  const { listing } = input

  if (!stage.source || stage.source.id === listing.id) {
    // it means that the lead is the source of the deduplication flow, so it isn't paginated
    // TODO: handle with pagination on the results page
    return undefined
  }

  const positionRanking = inferListingPosition(context, input)
  return {
    currentPage: 0,
    ...(positionRanking && { positionRanking }),
  }
}

/**
 * @typedef {LeadOptions & {
 *   lead: import('@/store/core/use-aldo').Lead;
 *   listing: import('@/store/listing/state').Listing | import('./duplicates').Duplicate;
 * }} InferTrackingInput
 */

/**
 * @param {import('vuex').ActionContext<ListingDeduplicationState, any>} context
 * @param {InferTrackingInput} input
 * @returns {TrackingOptions}
 */
// eslint-disable-next-line complexity
function inferMissingTrackingOptions (context, input) {
  const { source, type: leadType, listing } = input
  const { type: stage, listing: interest } = context.state.stage.current
  let { listings: interests = [] } = context.state.stage.current

  if (interest) {
    interests = interests.concat(interest)
  }

  const wasStartedOutsiteDeduplicationFlow = !interests.length
    && stage === ListingDeduplicationStage.Initial

  if (wasStartedOutsiteDeduplicationFlow) {
    // it means that the lead is coming from outside the deduplication flow
    interests = interests.concat(source)
  }

  const isRecommendation = input.tracking?.subject === LEAD_SUBJECT.LEAD_CLICKED.POST_LEAD

  const { type: previous } = context.state.stage.previous ?? {}

  const selected = interests.some(({ id }) => id === listing.id)

  return {
    pagination: input.tracking?.pagination ?? inferPaginationProps(context, input),
    listingPosition: input.tracking?.listingPosition ?? inferListingPosition(context, input),
    screen: input.tracking?.screen ?? inferTrackingScreen(stage, { leadType }),
    subject: (selected && input.tracking?.subject)
      || (isRecommendation && input.tracking?.subject)
      || inferTrackingSubject(stage, { previous, leadType, selected }),
  }
}

/**
 * @param {import('vuex').ActionContext<ListingDeduplicationState, any>} context
 * @param {LeadOptions & { lead: import('../core/use-aldo').Lead }} input
 */
function trackLeadClicked (context, input) {
  const {
    type,
    lead,
    businessType,
  } = input

  input.listings.forEach((listing) => {
    const {
      pagination,
      listingPosition,
      ...tracking
    } = inferMissingTrackingOptions(context, { ...input, listing })

    tracker.leadClicked({
      type,
      listing,
      lead,
      tracking,
      pagination,
      listingPosition,
      businessType,
      filters: undefined,
    })
  })
}

/**
 * @param {import('vuex').ActionContext<ListingDeduplicationState, any>} context
 */
function trackRankingRendered (context) {
  const { source, type } = context.state.stage.current
  const { content, filters } = context.state.duplicates

  if (type === ListingDeduplicationStage.ShowingDuplicates) {
    const page = content?.page ?? 1
    const pageSize = filters?.pageSize ?? 0

    const from = ((page - 1) * pageSize)
    rankingRenderedTracker.rankingRendered({
      filter: {
        businessType: source?.pricingInfo.businessType,
        listingTypes: source?.listingType,
      },
      pagination: encodePagination({
        page,
        pageSize,
        total: content?.total ?? 0,
      }),
      listings: content?.items?.slice(from, from + pageSize) ?? [],
      action: 'DEDUPLICATION MODAL OPEN',
    })
  }
}

/**
 * @type {import('vuex').Module<ListingDeduplicationState, any>}
 */
export default {
  namespaced: true,

  state: () => /** @type {ListingDeduplicationState} */ ({
    submitting: false,
  }),

  modules: {
    duplicates: duplicatesModule,
    stage: stageModule,
  },

  mutations: {
    onSubmitStarted (state) {
      state.submitting = true
    },
    onSubmitEnded (state) {
      state.submitting = false
    },
  },

  actions: {
    cancel (context) {
      const select = useSelect(context)
      const currentStage = select(getDeduplicationStage)
      const previousStage = select(getPreviousDeduplicationStage)

      if (currentStage === ListingDeduplicationStage.ShowingDuplicates) {
        modalViewTracker.modalView({
          action: 'CLOSE',
          origin: previousStage === ListingDeduplicationStage.Initial ? 'OPEN' : 'BACK TO MODAL',
        })
      }

      context.commit('stage/backToStart')
    },

    writeMessage (
      context,
      /**
       * @type {{
       *  listing?: import('@/store/listing/state').Listing
       *  listings?: import('./stage').Listings
       *  screen?: typeof ShowingDuplicatesScreen[keyof typeof ShowingDuplicatesScreen]
       * }}
       */ {
        listing, listings, screen,
      },
    ) {
      const isRecommendationCTA = screen === ShowingDuplicatesScreen.RecommendationCard
      const select = useSelect(context)
      const source = screen === ShowingDuplicatesScreen.RecommendationCard
        ? listing
        : select(getDeduplicationSource)

      if (listings?.length) {
        assertSource(source, listings)
      } else {
        assert(listing)
        assertSource(source, [listing])
      }

      context.commit('stage/writeMessage', { source, listings, listing })

      leadPanelClickedTracker.leadPanelClicked({
        listingId: (listings?.[0] ?? listing)?.id,
        subject: screen,
        action: 'OPEN',
        screen: isRecommendationCTA ? 'SHOWCASE' : 'DEDUPLICATION RANKING',
      })
    },

    async fetchDuplicates (
      context,
      /** @type {{ handleError?: boolean, pageSize?: number }} */ {
        handleError = false,
        pageSize = PAGE_SIZE,
      } = {},
    ) {
      const select = useSelect(context)
      const source = select(getDeduplicationSource)

      try {
        await context.dispatch('duplicates/sync', { listing: source, pageSize })

        trackRankingRendered(context)
      } catch (err) {
        if (handleError) {
          context.commit('stage/onError', { source, error: err })
        }

        throw err
      }
    },

    showPhoneNumber (
      context,
      /** @type {Partial<{ listing: import('@/store/listing/state').Listing }>} */ { listing },
    ) {
      context.dispatch('submit', { listing, type: LEAD_TYPE.PHONE })
    },

    async showDuplicates (
      context,
      /** @type {{ listing: import('@/store/listing/state').Listing }} */ { listing },
    ) {
      context.commit('stage/showDuplicates', { source: listing })

      modalViewTracker.modalView({
        action: 'OPEN',
        origin: 'DETAIL PAGE',
      })
    },

    goBack (context) {
      context.commit('stage/goBack')

      modalViewTracker.modalView({
        action: 'OPEN',
        origin: 'BACK TO MODAL',
      })
    },

    async fetchNextDuplicatesPage (context) {
      await context.dispatch('duplicates/fetchNextPage')
      trackRankingRendered(context)
    },

    async submit (
      context,
      /** @type {SubmitOptions} */ options,
    ) {
      assert(options.type, { error: 'The (lead) type is required to send a lead' })
      assert(!context.state.submitting, { error: 'It is not possible to submit a lead while another is being submitted' })

      const aldo = useAldo(context)
      const select = useSelect(context)

      const { source, mode, ...payload } = await inferMissingSubmitOptions(context, options)

      try {
        context.commit('onSubmitStarted')

        const lead = await aldo.batch(payload)
        const [, { canSelectDuplicates }] = select(getVariationOf(DEDUPLICATION))
        trackLeadClicked(context, {
          ...payload, source, mode, lead,
        })

        context.commit('stage/onSubmitSuccess', {
          source,
          leadType: options.type,
          listings: payload.listings,
          showDuplicates: canSelectDuplicates && mode === 'SINGLE' && hasDuplicates(source),
        })
        context.commit('leads/setLead', lead, { root: true })
      } catch (err) {
        context.commit('stage/onError', { source, error: err })
        logger.error(err)
        throw err
      } finally {
        context.commit('onSubmitEnded')
      }
    },
  },
}

