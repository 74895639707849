import { icArrowSmallDown } from '@vivareal/lina-icons'

export default {
  name: 'oz-header-user',

  data () {
    return {
      icArrowSmallDown,
    }
  },

  props: {
    isSubmenu: { type: Boolean, default: false },
    user: { type: Object, required: true },
  },

  computed: {
    firstName () {
      return this.user?.name?.split(' ')[0] || ''
    },

    name () {
      return this.isSubmenu ? this.user.name : this.firstName
    },
  },
}
