export const GZUID_DATA = {
  iframeInlineStyle: {
    border: 0,
    bottom: '100%',
    height: 0,
    position: 'fixed',
    width: 0,
  },
  domain: 'https://gzuis.grupozap.com',
}

export const SCHEMA_IMAGE_SIZES = [
  '250x250', // 1x1
  '870x653', // 4x3
  '420x236', // 16x9
]

export const noIndexQueryStringParams = {
  quartos: true,
  banheiros: true,
  vagas: true,
  precoMinimo: true,
  precoMaximo: true,
  precoMinimoCondo: true,
  precoMaximoCondo: true,
  rentalTotalPriceMin: true,
  rentalTotalPriceMax: true,
  areaMinima: true,
  areaMaxima: true,
  areaTotalMinima: true,
  areaTotalMaxima: true,
  proximoAoMetro: true,
  id: true,
  endereco: true,
  transacao: true,
  tipo: true,
  ordem: true,
  tipoUnidade: true,
  onde: true,
  visitaVirtual: true,
  gclid: true,
  amenities: true,
  warranties: true,
  stamps: true,
  tipoAluguel: true,
}
