// @ts-check

import cookieStorage from '@/storage/cookie-storage'
import localStorage from '@/storage/local-storage'
import defineSelectorsOf from '../core/define-selectors-of'
import getters from './getters'

/** @type {SelectorFactory<import('./state').AuthState>} */
const defineSelector = defineSelectorsOf('auth')

export const getAnonymousId = defineSelector(getters.anonymousId)

export const getUserContact = defineSelector((state) => {
  const {
    name,
    email,
    phone,
  } = getters.user(state)

  if (email || phone) {
    /** @type {import('./state').UserContact} */
    const contact = {
      name: name || undefined,
      email: email || undefined,
      phoneNumber: phone || undefined,
    }

    return contact
  }

  return null
})

export const getAuthInfo = defineSelector((state, select) => {
  const anonymousId = select(getAnonymousId)
  const userId = cookieStorage.getUserId()
  const sessionId = localStorage.getSessionId()
  const gzuid = localStorage.getGzuid()

  return ({
    userId,
    anonymousId,
    gzuid,
    sessionId,
    loginStatus: state.authenticated,
    loginType: undefined,
  })
})
