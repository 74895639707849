/* eslint-disable complexity */
import { getListings } from '@/api/page'
import { fieldsTransform } from '@/utils/transformers/results'
import { PAGE_SIZE_DEFAULT, PAGE_SIZE_100, PAGE_SIZE_PUBLISHER } from '@/utils/constants/results'
import { transformLocationMapper } from '@/utils/transformers/location'
import { glueResponseHandler, handleExpandedResults, onFetch } from './util'

const search = async (store, filters = {}) => {
  store.commit('setLoading', true)
  try {
    const { domain, featureToggle } = store.rootState.context
    const publisherPage = filters?.categoryPage === 'PUBLISHER'
    const isCampaignPage = filters?.categoryPage === 'CAMPAIGN'
    const {
      addresses = [
        {
          locationId: 'BR',
        },
      ],
      ...restFilters
    } = filters

    const filtersHasValue = Object.entries(restFilters)
      .reduce((treatedObj, [key, value]) => {
        if (!value) {
          return treatedObj
        }

        const mountObj = { [key]: value }
        return { ...treatedObj, ...mountObj }
      }, {})

    const isPageSize100 = featureToggle.zap_page_size_100 ? PAGE_SIZE_100 : PAGE_SIZE_DEFAULT

    const rawFilters = (sendToGlue = false) => {
      const filter = sendToGlue ? filtersHasValue : restFilters
      return {
        addresses,
        ...filter,
        size: publisherPage ? PAGE_SIZE_PUBLISHER : isPageSize100,
      }
    }

    const formatedFilters = fieldsTransform(
      {
        fields: rawFilters(true),
        publisherPage,
        isCampaignPage,
        isPageSize100,
      },
    )
    const { glueEndpoint } = store.rootGetters

    const { levels } = store.rootState.results
    const variations = store.rootGetters['ab/getAllVariationNames']
    const transformerAddress = transformLocationMapper(filters?.addresses)

    const levelsParameter = levels || (transformerAddress.addressType?.toUpperCase() ?? '')
    const ref = transformerAddress.addressUrl ?? ''

    const response = await getListings(
      glueEndpoint,
      {
        ...formatedFilters,
        variations,
        domain,
        levels: levelsParameter,
        ref,
        images: 'webp',
      },
    )

    if (!response?.data) return

    const {
      listings,
      totalCount,
      totalCounts,
      developments,
      superPremium,
      metaContent,
      widgets,
      uriPagination,
      uriCategory,
      path,
      account,
      facets,
      accountStats,
    } = glueResponseHandler(response.data)

    const expandedListings = handleExpandedResults({
      store,
      developments,
      superPremium,
      listings,
    })

    const levelsUriCategory = uriCategory?.levels?.join() ?? ''

    onFetch({
      store,
      listings: expandedListings,
      filters: rawFilters(),
      uriPagination: { ...uriPagination, totalCount },
      metaContent,
      widgets,
      path,
      account,
      accountStats,
      seasonalCampaigns: response.data.seasonalCampaigns,
      categoryPage: uriCategory.page || 'RESULT',
      levels: levelsUriCategory,
      uriCategory,
      totalCount,
      totalCounts,
      facets,
    })
  } catch (e) {
    const { location, code } = e

    if (location) {
      throw e
    }

    store.dispatch('errors/setHttpStatus', code, { root: true })
  } finally {
    store.commit('setLoading', false)
  }
}

const paginate = async (store, page) => {
  const { pageSize: size } = store.state.pagination

  const from = size * (page - 1)

  store.commit('paginate', page)
  const query = {
    ...store.state.filters,
    from,
    size,
    page,
  }

  await search(store, query)
}

const sort = async (store, sortParam) => {
  store.commit('sort', sortParam)
  await search(store, store.state.filters)
}

export {
  search,
  paginate,
  sort,
}
