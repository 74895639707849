const setHomePageContent = (state, homePageContent = {}) => {
  state.homePageContent = homePageContent
}

const setNeighborhoodsStates = (state, neighborhoodsStates = []) => {
  state.neighborhoodsStates = neighborhoodsStates
}

export default {
  setHomePageContent,
  setNeighborhoodsStates,
}
