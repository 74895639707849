import apiClient from './client'

export async function getNeighborhoodsGuideHome () {
  const url = 'https://prod-growth.s3.amazonaws.com/neighborhood_guide/home.json'
  const result = await apiClient.get(url)
  return result?.data
}

export default { getNeighborhoodsGuideHome }

