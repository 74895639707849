const USAGE_TYPE_DEFAULT = 'UsageType_NONE'
const UNIT_TYPE_DEFAULT = 'UnitType_NONE'

const TYPES_MAPPER = {
  COMMERCIAL: {
    label: 'Comercial',
  },
  RESIDENTIAL: {
    label: 'Residencial',
  },
  FARM: {
    label: 'Rural',
  },
}

const DEVELOPMENT_MAPPER = {
  RESIDENTIAL: {
    items: [{
      text: 'Apartamento',
    }, {
      text: 'Casa',
    }, {
      text: 'Casa de Condomínio',
    }, {
      text: 'Cobertura',
    }, {
      text: 'Flat',
    }, {
      text: 'Loft',
    }, {
      text: 'Terreno / Lote / Condomínio',
    }],
  },
  COMMERCIAL: {
    items: [{
      text: 'Loja / Salão / Ponto Comercial',
    }, {
      text: 'Prédio Inteiro',
    }, {
      text: 'Conjunto Comercial / Sala',
    }, {
      text: 'Hotel / Motel / Pousada',
    }, {
      text: 'Terrenos / Lotes Comerciais',
    }],
  },
}

const DEVELOPMENT_MAPPER_TEXTS = {
  RESIDENTIAL: ['Apartamento', 'Casa', 'Casa de Condomínio', 'Cobertura', 'Flat', 'Loft', 'Terreno / Lote / Condomínio'],
  COMMERCIAL: ['Loja / Salão / Ponto Comercial', 'Prédio Inteiro', 'Conjunto Comercial / Sala', 'Hotel / Motel / Pousada', 'Terrenos / Lotes Comerciais'],
}

export default {
  TYPES_MAPPER,
  DEVELOPMENT_MAPPER,
  DEVELOPMENT_MAPPER_TEXTS,
  USAGE_TYPE_DEFAULT,
  UNIT_TYPE_DEFAULT,
}
