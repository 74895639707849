import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'

const getUF = (value, glossary) => {
  const states = get(glossary, 'items.state.items')
  if (states) {
    const stateSelected = Object.keys(states).filter((uf) => states[uf].singular === value)[0]
    if (!isNil(stateSelected)) return stateSelected
  }
  return value
}

const address = ({ address: rawAddress, glossary }) => {
  if (!rawAddress) return null

  const {
    street: rawStreet,
    streetNumber: rawStreetNumber,
    neighborhood: rawNeighborhood,
    city: rawCity,
    state: rawState,
  } = rawAddress

  const streetNumber = rawStreetNumber ? `, ${rawStreetNumber}` : ''
  const street = rawStreet ? `${rawStreet}${streetNumber} -` : ''
  const neighborhood = rawNeighborhood ? `${rawNeighborhood},` : ''
  const city = rawCity ? `${rawCity} -` : ''
  const state = rawState ? `${getUF(rawState, glossary)}` : ''

  return [street, neighborhood, city, state].filter((item) => !isEmpty(item)).join(' ')
}

export function getAddressResume ({ address: rawAddress }) {
  if (!rawAddress) return ''

  const {
    city,
    neighborhood,
    street,
  } = rawAddress

  if (!street) return `${neighborhood}, ${city}`

  return `${street}, ${neighborhood}`
}

export default address
export { getUF }
