class Gzuis {
  get iframe () {
    if (typeof document !== 'undefined') {
      return document.querySelector('iframe[src*=gzuis]')
    }

    return null
  }

  get do () {
    return this.iframe?.contentWindow?.postMessage.bind(this.iframe?.contentWindow)
  }

  setCookie (cookies) {
    this.do?.({
      type: 'writeCookie',
      cookies: cookies.map((c) => ({
        path: '/',
        name: c.name,
        secure: true,
        value: c.value,
        sameSite: 'None',
        domain: '.grupozap.com',
      })),
    }, '*')
  }

  deleteCookie (cookies) {
    this.do?.({
      type: 'deleteCookie',
      cookies: cookies.map((c) => ({
        name: c,
        path: '/',
        secure: true,
        sameSite: 'None',
        domain: '.grupozap.com',
      })),
    }, '*')
  }

  getCookie (cookies) {
    this.do?.({ type: 'readCookie', cookies }, '*')
  }

  resendGzuid () {
    this.do?.({ type: 'sendGzuid' }, 'https://gzuis.grupozap.com/')
  }

  listen (key, fn) {
    window?.addEventListener('message', (e) => {
      const current = e.data[key]
      if (current) { fn(current) }
    })
  }
}

const instance = new Gzuis()

export default instance
