import Vue from 'vue'

const ASKING_LOCATION_DELAY_MS = 8000

export default {
  mounted () {
    setTimeout(() => {
      this.$store.dispatch('userLocation/setUserLocation', {
        ipFallback: false,
      })
        .then(({ latitude, longitude, accuracy }) => {
          Vue.$clickstream.trackEvent({
            accuracyLatLong: accuracy,
            connectionLatitude: latitude,
            connectionLongitude: longitude,
          })
        })
        .catch(Boolean)
    }, ASKING_LOCATION_DELAY_MS)
  },
}
