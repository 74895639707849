const AMENITIES = [
  { id: 'PETS_ALLOWED', text: 'Aceita pets', category: 'Características do imóvel' },
  { id: 'SAFETY_CIRCUIT', text: 'Circuito de segurança', category: 'Segurança' },
  { id: 'GATED_COMMUNITY', text: 'Condomínio fechado', category: 'Segurança' },
  { id: 'ELECTRONIC_GATE', text: 'Portão eletrônico', category: 'Segurança' },
  { id: 'CONCIERGE_24H', text: 'Portaria 24h', category: 'Segurança' },
  { id: 'ALARM_SYSTEM', text: 'Sistema de alarme', category: 'Segurança' },
  { id: 'WATCHMAN', text: 'Vigia', category: 'Segurança' },
  {
    id: 'GYM', text: 'Academia', category: 'Lazer e esporte', path: 'academia',
  },
  {
    id: 'BARBECUE_GRILL', text: 'Churrasqueira', category: 'Lazer e esporte', path: 'churrasqueira',
  },
  { id: 'CINEMA', text: 'Cinema', category: 'Lazer e esporte' },
  {
    id: 'GOURMET_SPACE', text: 'Espaço gourmet', category: 'Lazer e esporte', path: 'espaco-gourmet',
  },
  { id: 'GREEN_SPACE', text: 'Espaço verde/Parque', category: 'Lazer e esporte' },
  {
    id: 'GARDEN', text: 'Jardim', category: 'Lazer e esporte', path: 'jardim',
  },
  {
    id: 'POOL', text: 'Piscina', category: 'Lazer e esporte', path: 'piscina',
  },
  {
    id: 'HIKING_TRAIL', text: 'Pista de cooper', category: 'Lazer e esporte', path: 'pista-de-cooper',
  },
  {
    id: 'PLAYGROUND', text: 'Playground', category: 'Lazer e esporte', path: 'playground',
  },
  {
    id: 'SQUASH', text: 'Quadra de squash', category: 'Lazer e esporte', path: 'quadra-de-squash',
  },
  {
    id: 'TENNIS_COURT', text: 'Quadra de tênis', category: 'Lazer e esporte', path: 'quadra-de-tenis',
  },
  {
    id: 'SPORTS_COURT', text: 'Quadra poliesportiva', category: 'Lazer e esporte', path: 'quadra-poliesportiva',
  },
  {
    id: 'PARTY_HALL', text: 'Salão de festas', category: 'Lazer e esporte', path: 'salao-de-festas',
  },
  {
    id: 'ADULT_GAME_ROOM', text: 'Salão de jogos', category: 'Lazer e esporte', path: 'salao-de-jogos',
  },
  { id: 'DISABLED_ACCESS', text: 'Acesso para deficientes', category: 'Condomínio' },
  { id: 'BICYCLES_PLACE', text: 'Bicicletário', category: 'Condomínio' },
  { id: 'COWORKING', text: 'Coworking', category: 'Condomínio' },
  {
    id: 'ELEVATOR', text: 'Elevador', category: 'Condomínio', path: 'elevador',
  },
  { id: 'ELECTRIC_GENERATOR', text: 'Gerador elétrico', category: 'Condomínio' },
  { id: 'GRASS', text: 'Gramado', category: 'Condomínio' },
  { id: 'LAUNDRY', text: 'Lavanderia', category: 'Condomínio' },
  { id: 'RECEPTION', text: 'Recepção', category: 'Condomínio' },
  {
    id: 'SAUNA', text: 'Sauna', category: 'Condomínio', path: 'sauna',
  },
  { id: 'SPA', text: 'SPA', category: 'Condomínio' },
  {
    id: 'AIR_CONDITIONING', text: 'Ar-Condicionado', category: 'Características do imóvel', path: 'ar-condicionado',

  },
  {
    id: 'BUILTIN_WARDROBE', text: 'Armário embutido', category: 'Características do imóvel', path: 'armario-embutido',
  },
  { id: 'BEDROOM_WARDROBE', text: 'Armário embutido no quarto', category: 'Características do imóvel' },
  {
    id: 'KITCHEN_CABINETS', text: 'Armários na cozinha', category: 'Características do imóvel', path: 'armario-de-cozinha',
  },
  { id: 'BATHROOM_CABINETS', text: 'Armário no banheiro', category: 'Características do imóvel' },
  {
    id: 'SERVICE_AREA', text: 'Área de serviço', category: 'Características do imóvel', path: 'area-de-servico',
  },
  { id: 'BATHTUB', text: 'Banheira', category: 'Características do imóvel' },
  {
    id: 'BLINDEX_BOX', text: 'Box blindex', category: 'Características do imóvel', path: 'blindex',
  },
  { id: 'GAS_SHOWER', text: 'Chuveiro a gás', category: 'Características do imóvel' },
  {
    id: 'CLOSET', text: 'Closet', category: 'Características do imóvel', path: 'closet',
  },
  { id: 'INTERNET_ACCESS', text: 'Conexão a internet', category: 'Características do imóvel' },
  {
    id: 'AMERICAN_KITCHEN', text: 'Cozinha americana', category: 'Características do imóvel', path: 'cozinha-americana',
  },
  { id: 'GOURMET_KITCHEN', text: 'Cozinha gourmet', category: 'Características do imóvel' },
  { id: 'LARGE_KITCHEN', text: 'Cozinha grande', category: 'Características do imóvel' },
  { id: 'DEPOSIT', text: 'Depósito', category: 'Características do imóvel' },
  {
    id: 'HOME_OFFICE', text: 'Escritório', category: 'Características do imóvel', path: 'escritorio',
  },
  { id: 'COOKER', text: 'Fogão', category: 'Características do imóvel' },
  { id: 'FREEZER', text: 'Freezer', category: 'Características do imóvel' },
  {
    id: 'INTERCOM', text: 'Interfone', category: 'Características do imóvel', path: 'interfone',
  },
  { id: 'LARGE_WINDOW', text: 'Janelas grandes', category: 'Características do imóvel' },
  {
    id: 'FIREPLACE', text: 'Lareira', category: 'Características do imóvel', path: 'lareira',
  },
  {
    id: 'FURNISHED', text: 'Mobiliado', category: 'Características do imóvel', path: 'mobiliado',
  },
  { id: 'PRIVATE_POOL', text: 'Piscina privativa', category: 'Características do imóvel' },
  {
    id: 'BACKYARD', text: 'Quintal', category: 'Características do imóvel', path: 'quintal',
  },
  { id: 'SUITE', text: 'Suíte', category: 'Características do imóvel' },
  {
    id: 'CABLE_TV', text: 'Tv a cabo', category: 'Características do imóvel', path: 'tv-a-cabo',
  },
  {
    id: 'BALCONY', text: 'Varanda/Sacada', category: 'Características do imóvel', path: 'varanda',
  },
  {
    id: 'GOURMET_BALCONY', text: 'Varanda gourmet', category: 'Características do imóvel', path: 'varanda-gourmet',
  },
  { id: 'NATURAL_VENTILATION', text: 'Ventilação natural', category: 'Características do imóvel' },
  {
    id: 'PANORAMIC_VIEW', text: 'Vista panorâmica', category: 'Características do imóvel', path: 'vista-panoramica',
  },
]

export default AMENITIES
