// @ts-check
import assert from '@/utils/assert'
import { LEAD_TYPE } from '@/utils/constants'

export const ListingDeduplicationStage = /** @type {const} */ ({
  Initial: 'INITIAL',
  MessageSentToListing: 'MESSAGE_SENT_TO_LISTING',
  Done: 'DONE',
  WritingMessage: 'WRITTING_MESSAGE',
  ShowingDuplicates: 'SHOWING_DUPLICATES',
  SeeingPhoneNumber: 'SEEING_PHONE_NUMBER',
  HasError: 'SEND_LEAD_ERROR',
})

/**
 * @typedef {(typeof ListingDeduplicationStage)[keyof typeof ListingDeduplicationStage]}
 *  ListingDeduplicationStage
 */

/**
 * @template {string} K
 * @template T
 * @param {K} stage
 * @param {Partial<{[keys in K]: (defaultValue: () => T) => T}>} entries
 * @param {{ default: () => T }} options
 * @returns {T}
 */
function when (stage, entries, { default: defaultValue }) {
  return entries[stage]?.(defaultValue) || defaultValue()
}

/**
 *
 * @param {ListingDeduplicationStage} stage
 * @param {{
 *  leadType: import('@/utils/constants/leads').LeadType
 *  selected: boolean
 *  previous?: ListingDeduplicationStage
 * }} options
 * @returns {string}
 */
export function inferTrackingSubject (stage, { leadType, selected, previous }) {
  return when(stage, {
    [ListingDeduplicationStage.Initial]:
      (defaultValue) => {
        switch (leadType) {
          case LEAD_TYPE.EMAIL:
            return selected
              ? 'DEDUPLICATION SELECTED LISTING CARD'
              : 'DEDUPLICATION MANDATORY LISTING CARD'
          default:
            return defaultValue()
        }
      },
    [ListingDeduplicationStage.MessageSentToListing]:
      (defaultValue) => (leadType === LEAD_TYPE.EMAIL ? 'DEDUPLICATION SELECTED POST LEAD' : defaultValue()),
    [ListingDeduplicationStage.ShowingDuplicates]:
      (defaultValue) => {
        switch (leadType) {
          case LEAD_TYPE.PHONE:
            return 'DEDUPLICATION LISTING CARD ONE CLICK TO LEAD'
          case LEAD_TYPE.EMAIL:
            return selected
              ? 'DEDUPLICATION SELECTED LISTING CARD'
              : 'DEDUPLICATION MANDATORY LISTING CARD'
          default:
            return defaultValue()
        }
      },
    [ListingDeduplicationStage.WritingMessage]:
      (defaultValue) => (
        previous ? inferTrackingSubject(previous, { leadType, selected }) : defaultValue())
    ,
  }, {
    default: () => [stage, leadType].join(':'),
  })
}

/**
 *
 * @param {ListingDeduplicationStage} stage
 * @param {{
 *  leadType: import('@/utils/constants/leads').LeadType
 * }} options
 * @returns
 */
export function inferTrackingScreen (stage, { leadType }) {
  return when(stage, {
    [ListingDeduplicationStage.ShowingDuplicates]:
      (defaultValue) => {
        switch (leadType) {
          case LEAD_TYPE.PHONE:
          case LEAD_TYPE.WHATSAPP:
            return 'ONE CLICK TO LEAD BUTTON'
          default:
            return defaultValue()
        }
      },
    [ListingDeduplicationStage.MessageSentToListing]:
      (defaultValue) => (leadType === LEAD_TYPE.EMAIL ? 'MODAL FORM' : defaultValue()),
    [ListingDeduplicationStage.WritingMessage]:
      (defaultValue) => (leadType === LEAD_TYPE.EMAIL ? 'MODAL FORM' : defaultValue()),
  }, {
    default: () => [stage, leadType].join(':'),
  })
}

/**
 * @typedef {import('@/store/listing/state').Listing | import('./duplicates').Duplicate} ListingLike
 */

/**
 * @typedef {Array<ListingLike>} Listings
 */

/**
 * @template {object} [T = {}]
 * @typedef {T & { source: import('@/store/listing/state').Listing }} WithSource
 */

/**
 * @typedef {{
 *  type: ListingDeduplicationStage
 *  source?: import('@/store/listing/state').Listing
 *  listing?: ListingLike
 *  listings?: ListingLike[]
 * }} StageWithProps
 */

/**
 * @typedef {{
 *  current: StageWithProps,
 *  previous: StageWithProps
 * }} State
 */

/**
 * @typedef {{
 *  listing: import('@/store/listing/state').Listing;
 *  duplicates?: import('./duplicates').Duplicate[];
 * }} ListingWithDuplicates
 */

/**
 * @template Payload
 * @template {StageWithProps} Next
 * @param {(payload: Payload, current: State) => Next} handler
 * @return {(state: State, payload: any) => void}
 */
function makeMutation (handler) {
  return (state, /** @type {Payload} */ payload) => {
    const next = handler(payload, state)
    state.previous = state.current
    state.current = next
  }
}

const INITIAL = {
  type: ListingDeduplicationStage.Initial,
}

/** @type {import('vuex').Module<State, any>} */
export default {
  namespaced: true,

  state: () => ({
    current: INITIAL,
    previous: INITIAL,
  }),

  mutations: {

    backToStart: makeMutation(() => INITIAL),

    goBack: makeMutation((_, state) => state.previous ?? INITIAL),

    writeMessage: makeMutation((
      /**
       * @type {WithSource<{
       *   listings?: Listings,
       *   listing: import('@/store/listing/state').Listing
       * }>}
       */ { listing, listings, source },
    ) => {
      assert(listings?.length || listing)
      assert(source)

      return ({
        type: ListingDeduplicationStage.WritingMessage,
        source,
        listing,
        listings,
      })
    }),

    onSubmitSuccess: makeMutation((
      /**
       * @type {WithSource<{
       *   listings: Listings,
       *   showDuplicates: boolean,
       *   leadType: import('@/utils/constants/leads').LeadType
       * }>}
       */ {
        listings, source, showDuplicates, leadType,
      },
    ) => {
      assert(listings?.length)
      assert(source)
      assert(leadType)

      const type = (function () {
        switch (leadType) {
          case LEAD_TYPE.PHONE:
            return ListingDeduplicationStage.SeeingPhoneNumber
          case LEAD_TYPE.EMAIL:
          default:
            return !showDuplicates
              ? ListingDeduplicationStage.Done
              : ListingDeduplicationStage.MessageSentToListing
        }
      }())

      const listing = listings?.[0]

      return ({
        type,
        source,
        listing,
      })
    }),

    showDuplicates: makeMutation((
      /** @type {WithSource} */ { source },
    ) => {
      assert(source)

      return ({
        type: ListingDeduplicationStage.ShowingDuplicates,
        source,
      })
    }),

    onError: makeMutation((
      /** @type {WithSource<{ error: Error }>} */ { source, error },
    ) => {
      assert(source)

      return ({
        type: ListingDeduplicationStage.HasError,
        source,
        error,
      })
    }),
  },
}

