const getSortedObject = (untreatedObjects) => {
  const validatedOrders = []
  const validatedObjects = []

  untreatedObjects.forEach((object) => {
    const objectOrder = object.attributes?.order

    const hasOrder = Boolean(objectOrder)
    const notFoundOrders = !validatedOrders.includes(objectOrder)

    if (hasOrder && notFoundOrders) {
      validatedOrders.push(objectOrder)
      validatedObjects.push(object)
    }
  })

  const orderedObjects = validatedObjects.sort((a, b) => a.attributes.order - b.attributes.order)

  return orderedObjects.map((e) => e.attributes)
}

export default getSortedObject
