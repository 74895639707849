import apiClient from '@/api/client'
import createGluePayload from './utils/glue'

const accountAddressFields = 'country,zipCode,geoJson,city,streetNumber,level,precision,confidence,stateAcronym,source,ibgeCityId,zone,street,locationId,district,name,state,neighborhood,poisList,complement,pois,valuableZones'
const accountAddress = `addresses(shipping(${accountAddressFields}),billing(${accountAddressFields}))`
const accountPhones = 'phones(primary, mobile)'
const accountPublisherFields = `id,name,logoUrl,licenseNumber,showAddress,legacyVivarealId,legacyZapId,createdDate,${accountPhones},${accountAddress},minisite`

const listingFields = 'sourceId,listingsCount,displayAddressType,amenities,usableAreas,constructionStatus,listingType,description,title,stamps,createdAt,deletedAt,floors,unitTypes,nonActivationReason,providerId,propertyType,unitSubTypes,unitsOnTheFloor,legacyId,id,portal,unitFloor,parkingSpaces,updatedAt,address,suites,publicationType,externalId,bathrooms,usageTypes,totalAreas,advertiserId,advertiserContact,bedrooms,whatsappNumber,acceptExchange,pricingInfos,showPrice,resale,buildings,capacityLimit,status,priceSuggestion,contractType'

export const INCLUDE_FIELDS = `recommendations(scores(listing(listing(${listingFields}),medias,link,account(${accountPublisherFields}))),recommender,version,description,causes)`
export const PORTAL = 'ZAP'

const paramsExists = (parameter) => (
  parameter ? parameter.split(',')[0] : undefined
)

// eslint-disable-next-line
export async function getRecommendations (endpoint, params) {
  const unitType = paramsExists(params['profile.unitType'])
  const bedrooms = paramsExists(params['profile.bedrooms'])
  const bathrooms = paramsExists(params['profile.bathrooms'])
  const parkingSpaces = paramsExists(params['profile.parkingSpaces'])
  const payload = createGluePayload(
    {
      ...params,
      'profile.bedrooms': bedrooms,
      'profile.bathrooms': bathrooms,
      'profile.parkingSpaces': parkingSpaces,
      'profile.unitType': unitType,
      includeFields: INCLUDE_FIELDS,
      portal: PORTAL,
    },
  )

  return apiClient.get(`${endpoint}/v2/recommendations`, payload)
}
