import { mountListingsTrackingData } from '../results/base-page-view'

/**
 *
 * @param {import('@/store/listing/state').Listing['publicationType']} type
 * @returns {(keyof typeof import('@/utils/constants/results').EXPANSIONS) | null}
 */
function encodeType (type) {
  switch (type) {
    case 'PREMIUM':
      return 'premium'
    case 'SUPER_PREMIUM':
      return 'superPremium'
    case 'TRIPLE':
      return 'triple'
    default:
      return null
  }
}

const adaptDuplicateListings = (listings) => listings.map((listing) => ({
  listing,
  type: encodeType(listing.publicationType),
  account: listing.publisher,
}))

const mountPaginationTrackingData = (pagination) => {
  const {
    currentPage,
    listingsCount,
    pageResults,
    maxListingsPerPage,
  } = pagination

  return {
    currentPage,
    listingsCount,
    listingsShowedBySlot: pageResults,
    pageResults,
    pagesCount: Math.ceil(listingsCount / maxListingsPerPage),
    slot: 1,
  }
}

const mountFiltersTrackingData = (filter) => ({
  sort: 'DEFAULT',
  businessType: filter.businessType ?? '',
  listingTypes: filter.listingTypes ? [filter.listingTypes] : [],
  unitTypes: [],
  addresses: [],
  normalizedAddresses: [],
  constructionStatuses: [],
  fromPrice: 0,
  toPrice: 0,
  priceMinCondo: 0,
  priceMaxCondo: 0,
  fromArea: 0,
  toArea: 0,
  fromTotalArea: 0,
  toTotalArea: 0,
  parkingSpaces: [],
  bedrooms: [],
  bathrooms: [],
  suites: [],
  amenities: [],
  searchTerms: [],
  advertiserId: '',
  filterCategories: [],
  metadata: [],
  rentalPeriod: [],
  rentalTotalPriceMax: 0,
  rentalTotalPriceMin: 0,
  stamps: [],
  displayAddressType: '',
  nearBy: [],
  warranties: [],
})

// taken from portal-webapp project
const generateUniqueId = () => Date.now().toString(36) + Math.random().toString(36).substring(2)

const buildRankingRenderedData = (
  filter = {},
  pagination = {},
  listings = [],
  action = '',
) => {
  const ranking = {
    action,
    searchId: generateUniqueId(),
    filter: mountFiltersTrackingData(filter),
    pagination: mountPaginationTrackingData(pagination),
    ...mountListingsTrackingData(adaptDuplicateListings(listings)),
  }

  return ranking
}

const buildRankingClickedData = (
  filter = {},
  pagination = {},
  listingId = '',
  expandedResultType = '',
) => {
  const ranking = {
    listingId,
    expandedResultType,
    searchId: '',
    action: 'DEDUPLICATION SEE DETAILS',
    filter: mountFiltersTrackingData(filter),
    pagination: mountPaginationTrackingData(pagination, []),
  }

  return ranking
}

export {
  buildRankingRenderedData,
  buildRankingClickedData,
}
