// @ts-check

import { define } from './metadata'

/**
 * @param {string | string[]} [ids]
 * @returns {import('./metadata').MetadataBuilder}
 */
export default (ids) => define(({ script, noscript }) => {
  if (typeof ids === 'string') {
    ids = [ids]
  } else if (!ids) {
    ids = []
  }

  ids?.forEach((id) => {
    noscript(`<iframe src="https://www.googletagmanager.com/ns.html?id=${id}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`)

    script(
      `(function(w, d, s, l, i) {
        w[l] = w[l] || [];
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
        j.defer = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
        f = null;
      })(window, document, 'script', 'dataLayer', '${id}');`,
    )
  })
})
