let requestAnimationFrame
let easeInOutQuad

if (__BROWSER__) {
  requestAnimationFrame = window.requestAnimationFrame
    || window.webkitRequestAnimationFrame
    || window.mozRequestAnimationFrame
    || function (callback) { window.setTimeout(callback, 1000 / 60) }

  easeInOutQuad = (t, b, c, d) => {
    t /= d / 2
    if (t < 1) return (c / 2) * t * t + b
    t -= 1
    return -(c / 2) * (t * (t - 2) - 1) + b
  }
}

export const smoothScrollTo = (to = 0, duration = 500) => {
  if (typeof document === 'undefined') return

  const element = document.scrollingElement || document.documentElement
  const { scrollTop: start } = element
  const change = to - start
  const startDate = +new Date()

  const animateScroll = () => {
    const currentDate = +new Date()
    const currentTime = currentDate - startDate

    element.scrollTop = Number(easeInOutQuad(currentTime, start, change, duration))

    if (currentTime < duration) requestAnimationFrame(animateScroll)
    else element.scrollTop = to
  }

  animateScroll()
}

export const smoothScrollToReferencedListing = (element, marginTop = 0) => {
  const getTopBounding = element.getBoundingClientRect().top
  const finalPosition = window.scrollY + marginTop + getTopBounding

  window.scrollTo({ top: finalPosition, behavior: 'smooth' })
}

export const hiddeBodyScroll = () => {
  const isBodyScrollHidden = document.body.style.overflow === 'hidden'

  document.body.style.overflow = isBodyScrollHidden ? 'auto' : 'hidden'
}
