const setHttpStatus = ({ commit }, status) => {
  commit('setHttpStatus', status)
}

const clearHttpStatus = ({ commit }) => {
  commit('setHttpStatus', null)
}

export default {
  setHttpStatus,
  clearHttpStatus,
}
