/* eslint-disable import/no-mutable-exports */
import CreatePersistedState from 'vuex-persistedstate'

let PersistedState

if (__BROWSER__) {
  PersistedState = CreatePersistedState({
    key: 'zap-web',
    paths: ['auth', 'leads.lead', 'recommendations.location', 'results.filters'],
    storage: window.localStorage,
  })
}

export default PersistedState
