// @ts-check

/**
 * @typedef {import('@/store/auth/state').UserContact & {
 *  id: string,
 *  acceptTerms?: boolean
 *  message?: string
 * }} Lead
 */

/**
 * @typedef {{
 *  lead: Lead | null
 *  loading: boolean
 *  recommendationsLoading: boolean
 * }} LeadsState
 */

export default () => (/** @type {LeadsState} */ ({
  lead: null,
  recommendations: null,
  loading: false,
  recommendationsLoading: false,
  leadId: null,
  tracking: {},
  leadData: {},
}))
