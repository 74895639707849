import Vue from 'vue'
import cuid from 'cuid'
import localStorage from '@/storage/local-storage'

import rpTrackerMapper from '@/pages/results/components/filters/filters.tracker.mapper'

import { unformatDecimal } from '@/filters/format-decimal'
import { LEAD_TYPE } from '@/utils/constants'
import { encodeProType, encodeSellerCategory } from '../tracker'

const handleRequiredAttributes = (attr) => {
  const errorMessage = 'attribute is necessary to track leadClicked event'
  const requiredFields = [
    'filters',
    'lead',
    'listing',
    'businessType',
    'tracking',
  ]

  requiredFields.forEach((field) => {
    if (!(field in attr)) throw new Error(`${field} ${errorMessage}`)
  })
}

/**
 * @typedef {{
 *  currentPage?: number;
 *  positionRanking?: number;
 * }} PaginationProps
 */

const handlePagination = (/** @type {PaginationProps} */ pagination) => {
  if (!pagination) return {}
  const { currentPage, positionRanking } = pagination
  return { pagination: { currentPage, positionRanking } }
}

/**
 * @typedef {Pick<
 *  import('@/store/listing/state').Listing,
 *    'id'
 *    | 'advertiserId'
 *    | 'description'
 *    | 'pricingInfo'
 *    | 'stamps'
 *    | 'listingSummary'
 *    | 'contractType'
 *  >} Listing
 */

/**
 * @typedef {{
 *   intent?: string
 *   screen: string
 *   subject: string
 * }} TrackingProps
 */

/**
 * @typedef {{
 *   listing: Listing
 *   lead: import('@/store/leads/state').Lead
 *   businessType: import('@/store/core/types').BusinessType
 *   listingPosition?: number
 *   type: import('@/utils/constants/leads').LeadType
 *   tracking: TrackingProps
 *   filters: unknown
 *   pagination?: PaginationParams
 * }} LeadClickedProps
 */

/**
 * @param {import('@/utils/constants/leads').LeadType} type
 * @returns {string}
 */
function inferIntent (type) {
  switch (type) {
    case LEAD_TYPE.EMAIL:
      return 'ANSWERS_REQUEST'
    case LEAD_TYPE.PHONE:
      return 'SEE_PHONE'
    case LEAD_TYPE.WHATSAPP:
      return 'WHATSAPP APPOINTMENT REQUEST'
    default:
      throw new Error(`It is not possible to encode the intent of the lead type ${type}`)
  }
}

/**
 * @param {LeadClickedProps} data
 */
const buildLeadClikedEvent = (data) => {
  handleRequiredAttributes(data)

  const {
    filters,
    lead: {
      id: leadId,
      email,
      message,
      name,
      phoneNumber: phone,
    },
    listing: {
      advertiserId,
      description: listingName,
      pricingInfo: { rentalPrice, salePrice },
      id: listingId,
      listingSummary: { leads } = {},
      stamps = [],
      contractType,
    },
    listingPosition,
    businessType: businessTypeContext,
    tracking: {
      intent,
      screen,
      subject,
    },
    type,
  } = data

  const source = localStorage.getSource()
  const filter = rpTrackerMapper.filters(filters)
  const transactionId = cuid()

  const transactionTotal = unformatDecimal(businessTypeContext === 'SALE'
    ? salePrice : rentalPrice, 0)

  return {
    lead: {
      advertiser_category: encodeSellerCategory(contractType),
      advertiser_pro_type: encodeProType(contractType),
      businessTypeContext,
      listingId,
      advertiserId,
      intent: intent || inferIntent(type),
      type,
      message: message || '',
      name,
      email,
      phone,
      options: [],
      screen,
      subject,
      source: {
        ...source,
        filter,
      },
      leadid: leadId,
      senseOfUrgency: leads,
      stamps,
      listingPosition,
    },
    transactionId,
    transactionAffiliation: '',
    transactionTax: 0,
    transactionTotal,
    transactionShipping: 0,
    transactionProducts: [
      {
        name: listingName || listingId,
        price: transactionTotal,
        sku: listingId,
        category: businessTypeContext,
        quantity: 1,
      },
    ],
    event: 'leadClicked',
    _clear: true,
  }
}

/**
 * @typedef {ReturnType<typeof buildLeadClikedEvent>} LeadEvent
 */

// Tests
export { buildLeadClikedEvent }

export default {
  /**
   * @param {LeadClickedProps} data
   * @returns {LeadEvent}
   */
  leadClicked (data) {
    const event = buildLeadClikedEvent(data)
    const { listing } = data

    if (listing.sourceId) {
      event.lead = { ...event.lead, sourceId: data.listing.sourceId }

      if (listing.sourceId !== listing.listingId) {
        const pagination = handlePagination(data.pagination)

        event.lead = { ...event.lead, ...pagination }
      } else {
        delete event.pagination
      }
    }

    Vue.$clickstream.trackEvent(event)
  },
}
