import Vue from 'vue'
import Vuex from 'vuex'
import createLogger from 'vuex/dist/logger'

import CreatePersistedState from './storage'
import mutations from './mutations'
import getters from './getters'

// Modules
import ab from './ab'
import auth from './auth'
import listing from './listing'
import results from './results'
import advertisers from './advertisers'
import markdown from './markdown'
import errors from './errors'
import leads from './leads'
import visits from './visits'
import map from './map'
import savedSearch from './saved-search'
import userLocation from './user-location'
import page from './page'
import ui from './ui'
import schema from './schema'
import filters from './filters'
import listingStats from './listing-stats'
import home from './home'
import performance from './performance'
import partners from './partners'
import officialStore from './official-store'
import buyingPower from './buying-power'
import enem from './enem'
import listingDeduplication from './listing-deduplication'

Vue.use(Vuex)

const debug = false

const plugins = [].concat(debug ? [createLogger()] : [])

if (__BROWSER__) {
  plugins.push(CreatePersistedState)
}

const createStore = ({ device, context }) => new Vuex.Store({
  state: () => ({
    context,
    // Assume mobile first when no device config available for ui intent (a.k.a. SSR)
    // also, screenWidth is based on most used screen resolution according to GA
    // https://analytics.google.com/analytics/web/?authuser=1#/report/visitors-mobile-overview/a230770w75321079p77793250/explorer-table.secSegmentId=analytics.browserSize&explorer-table.plotKeys=%5B%5D&explorer-graphOptions.selected=analytics.nthMonth
    // TODO: check if this is really necessary
    device: device || { platform: 'mobile', isMobile: true, screenWidth: 410 },
  }),
  mutations,
  getters,
  modules: {
    ab,
    auth,
    listing,
    results,
    advertisers,
    markdown,
    errors,
    leads,
    visits,
    map,
    savedSearch,
    userLocation,
    page,
    ui,
    schema,
    filters,
    listingStats,
    home,
    performance,
    partners,
    officialStore,
    enem,
    buyingPower,
    listingDeduplication,
  },
  strict: debug,
  plugins,
})

export default createStore
