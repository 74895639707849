import breakpoints from '@/utils/constants/breakpoints'
import platforms from '@/utils/constants/platforms'

const isSmallerThan = (valueA, valueB) => valueA < valueB
const isAtLeast = (valueA, valueB) => valueA >= valueB
const isAtMost = (valueA, valueB) => valueA <= valueB

const device = {
  window: typeof window === 'undefined' ? {} : window,

  getCurrentMediaConfig () {
    const { innerWidth } = this.window
    const { tablet, desktop } = breakpoints

    let platform = platforms.mobile

    let isTablet = false
    const isMobile = isSmallerThan(innerWidth, tablet)

    if (!isMobile) {
      isTablet = isAtLeast(innerWidth, tablet) && isAtMost(innerWidth, desktop)
      platform = isTablet ? platforms.tablet : platforms.desktop
    }

    return {
      isDesktop: !isMobile && !isTablet,
      isTablet,
      isMobile,
      platform,
      innerWidth,
    }
  },
}

export default device
