// @ts-check

/**
 * @template T
 * @param {T} a
 * @returns {T}
 */
function doNothing (a) {
  return a
}

/**
 * @template T
 * @template [RootState = any]
 * @template [Selected = T]
 * @param {Selector<Selected, RootState>} selector
 * @param {import("./select").Transform<Selected, T>} transformation
 * @returns {function(this: Vue): T}
 */
export default function select (selector, transformation) {
  return function () {
    const transform = transformation ?? doNothing
    return transform.apply(this, [selector(this.$store.state)])
  }
}
