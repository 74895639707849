// @ts-check

/**
 * @typedef {object} Video
 * @typedef {object} Image
 * @typedef {'DAILY' | 'WEEKLY' | 'MONTHLY' | 'YEARLY'} RentalPeriod
 */

/**
 * @typedef {object} Address
 * @property {string} state
 * @property {string} city
 * @property {string} neighborhood
 * @property {string} street
 * @property {string} streetNumber
 * @property {string} complement
 * @property {string} zipCode
 */

/**
 * @typedef {object} PricingInfo
 * @property {string} yearlyIptu
 * @property {string} price
 * @property {string} monthlyCondoFee
 * @property {import("../core/types").BusinessType} businessType
 * @property {string} [rentalPrice]
 * @property {string} [salePrice]
 * @property {{
 *  period: RentalPeriod
 * }} [rentalInfo]
 */

/**
 * @typedef { 'RecordType_NONE'
 *  | 'PROPERTY_DEVELOPER'
 *  | 'OWNER'
 *  | 'REAL_ESTATE'
 *  | 'PROPERTY_DEVELOPER_SERVICE' } ContractType
 */

/**
 * @typedef {object} Listing
 * @property {string} [sourceId]
 * @property {string} id
 * @property {Date} createdAt
 * @property {ContractType} contractType
 * @property {string} advertiserId
 * @property {string} publicationType
 * @property {Video[]} videos
 * @property {Image[]} images
 * @property {string[]} stamps
 * @property {string} description
 * @property {import("../core/types").ListingType} listingType
 * @property {string[]} usageTypes
 * @property {string[]} unitTypes
 * @property {PricingInfo} pricingInfo
 * @property {PricingInfo[]} pricingInfos
 * @property {string[]} usableAreas
 * @property {number[]} bedrooms
 * @property {number[]} bathrooms
 * @property {number[]} suites
 * @property {number[]} parkingSpaces
 * @property {number} unitFloor
 * @property {string[]} amenities
 * @property {Address} address
 * @property {number} listingsCount
 * @property {{ leads?: unknown }} [listingSummary]
 */

/**
 * @typedef {object} Publisher
 * @property {string} id
 * @property {string} licenseNumber
 * @property {string} logoUrl
 * @property {string} name
 * @property {number} relatedLinksCount
 * @property {boolean} showAddress
 * @property {string} url
 */

/**
 * @typedef {object} ListingState
 * @property {Listing} listing
 * @property {Publisher} publisher
 */

export default () => ({
  listing: null,
  publisher: {},
  lastModified: null,
  advertiserPartner: false,
})
