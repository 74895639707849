const setDates = (state, dates) => {
  const availableDates = dates.map((date) => new Date(date))
  Object.assign(state, { dates: availableDates })
}

const clearDates = (state) => {
  Object.assign(state, { dates: [] })
}

const setVisit = (state, visit) => {
  Object.assign(state, { visit })
}

const setUserInfo = (state, userInfo) => {
  const newUserInfo = { ...state.userInfo, ...userInfo }
  Object.assign(state, { userInfo: newUserInfo })
}

export default {
  setDates,
  clearDates,
  setVisit,
  setUserInfo,
}
