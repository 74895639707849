import gzuis from '@/utils/gzuis'

const setGzuisCookie = (referer, trig) => {
  gzuis.setCookie([
    {
      name: 'referer',
      value: referer,
    },
    {
      name: 'trig',
      value: trig ?? '',
    },
  ])
}

const createLoginUrl = (url, trig, redirectUrl = 'https://www.zapimoveis.com.br', customCliendId = '') => {
  const encodedRedirectUrl = encodeURIComponent(redirectUrl)

  setGzuisCookie(redirectUrl, trig)

  const newUrl = new URL(url)

  const redirectUri = `${newUrl.searchParams.get('redirect_uri')}${encodedRedirectUrl}`
  newUrl.searchParams.set('redirect_uri', redirectUri)

  if (customCliendId) {
    newUrl.searchParams.set('client_id', customCliendId)
  }

  return newUrl.href
}

export default {
  createLoginUrl,
}
