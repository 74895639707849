const setPostLeadModal = (state, data) => {
  Object.assign(state, data)
}

const setLeadModal = (state, data) => {
  state.leadModal = data.leadModal
}

const setSavedSearchModal = (state, data) => {
  state.savedSearchModal = data.savedSearchModal
}

const setSimilarAlertModal = (state, data) => {
  state.similarAlertModal = data.similarAlertModal
}

const setVisitModal = (state, data) => {
  state.visitModal = data.visitModal
}

const setWhatsappFormModal = (state, data) => {
  state.whatsappFormModal = data.whatsappFormModal
}

export default {
  setPostLeadModal,
  setLeadModal,
  setSavedSearchModal,
  setSimilarAlertModal,
  setWhatsappFormModal,
  setVisitModal,
}
