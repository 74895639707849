import { getLeadPartner } from '@/api/panel-control'
import simulatorManagement from '@/pages/listing/simulatorManagement'
import { PARTNERS } from '@/pages/listing/simulatorManagement/constants'

const updatePartners = async (store, listingInformation) => {
  try {
    const { data } = await getLeadPartner(store, listingInformation)

    store.commit('updatePartnerObject', simulatorManagement({ partner: data.toLowerCase(), listingInformation }))
  } catch {
    store.commit('updatePartnerObject', simulatorManagement({ partner: PARTNERS.KZAS, listingInformation }))
  }
}

export default {
  updatePartners,
}
