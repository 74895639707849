// @ts-check

import defineSelector from '../core/define-selector'
import { INC_IN_RP_SALE } from '../experimentations'
import { getVariationOf } from '../experimentations/selectors'

const legacyExperimentsMap = {
  incInRP: INC_IN_RP_SALE,
}

/** @type {Experiment[]} */
const legacyExperiments = Object.entries(legacyExperimentsMap)
  .map(([_alias, experiment]) => experiment)

/**
 * @typedef {typeof legacyExperimentsMap} LegacyExperiments
 */

/**
 * @typedef {keyof LegacyExperiments} LegacyExperimentId
 */

/**
 * @template T
 * @param {T[]} collection
 * @returns {T[]}
 */
function unique (collection) {
  return Array.from(new Set(collection))
}

/**
 * @param {Experiment[]} experiments
 * @param {{ skipDefaults?: boolean }} options
 * @returns {Selector<string[]>}
 *
 * @deprecated use `useContext` helper instead
 */
export function getVariationsAliasOf (experiments, options = {}) {
  const { skipDefaults = false } = options

  return defineSelector((state, select) => unique(legacyExperiments
    .concat(experiments)
    .map(
      (experiment) => {
        try {
          const variation = select(getVariationOf(experiment, { throwIfNotFound: true }))

          if (skipDefaults && variation === experiment.defaultVariation) {
            return undefined
          }

          return experiment.makeVariationAlias(variation)
        } catch (err) {
          return undefined
        }
      },
    )
    .filter((name) => !!name)))
}

/**
 * @type {Selector<string[]>}
 * @deprecated
 */
export const getLegacyVariationsAlias = getVariationsAliasOf([], { skipDefaults: true })
