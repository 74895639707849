// @ts-check

import { encodeProType, encodeSellerCategory } from '@/utils/tracking/tracker'
import defineSelectorsOf from '../core/define-selectors-of'
import {
  makeFlowType, toCurrency, join, sanitize,
} from '../core/tracking'

/**
 * @typedef {string} Exposure
 * @typedef {string} Seal
 * @typedef {string} PropertyUsageCondition
 */

/**
 * @typedef {{
 *  address_state: string
 *  address_city: string
 *  address_neighborhood: string
 *  address_street: string
 *  address_street_number: string
 *  address_complement: string
 *  address_condo_name: string | undefined
 *  address_postal_code: string
 * }} Address
 */

/**
 * @typedef {{
 *  price: number | undefined
 *  condo_fee: number | undefined
 *  yearly_iptu: number | undefined
 * }} ListingPrices
 */

/**
 * @typedef {{
 *  ad: {
 *    id: string
 *    date: Date
 *    exposureId: string
 *    exposure: Exposure
 *    video: number
 *    pictures: number
 *    seal: Seal | undefined
 *    price: {
 *      sale_price?: ListingPrices
 *      rental_price: {
 *        daily?: ListingPrices
 *        weekly?: ListingPrices
 *        monthly?: ListingPrices
 *        yearly?: ListingPrices
 *      }
 *    }
 *    description: boolean
 *  }
 *  property: {
 *    usage: {
 *      condition: PropertyUsageCondition
 *      type: {
 *        id?: string
 *        name: string
 *      }
 *    }
 *    type: {
 *      id?: string
 *      name: string
 *    }
 *    location: Address,
 *    infos: {
 *      delivered_at?: Date
 *      total_area: string | undefined
 *      bedrooms: string | undefined
 *      suites: string | undefined
 *      floor: number | undefined
 *      parking_spaces: string | undefined
 *      bathrooms: string | undefined
 *      amenities: string | undefined
 *    }
 *  }
 * }} AdTrackingMetadata
 */

/**
 * @typedef {{
 *  id: string
 *  type?: {
 *    id: string
 *    name: string
 *    detail: {
 *      id: string
 *      name: string
 *    }
 *  }
 *  products?: {
 *    plan: {
 *      id: string
 *      name: string
 *    }
 *    feirao: {
 *      id: string
 *      name: string
 *    }
 *  }
 *  portfolio: {
 *    name: string
 *    creci: string
 *    total_active_listings?: number
 *    category_size?: string
 *  }
 * }} SellerTrackingMetadata
 */

/** @type {SelectorFactory<import('./state').ListingState>} */
const defineSelector = defineSelectorsOf('listing')

export const getListing = defineSelector((state) => {
  const { listing } = state

  return listing
})

const getFlowType = defineSelector((state) => {
  const { listing: { pricingInfo: { businessType }, listingType } } = state
  return makeFlowType({ businessType, listingType })
})

export const getListingPageInfo = defineSelector((state, select) => ({
  flowType: select(getFlowType),
}))

/** @type {Selector<AdTrackingMetadata>} */
export const getListingAdInfo = defineSelector((state) => {
  const { listing } = state

  /**
   * @param {import('../core/types').BusinessType} businessType
   * @param {import('./state').RentalPeriod} [period]
   * @returns {ListingPrices | undefined}
   */
  function pricesOf (businessType, period) {
    const info = listing.pricingInfos.find(
      (i) => i.businessType === businessType && (!period || i.rentalInfo?.period === period),
    )

    return info && {
      condo_fee: toCurrency(info.monthlyCondoFee),
      price: toCurrency(info.price),
      yearly_iptu: toCurrency(info.yearlyIptu),
    }
  }

  return ({
    ad: {
      id: listing.id,
      date: listing.createdAt,
      exposureId: listing.advertiserId,
      exposure: listing.publicationType,
      video: listing.videos?.length,
      pictures: listing.images?.length,
      seal: join(listing.stamps),
      description: !!listing.description,
      price: {
        sale_price: pricesOf('SALE'),
        rental_price: {
          daily: pricesOf('RENTAL', 'DAILY'),
          weekly: pricesOf('RENTAL', 'WEEKLY'),
          monthly: pricesOf('RENTAL', 'MONTHLY'),
          yearly: pricesOf('RENTAL', 'YEARLY'),
        },
      },
    },
    property: {
      usage: {
        condition: listing.listingType,
        type: {
          name: /** @type {string} */ (join(listing.usageTypes)),
        },
      },
      location: {
        address_city: sanitize(listing.address.city),
        address_complement: sanitize(listing.address.complement),
        address_condo_name: undefined, // TODO: add this information when the API is fixed
        address_neighborhood: sanitize(listing.address.neighborhood),
        address_postal_code: sanitize(listing.address.zipCode),
        address_state: sanitize(listing.address.state),
        address_street: sanitize(listing.address.street),
        address_street_number: listing.address.streetNumber,
      },
      type: {
        name: /** @type {string} */ (join(listing.unitTypes)),
      },
      infos: {
        amenities: join(listing.amenities),
        bathrooms: join(listing.bathrooms),
        bedrooms: join(listing.bedrooms),
        suites: join(listing.suites),
        total_area: join(listing.usableAreas),
        floor: listing.unitFloor,
        delivered_at: undefined, // TODO: add this information when the API is fixed
        parking_spaces: join(listing.parkingSpaces),
      },
    },
  })
})

/** @type {Selector<SellerTrackingMetadata>} */
export const getListingSellerInfo = defineSelector((state) => {
  const { publisher, listing } = state

  return ({
    id: publisher.id,
    category: encodeSellerCategory(listing.contractType),
    pro_type: encodeProType(listing.contractType),
    portfolio: {
      name: sanitize(publisher.name),
      creci: publisher.licenseNumber,
      total_active_listings: publisher.relatedLinksCount,
    },
  })
})
