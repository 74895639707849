// @ts-check

import defineExperiment, { defineVariation } from './define-experiment'

/**
 * It does nothing on runtime, but prevents the treeshaking remove the ref on build
 *
 * @template T
 * @param {T} _input
 * @returns {void}
 * */
function makeTreeShakingKeepIt (_input) {
  // do nothing
}

/** It's an experiment used to test the integration itself */
export const INTEGRATION_TEST = defineExperiment('exp-aa-test', {
  defaultVariation: 'control',
  variations: ['control', 'B'],
})

/**
 * since: 2022-01-18
 * owner: navigation
 */
export const HOME_ZAP = defineExperiment('new-home-zap', {
  defaultVariation: 'control',
  variations: ['control', 'test'],
})

/**
 * since: 2022-02-07
 * owner: squad-vas
 */
export const CTA_BUYING_POWER_CALCULATOR = defineExperiment('vasimo-buying-power-cta', {
  defaultVariation: 'control',
  variations: ['control', 'filtro', 'header'],
})

// The experiments above don't have any effects on the site but should be declared
// to be tracked with the others.
makeTreeShakingKeepIt(INTEGRATION_TEST)

/**
* since: 2023-04-03
* owner: discovery
*/
export const LEAD_FORM_SEE_PHONE_MODAL = defineExperiment('search-lead-phone-rp', {
  defaultVariation: 'control',
  variations: ['control', 'modal'],
})

/**
* since: 2023-05-17
* owner: discovery
*/
export const INC_IN_RP_SALE = defineExperiment('search-inc-in-rp-sale', {
  alias: 'mtc',
  defaultVariation: 'control',
  variations: ['control', 'zInc', 'exRan', 'decRan'],
})

/**
* since: 2023-07-05
* owner: growth
*/
export const TRUNCATE_IMAGES = defineExperiment('truncate-images', {
  defaultVariation: 'control',
  variations: [
    defineVariation('control', { maxImages: /** @type {number | undefined} */ (undefined), showPlaceholder: false }),
    defineVariation('rdc', { maxImages: 4, showPlaceholder: false }),
    defineVariation('rdc-cta', { maxImages: 4, showPlaceholder: true }),
  ],
})

export const DEDUPLICATION = defineExperiment('deduplication', {
  defaultVariation: 'select',
  variations: [
    defineVariation('control', {
      enabled: false,
      canSelectDuplicates: false,
      alwaysSendLeadToDuplicates: 0,
      glueVariant: /** @type {string | undefined} */ (undefined),
    }),
    defineVariation('select', {
      enabled: true,
      canSelectDuplicates: true,
      alwaysSendLeadToDuplicates: 0,
      glueVariant: 'mtc:deduplication2023',
    }),
  ],
})

/**
* since: 2023-12-12
* owner: growth
*/

export const GALLERY_TEST = defineExperiment('gallery-test', {
  defaultVariation: 'control',
  variations: [
    defineVariation('control', {
      changeCTAs: false,
      isFormOpen: false,
    }),
    defineVariation('btn-bot', {
      changeCTAs: true,
      isFormOpen: true,
    }),
  ],
})

/**
 * since: 2023-08-21
 * owner: navigation
 */
export const LDP_SIMILAR_ALERT = defineExperiment('ldp-similar-alert', {
  defaultVariation: 'control',
  variations: [
    defineVariation('control', { shouldCreateAlert: false }),
    defineVariation('enabled', { shouldCreateAlert: true }),
  ],
})

/**
* since: 2023-12-12
* owner: growth
*/
export const WEBP_RLT = defineExperiment('webp-rlt', {
  defaultVariation: 'control',
  variations: [
    defineVariation('control', { imageFormat: /** @type {string | undefined} */ (undefined) }),
    defineVariation('webp', { imageFormat: 'webp' }),
  ],
})

/**
* since: 2024-03-01
* owner: growth
*/
export const REC_CTA = defineExperiment('rec-cta', {
  defaultVariation: 'control',
  variations: [
    defineVariation('control', {
      showRecommendationCTA: false,
    }),
    defineVariation('rcta', {
      showRecommendationCTA: true,
    }),
  ],
})

/**
* since: 2024-04-22
* owner: navigation
*/
export const LDP_ZAP = defineExperiment('zap-newldp', {
  defaultVariation: 'control',
  variations: ['control', 'test'],
})
