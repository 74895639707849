// @ts-check

/**
 * @template T
 * @param {SelectionFn<RootState, T>} selectionFn
 * @returns {Selector<T>}
 */
export default function defineSelector (selectionFn) {
  /** @type {Selector<T>} */
  const selector = (rootState) => {
    /** @type {SelectorExecutor} */
    const select = (childSelector) => childSelector(rootState)
    return selectionFn(rootState, select)
  }

  // FIXME: add memoization
  return selector
}

