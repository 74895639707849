const ALLOWED_ADDRESS_FIELDS = [
  'country',
  'state',
  'city',
  'zone',
  'neighborhood',
  'street',
  'accounts',
  'type',
  'locationId',
  'pointLat',
  'pointLon',
  'url',
]

const AD_MIDDLE_POSITION = 26
const PAGE_SIZE_DEFAULT = 34
const PAGE_SIZE_100 = 100
const PAGE_SIZE_PUBLISHER = 36

const accountStats = 'address.city,pricingInfos.businessType,listingType,address.neighborhood,stamps,totalCount'
const accountAddressFields = 'country,zipCode,geoJson,city,streetNumber,level,precision,confidence,stateAcronym,source,ibgeCityId,zone,street,locationId,district,name,state,neighborhood,poisList,complement,pois,valuableZones'
const accountAddress = `addresses(shipping(${accountAddressFields}),billing(${accountAddressFields}))`
const accountPhones = 'phones(primary, mobile)'

const accountFields = 'id,name,logoUrl,licenseNumber,showAddress,legacyVivarealId,legacyZapId,createdDate,minisite,tier'
const accountPublisherFields = `id,name,logoUrl,licenseNumber,showAddress,legacyVivarealId,legacyZapId,createdDate,${accountPhones},${accountAddress},minisite`

const listingFields = 'listingsCount,sourceId,displayAddressType,amenities,usableAreas,constructionStatus,listingType,description,title,stamps,createdAt,floors,unitTypes,nonActivationReason,providerId,propertyType,unitSubTypes,unitsOnTheFloor,legacyId,id,portal,unitFloor,parkingSpaces,updatedAt,address,suites,publicationType,externalId,bathrooms,usageTypes,totalAreas,advertiserId,advertiserContact,whatsappNumber,bedrooms,acceptExchange,pricingInfos,showPrice,resale,buildings,capacityLimit,status,priceSuggestion,contractType'
const resultsFields = `listings(listing(${listingFields}),account(${accountFields}),medias,accountLink,link)`
const searchFields = `result(${resultsFields}),totalCount`

const INCLUDE_FIELDS_ONLY_SEARCH = `search(${searchFields}),page,seasonalCampaigns`
const SORT_INCLUDE_FIELDS = `${INCLUDE_FIELDS_ONLY_SEARCH},facets,seasonalCampaigns,fullUriFragments`
const INCLUDE_FIELDS = `${SORT_INCLUDE_FIELDS},developments(search(${searchFields})),superPremium(search(${searchFields})),schema`
const INCLUDE_FIELDS_ONLY_DEVELOPMENTS = `${SORT_INCLUDE_FIELDS},developments(search(${searchFields}))`
const INCLUDE_FIELDS_WITHOUT_SCHEMA = `${SORT_INCLUDE_FIELDS},developments(search(${searchFields})),superPremium(search(${searchFields}))`
const INCLUDE_FIELDS_WITHOUT_DEVELOPMENTS = `${SORT_INCLUDE_FIELDS},superPremium(search(${searchFields}))`

const PUBLISHER_INCLUDE_FIELDS = `search(${searchFields}),superPremium(search(${searchFields})),accountStats(${accountStats}),account(${accountPublisherFields}),page,seasonalCampaigns,facets,fullUriFragments`

const DEVELOPMENTS_SIZE = 3
const SUPER_PREMIUM_SIZE = 3
const SUPER_PREMIUM_FEATURED_SIZE = 12

// filters that must not be send to oz filters component
const FILTERS_OUT_FROM_OZ_FILTERS = ['page', 'from', 'size', 'sort']

const SORT_OPTIONS = [
  { value: 'undefined', label: 'Mais relevantes', toParam: () => undefined },
  { value: 'date', label: 'Mais recente', toParam: () => 'updatedAt DESC' },
  {
    value: 'price',
    label: 'Menor preço',
    toParam: ({ business }) => `pricingInfos.price ASC sortFilter:pricingInfos.businessType='${business}'`,
  },
  { value: 'area', label: 'Maior área', toParam: () => 'usableAreas DESC' },
]

const EXPANSIONS = {
  nearby: 'RADIUS EXPANSION',
  expansion: 'REMOVE AMENITIES EXPANSION',
  developments: 'FIXED DEVELOPMENTS POSITIONS',
  superPremium: 'SUPER PREMIUM',
  premium: 'PREMIUM',
  dynamic: 'DYNAMIC',
}

// tracking
const TIER_TYPES = {
  diamond: 'DIAMOND',
  platinum: 'PLATINUM',
  gold: 'GOLD',
}

const TYPES_FOR_REGULAR_LISTINGS = {
  PREMIUM: 'premium',
  SUPER_PREMIUM: 'rankingSuperPremium',
}

// Super Premium Gallery A/B
const SPGAB = {
  variationB: {
    size: 3,
  },
  variationC: {
    size: 6,
  },
}

const ENUM_TYPE_CARDS = {
  defaultLayout: 'defaultLayout',
  dynamic: 'dynamic',
}

const TYPE_LISTING = {
  developments: 'developments',
  superPremium: 'superPremium',
  dynamic: 'dynamic',
}

const STAMPS = [
  {
    id: 'DATAZAP_APPROVED_SALE',
    text: 'Venda Datazap',
  },
  {
    id: 'DATAZAP_APPROVED_RENTAL',
    text: 'Aluguel Datazap',
  },
]

const OFFICIAL_STORE_STAMP_ENUM = {
  quality: 'quality',
  answerRate: 'answerRate',
  champion: 'champion',
  reclameAqui: 'reclameAqui',
}

const OFFICIAL_STORE_STAMPS = [OFFICIAL_STORE_STAMP_ENUM.quality,
  OFFICIAL_STORE_STAMP_ENUM.answerRate, OFFICIAL_STORE_STAMP_ENUM.champion,
  OFFICIAL_STORE_STAMP_ENUM.reclameAqui]

const OFFICIAL_STORE_ANSWER_RATE = {
  EXCELLENT: 'Excelente',
  VERY_GOOD: 'Muito boa',
  GOOD: 'Boa',
  REGULAR: 'Regular',
  BAD: 'Ruim',
}

export {
  AD_MIDDLE_POSITION,
  ALLOWED_ADDRESS_FIELDS,
  DEVELOPMENTS_SIZE,
  SUPER_PREMIUM_SIZE,
  SUPER_PREMIUM_FEATURED_SIZE,
  INCLUDE_FIELDS,
  INCLUDE_FIELDS_WITHOUT_SCHEMA,
  INCLUDE_FIELDS_ONLY_DEVELOPMENTS,
  INCLUDE_FIELDS_WITHOUT_DEVELOPMENTS,
  INCLUDE_FIELDS_ONLY_SEARCH,
  SORT_INCLUDE_FIELDS,
  PUBLISHER_INCLUDE_FIELDS,
  FILTERS_OUT_FROM_OZ_FILTERS,
  SORT_OPTIONS,
  PAGE_SIZE_DEFAULT,
  PAGE_SIZE_100,
  PAGE_SIZE_PUBLISHER,
  EXPANSIONS,
  TIER_TYPES,
  SPGAB,
  TYPES_FOR_REGULAR_LISTINGS,
  ENUM_TYPE_CARDS,
  TYPE_LISTING,
  STAMPS,
  OFFICIAL_STORE_STAMP_ENUM,
  OFFICIAL_STORE_STAMPS,
  OFFICIAL_STORE_ANSWER_RATE,
}
