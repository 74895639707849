import Vue from 'vue'

const buildModalViewEvent = ({
  action,
  origin,
}) => ({
  modalView: {
    action,
    origin,
    screen: 'DEDUPLICATION RANKING',
  },
  event: 'modalView',
})

export default {
  modalView (data) {
    const event = buildModalViewEvent(data)
    Vue.$clickstream.trackEvent(event)
  },
}

export { buildModalViewEvent }
