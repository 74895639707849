/**
 * @typedef {Object} AssertOptions
 * @property {string| ((this: void) => string)} [error] - The error message to throw
 * if the condition is false. If a function is provided, it will be called to generate the message.
 */

/**
 * Asserts that a condition is true, throwing an error if it is not.
 * @param {unknown} condition - The condition to check.
 * @param {AssertOptions} [options] - Optional parameters.
 * @throws {Error} Throws an error if the condition is false.
 * @returns {asserts condition}
 */
export default function assert (condition, { error } = {}) {
  if (!condition) {
    const errorMessage = typeof error === 'function' ? error() : error
    throw new Error(errorMessage ?? 'Assertion error')
  }
}
