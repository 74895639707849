const setListingStats = (state, stats) => {
  Object.assign(state, { stats })
}

const setLoading = (state, loading) => {
  Object.assign(state, { loading })
}

export default {
  setListingStats,
  setLoading,
}
