import { HEADERS } from '@/utils/constants/api'

function addHeaders ({ authorization, xDeviceId } = {}) {
  return {
    headers: {
      ...(authorization && { [HEADERS.AUTHORIZATION]: `Bearer ${authorization}` }),
      ...(xDeviceId && { [HEADERS.X_DEVICE_ID]: xDeviceId }),
    },
  }
}

export function createParamsAccountPayload (params = {}) {
  const {
    authorization, xDeviceId, ...rest
  } = params
  return {
    params: {
      ...rest,
    },
    withCredentials: true,
    ...addHeaders({ authorization, xDeviceId }),
  }
}

export function createBodyAccountPayload (params = {}) {
  const {
    authorization, xDeviceId, ...rest
  } = params
  return {
    data: {
      ...rest,
    },
    withCredentials: true,
    ...addHeaders({ authorization, xDeviceId }),
  }
}
