import merge from 'lodash/merge'
import { getByPath, getListing } from '@/api/page'

const accountPhonesFetch = async (store) => {
  const { glueEndpoint, currentPath } = store.rootGetters
  const { domain } = store.rootState.context
  const hideSummary = true

  const { data } = await getByPath(
    glueEndpoint,
    currentPath,
    {
      includeFields: 'account(phones)',
      domain,
      hideSummary,
    },
  )

  const { phones = {} } = data.account

  const newPublisher = { ...store.rootState.page.publisher, phones }

  store.commit('page/setPublisher', newPublisher, { root: true })
}

const advertiserPhonesFetch = async (store, listingId) => {
  const relatedListing = store.getters.getListingById(listingId)

  if (relatedListing.listing.advertiserContact) return

  const { glueEndpoint } = store.rootGetters
  const { domain } = store.rootState.context
  const variations = store.rootGetters['ab/getAllVariationNames']

  const { data } = await getListing(glueEndpoint, listingId, {
    includeFields: 'listing(advertiserContact),account(config)',
    variations,
    domain,
  })

  store.dispatch('setListing', merge(relatedListing, data))
}

export { accountPhonesFetch, advertiserPhonesFetch }
