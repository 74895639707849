/* eslint-disable no-unused-vars */
import getHomePageContent from '@/api/cms/home'
import { getNeighborhoodsGuideHome } from '@/api/neighborhoods-guide-home'

const fetchCMSContent = async (store, queryParams) => {
  const { glueEndpoint } = store.rootGetters
  const { domain } = store.rootState.context

  const homePageContent = await getHomePageContent(glueEndpoint, { domain }, queryParams, store)

  if (!homePageContent) return

  store.commit('setHomePageContent', homePageContent)
}

const gettingNeighborhoodsStates = async (store) => {
  try {
    const { states } = await getNeighborhoodsGuideHome()
    store.commit('setNeighborhoodsStates', states)
  } catch (error) {
    store.commit('setNeighborhoodsStates', [])
  }
}

export default {
  fetchCMSContent,
  gettingNeighborhoodsStates,
}
