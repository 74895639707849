const setFilters = (state, filters) => {
  state.filters = filters
}

const setSort = (state, sortParam) => {
  state.filters = {
    ...state.filters,
    sort: sortParam,
  }
}

export default {
  setFilters,
  setSort,
}
