
const UNIT_TYPES = [
  'unitTypes',
  'unitSubTypes',
  'usageTypes',
  'unitTypesV3',
]

function mountUnits (acc, types) {
  // eslint-disable-next-line no-restricted-syntax
  for (const type of UNIT_TYPES) {
    if (acc[type]) {
      const concatSeparator = type === 'unitSubTypes' ? '|' : ','
      acc[type] = acc[type].concat(`${concatSeparator}${types[type]}`)
    } else {
      acc[type] = types[type]
    }
  }

  return acc
}

const transformListForSingleObjectTypes = (value = []) => {
  if (!value.length) return {}

  return value.reduce((acc, el) => {
    const {
      unitTypes, unitSubTypes, usageTypes, unitTypesV3,
    } = el

    acc = mountUnits(acc, {
      unitTypes, unitSubTypes, usageTypes, unitTypesV3,
    })

    return acc
  }, {})
}

const getTypes = (values = []) => {
  const isValid = values.filter((value) => value.split('|').length === UNIT_TYPES.length).length

  if (!isValid) {
    return null
  }

  return values.reduce((acc, value) => {
    const [unitTypes, unitSubTypes, usageTypes, unitTypesV3] = value.split('|')

    acc = mountUnits(acc, {
      unitTypes, unitSubTypes, usageTypes, unitTypesV3,
    })

    return acc
  }, {})
}

const getTypesValues = (data = {}) => {
  const isValid = UNIT_TYPES.every((key) => (Object.prototype.hasOwnProperty.call(data, key)))

  if (!isValid) {
    return []
  }

  const values = []

  data.unitTypes.split(',').forEach((k, i) => {
    const types = []

    UNIT_TYPES.forEach((type) => {
      if (type !== 'unitSubTypes') {
        types.push(data[type].split(',')[i])
      } else if (type === 'unitSubTypes' && Array.isArray(data[type])) {
        types.push(data[type][i])
      } else {
        types.push(data[type].split('|')[i])
      }
    })

    values[i] = types.join('|')
  })

  return values
}

export {
  getTypes,
  getTypesValues,
  transformListForSingleObjectTypes,
}
