import { transformRecommendation } from '@/utils/transformers/recommendations'

const pageFilters = (size, page) => ({
  size,
  from: page?.uriPagination?.from || 0,
  page: page?.uriPagination?.page || 1,
  categoryPage: page?.uriCategory?.page || 'RESULT',
})

const getRecommendations = (recommendations) => {
  if (recommendations) {
    return transformRecommendation(recommendations)
  }
  return []
}

export { pageFilters, getRecommendations }
