// @ts-check

import deburr from 'lodash/deburr'

/**
 * @typedef {'compra' | 'aluguel' | 'compra-lancamentos' | 'descobrir'} FlowType
 */

/**
 * @argument {{
 *  businessType: import('./types').BusinessType
 *  listingType: import('./types').ListingType
 * }} input
 * @returns {FlowType | undefined}
 */
export function makeFlowType ({ businessType, listingType }) {
  if (listingType === 'DEVELOPMENT') {
    if (businessType === 'SALE') return 'compra-lancamentos'
  }

  if (listingType === 'USED') {
    if (businessType === 'SALE') return 'compra'
    if (businessType === 'RENTAL') return 'aluguel'
  }

  return undefined
}

/**
 * @param {string | number} v
 */
export function toCurrency (v) {
  if (!v && v !== 0) {
    return undefined
  }

  const value = typeof v === 'string' ? Number(v) : v

  return value
}

/**
 * @template {string|number} T
 * @param {T[]} array
 * @param {{ transformer?: (t: T) => string}} options
 * @returns {string | undefined}
 */
export function join (array, { transformer } = {}) {
  if (!array?.length) return undefined

  const clone = transformer
    ? array.map(transformer)
    : Array.from(array)

  clone.sort()

  return clone.join('|')
}

/**
 * @param {string} string
 * @returns {string}
 */
export function sanitize (string) {
  if (!string) return string

  return deburr(string)
    .toLocaleLowerCase()
    .trim()
    .replace(/[^\d\w\s]/g, '') // it removes special chars
    .replace(/\s+/g, '-') // it replaces spaces to `-`
}
