import queryString from 'query-string'

const buildUrl = (url, { query }) => {
  const result = queryString.parseUrl(url)

  Object.keys(query).forEach((k) => {
    result.query[k] = query[k]
  })

  if (Object.keys(result.query).length === 0) {
    return url
  }

  return `${result.url}?${queryString.stringify(result.query)}`
}

// eslint-disable-next-line
export { buildUrl }
