const ZAP_URL = 'https://www.zapimoveis.com.br/'
const GRUPO_ZAP_URL = 'https://www.grupozap.com/'
const MY_ZAP_URL = 'https://meuzap.zapimoveis.com.br/'
const ANNOUNCE_ZAP_URL = 'https://anuncie.zapimoveis.com.br'
const BLOG = 'https://www.zapimoveis.com.br/blog/'
const TRANSACTION_URL = 'https://negociacao.zapimoveis.com.br'
const PRIVACY_PORTAL_URL = 'https://privacidade.zapimoveis.com.br/'
const ZAP_FINANCING_URL = 'https://www.zapimoveis.com.br/calculadora-poder-compra/'
const ZAPMAIS_HELP = 'https://ajuda.zapmais.com/'
const ACCOUNT_ZAP_URL = 'https://account-api.grupozap.com/'
const HELP_ZAPMAIS = 'https://ajuda.zapmais.com/'

const ZAP_INFO_PATH = 'informacao'
const ZAP_INFO_PRIVACY_PATH = 'politica-de-privacidade'
const ZAP_INFO_QUALITY_PATH = 'politica-de-qualidade'
const ZAP_INFO_COOKIE_PATH = 'politica-de-cookies'
const ZAP_INFO_TERM_PATH = 's/article/termos-e-condicoes-gerais-de-uso-zap'

const ZAP_FAQ_URL = `${ZAP_URL}perguntas-frequentes`

// URLS - SALE/RENTAL/DEVELOPMENT/VIRTUAL TOUR
const ZAP_SALE_PATH = 'venda'
const ZAP_SALE_URL = `/${ZAP_SALE_PATH}/`
const ZAP_SALE_URL_DOMAIN = `${ZAP_URL}${ZAP_SALE_PATH}`

const ZAP_RENTAL_PATH = 'aluguel'
const ZAP_RENTAL_URL = `/${ZAP_RENTAL_PATH}/`
const ZAP_RENTAL_URL_DOMAIN = `${ZAP_URL}${ZAP_RENTAL_PATH}`

const ZAP_DEVELOPMENT_PATH = 'lancamentos'
const ZAP_DEVELOPMENT_URL = `/${ZAP_DEVELOPMENT_PATH}/`
const ZAP_DEVELOPMENT_URL_DOMAIN = `${ZAP_URL}${ZAP_DEVELOPMENT_PATH}`

const ZAP_PLAN_ONLY_PATH = 'lancamentos/imoveis/na-planta'
const ZAP_PLAN_ONLY_URL = `/${ZAP_PLAN_ONLY_PATH}/`
const ZAP_PLAN_ONLY_URL_DOMAIN = `${ZAP_URL}${ZAP_PLAN_ONLY_PATH}`

const ZAP_UNDER_CONSTRUCTION_PATH = 'lancamentos/imoveis/em-construcao'
const ZAP_UNDER_CONSTRUCTION_URL = `/${ZAP_UNDER_CONSTRUCTION_PATH}/`
const ZAP_UNDER_CONSTRUCTION_URL_DOMAIN = `${ZAP_URL}${ZAP_UNDER_CONSTRUCTION_PATH}`

const ZAP_BUILT_PATH = 'lancamentos/imoveis/pronto-para-morar'
const ZAP_BUILT_URL = `/${ZAP_BUILT_PATH}/`
const ZAP_BUILT_URL_DOMAIN = `${ZAP_URL}${ZAP_BUILT_PATH}`

const ZAP_VIRTUAL_TOUR_PATH = 'venda/?visitaVirtual=true'
const ZAP_VIRTUAL_TOUR_URL = `/${ZAP_VIRTUAL_TOUR_PATH}`
const ZAP_VIRTUAL_TOUR_URL_DOMAIN = `${ZAP_URL}${ZAP_VIRTUAL_TOUR_PATH}`

const ZAP_SITE_MAP_PATH = 'mapa-do-site'
const ZAP_SITE_MAP_URL = `/${ZAP_SITE_MAP_PATH}/`
const ZAP_SITE_MAP_URL_DOMAIN = `${ZAP_URL}${ZAP_SITE_MAP_PATH}`

const CHEAP_LISTINGS_RENTAL_PATH = 'alugar-imovel-barato'
const ZAP_CHEAP_LISTINGS_RENTAL_URL = `/${CHEAP_LISTINGS_RENTAL_PATH}/`
const ZAP_CHEAP_LISTINGS_RENTAL_URL_DOMAIN = `${ZAP_URL}${CHEAP_LISTINGS_RENTAL_PATH}`

const CHEAP_LISTINGS_SALE_PATH = 'comprar-imovel-barato'
const ZAP_CHEAP_LISTINGS_SALE_URL = `/${CHEAP_LISTINGS_SALE_PATH}/`
const ZAP_CHEAP_LISTINGS_SALE_URL_DOMAIN = `${ZAP_URL}${CHEAP_LISTINGS_SALE_PATH}`

const ZAP_NEIGHBORHOODS_GUIDE_PATH = 'guia-de-bairros'
const ZAP_NEIGHBORHOODS_GUIDE_URL = `/${ZAP_NEIGHBORHOODS_GUIDE_PATH}/`
const ZAP_NEIGHBORHOODS_GUIDE_URL_DOMAIN = `${ZAP_URL}${ZAP_NEIGHBORHOODS_GUIDE_PATH}`

const ZAP_DISCOVER_URL = '/descobrir/'
const ZAP_SEASONAL_CAMPAIGN_URL = '/feirao/festival-da-mudanca/'

// HEADER URL PARAMS
const TRANSACTION_HEADER_PARAMS = '?itl_id=1000098&itl_name=zap_-_link-header-menu-logged_zapway_to_zap_meu-zap'
const ZAP_FINANCING_HEADER_PARAMS = '?itl_id=1000070&itl_name=zap_-_link-header_financiamento_to_zap_calculadora_poder_compra'
const ANNOUNCE_ZAP_HEADER_PARAMS = '?itl_id=1000061&itl_name=zap_-_botao-header_anunciar_to_zap_anuncie'
const ZAPMAIS_HELP_HEADER_PARAMS = '/?itl_id=1000071&itl_name=zap_-_link-header_central-de-ajuda_to_zap_central-de-ajuda'
const ZAP_DISCOVER_HEADER_PARAMS = '?itl_id=1000069&itl_name=zap_-_link-header_descobrir_to_zap_descobrir'
const ZAP_SALE_HEADER_PARAMS = '?itl_id=1000063&itl_name=zap_-_link-header_comprar_to_zap_resultado-pesquisa'
const ZAP_RENTAL_HEADER_PARAMS = '?itl_id=1000064&itl_name=zap_-_link-header_alugar_to_zap_resultado-pesquisa'
const ZAP_DEVELOPMENT_HEADER_PARAMS = '?itl_id=1000065&itl_name=zap_-_link-header_lancamentos_to_zap_resultado-pesquisa'
const ZAP_PLAN_ONLY_HEADER_PARAMS = '?itl_id=1000066&itl_name=zap_-_link-header_na-planta_to_zap_resultado-pesquisa'
const ZAP_UNDER_CONSTRUCTION_HEADER_PARAMS = '?itl_id=1000067&itl_name=zap_-_link-header_em-construcao_to_zap_resultado-pesquisa'
const ZAP_BUILT_HEADER_PARAMS = '?itl_id=1000068&itl_name=zap_-_link-header_pronto-pra-morar_to_zap_resultado-pesquisa'
const LOGGED_FAVORITES_HEADER_PARAMS = '?itl_id=1000093&itl_name=zap_-_link-header-menu-logged_imoveis-favoritos_to_zap_meu-zap'
const LOGGED_CONTACTED_HEADER_PARAMS = '?itl_id=1000095&itl_name=zap_-_link-header-menu-logged_imoveis-contatados_to_zap_meu-zap'
const LOGGED_MY_PROFILE_HEADER_PARAMS = '?itl_id=1000096&itl_name=zap_-_link-header-menu-logged_meus-dados_to_zap_meu-zap'
const LOGGED_MY_LISTINGS_HEADER_PARAMS = '?itl_id=1000097&itl_name=zap_-_link-header-menu-logged_meus-anuncios_to_zap_meu-zap'
const LOGGED_MY_SAVED_SEARCHES_HEADER_PARAMS = '?itl_id=1000094&itl_name=zap_-_link-header-menu-logged_buscas-salvas_to_zap_meu-zap'

// FOOTER URL PARAMS
const ZAP_FINANCING_FOOTER_PARAMS = '?itl_id=1000122&itl_name=zap_-_link-footer_financiamento_to_zap_calculadora_poder_compra'
const PRIVACY_PORTAL_FOOTER_PARAMS = '?itl_id=1000129&itl_name=zap_-_link-footer_portal-de-privacidade_to_zap_login'
const ANNOUNCE_ZAP_FOOTER_PARAMS = '?itl_id=1000116&itl_name=zap_-_botao-footer_anunciar-imoveis_to_zap_anuncie'
const ZAPMAIS_HELP_FOOTER_PARAMS = '/?itl_id=1000131&itl_name=zap_-_link-footer_ajuda_to_zap_central-de-ajuda'
const ZAP_NEIGHBORHOODS_GUIDE_FOOTER_PARAMS = '/?itl_id=1000123&itl_name=zap_-_link-footer_guia-dos-bairros_to_zap_guia-de-bairros'
const BLOG_FOOTER_PARAMS = '?itl_id=1000125&itl_name=zap_-_link-footer_blog_to_zap_blog-do-zap'
const ZAP_INFO_PRIVACY_FOOTER_PARAMS = '?itl_id=1000126&itl_name=zap_-_link-footer_politicas-de-privacidade_to_zap_politicas-de-privacidade'
const ZAP_INFO_QUALITY_FOOTER_PARAMS = '?itl_id=1000134&itl_name=zap_-_link-footer_política-de-qualidade_to_zap_política-de-qualidade'
const ZAP_INFO_COOKIE_FOOTER_PARAMS = '?itl_id=1000127&itl_name=zap_-_link-footer_politica-de-cookies_to_zap_politica-de-cookies'
const ZAP_INFO_TERM_FOOTER_PARAMS = '?itl_id=1000128&itl_name=zap_-_link-footer_termos-de-uso_to_zap_termos-de-uso'
const ZAP_SALE_FOOTER_PARAMS = '?itl_id=1000117&itl_name=zap_-_link-footer_venda_to_zap_resultado-pesquisa'
const ZAP_RENTAL_FOOTER_PARAMS = '?itl_id=1000118&itl_name=zap_-_link-footer_aluguel_to_zap_resultado-pesquisa'
const ZAP_DEVELOPMENT_FOOTER_PARAMS = '?itl_id=1000119&itl_name=zap_-_link-footer_lancamentos_to_zap_resultado-pesquisa'
const ZAP_SITE_MAP_FOOTER_PARAMS = '?itl_id=1000130&itl_name=zap_-_link-footer_mapa-do-site_to_zap_mapa-do-site'
const ZAP_CHEAP_LISTING_RENTAL_FOOTER_PARAMS = '?itl_id=1000121&itl_name=zap_-_link-footer_aluguel-imovel-barato_to_zap_alugar-imovel-barato'
const ZAP_CHEAP_LISTING_SALE_FOOTER_PARAMS = '?itl_id=1000120&itl_name=zap_-_link-footer_comprar-imovel-barato_to_zap_comprar-imovel-barato'
const MY_ZAP_FOOTER_PARAMS = '?itl_id=1000124&itl_name=zap_-_link-footer_meu-zap_to_zap_login'
const ANNOUNCE_PLANS_FOOTER_PARAMS = '?itl_id=1000133&itl_name=zap_-_link-footer_planos-de-anuncio_to_zap_planos-de-anuncio'
const ANNOUNCE_SELL_FOOTER_PARAMS = '?itl_id=1000135&itl_name=zap_-_link-footer_venda-seu-imovel_to_zap_venda-seu-imovel'
const WORK_WITH_US_FOOTER_PARAMS = '?itl_id=1000136&itl_name=zap_-_link-footer_trabalhe-conosco_to_zap_trabalhe-conosco'

const MAIN_URL = {
  PATHS: {
    RENTAL: ZAP_RENTAL_PATH,
    SALE: ZAP_SALE_PATH,
    DEVELOPMENT: ZAP_DEVELOPMENT_PATH,
    PLAN_ONLY: ZAP_PLAN_ONLY_PATH,
    UNDER_CONSTRUCTION: ZAP_UNDER_CONSTRUCTION_PATH,
    BUILT: ZAP_BUILT_PATH,
    VIRTUAL_TOUR: ZAP_VIRTUAL_TOUR_PATH,
    SITE_MAP: ZAP_SITE_MAP_PATH,
    CHEAP_LISTINGS_RENTAL: CHEAP_LISTINGS_RENTAL_PATH,
    CHEAP_LISTINGS_SALE: CHEAP_LISTINGS_SALE_PATH,
    NEIGHBORHOODS_GUIDE: ZAP_NEIGHBORHOODS_GUIDE_PATH,
  },
  URLS: {
    RENTAL: ZAP_RENTAL_URL,
    SALE: ZAP_SALE_URL,
    DEVELOPMENT: ZAP_DEVELOPMENT_URL,
    PLAN_ONLY: ZAP_PLAN_ONLY_URL,
    UNDER_CONSTRUCTION: ZAP_UNDER_CONSTRUCTION_URL,
    BUILT: ZAP_BUILT_URL,
    VIRTUAL_TOUR: ZAP_VIRTUAL_TOUR_URL,
    SITE_MAP: ZAP_SITE_MAP_URL,
    CHEAP_LISTINGS_RENTAL: ZAP_CHEAP_LISTINGS_RENTAL_URL,
    CHEAP_LISTINGS_SALE: ZAP_CHEAP_LISTINGS_SALE_URL,
    NEIGHBORHOODS_GUIDE: ZAP_NEIGHBORHOODS_GUIDE_URL,
  },
  URLS_DOMAIN: {
    RENTAL: ZAP_RENTAL_URL_DOMAIN,
    SALE: ZAP_SALE_URL_DOMAIN,
    DEVELOPMENT: ZAP_DEVELOPMENT_URL_DOMAIN,
    PLAN_ONLY: ZAP_PLAN_ONLY_URL_DOMAIN,
    UNDER_CONSTRUCTION: ZAP_UNDER_CONSTRUCTION_URL_DOMAIN,
    BUILT: ZAP_BUILT_URL_DOMAIN,
    VIRTUAL_TOUR: ZAP_VIRTUAL_TOUR_URL_DOMAIN,
    SITE_MAP: ZAP_SITE_MAP_URL_DOMAIN,
    CHEAP_LISTINGS_RENTAL: ZAP_CHEAP_LISTINGS_RENTAL_URL_DOMAIN,
    CHEAP_LISTINGS_SALE: ZAP_CHEAP_LISTINGS_SALE_URL_DOMAIN,
    NEIGHBORHOODS_GUIDE: ZAP_NEIGHBORHOODS_GUIDE_URL_DOMAIN,
  },
}

// URLS - INFO
const ZAP_INFO_URL_DOMAIN = `${ZAP_URL}${ZAP_INFO_PATH}`
const ZAP_INFO_PRIVACY_URL_DOMAIN = `${ZAP_INFO_URL_DOMAIN}/${ZAP_INFO_PRIVACY_PATH}`
const ZAP_INFO_QUALITY_URL_DOMAIN = `${ZAP_INFO_URL_DOMAIN}/${ZAP_INFO_QUALITY_PATH}`
const ZAP_INFO_COOKIE_URL_DOMAIN = `${ZAP_INFO_URL_DOMAIN}/${ZAP_INFO_COOKIE_PATH}`
const ZAP_INFO_TERM_URL_DOMAIN = `${HELP_ZAPMAIS}/${ZAP_INFO_TERM_PATH}`

const ZAP_INFO_URL = ZAP_INFO_PATH
const ZAP_INFO_PRIVACY_URL = `/${ZAP_INFO_URL}/${ZAP_INFO_PRIVACY_PATH}/`
const ZAP_INFO_QUALITY_URL = `/${ZAP_INFO_URL}/${ZAP_INFO_QUALITY_PATH}/`
const ZAP_INFO_COOKIE_URL = `/${ZAP_INFO_URL}/${ZAP_INFO_COOKIE_PATH}/`

const ZAP_INFO = {
  PATHS: {
    INFO: `/${ZAP_INFO_PATH}`,
    PRIVACY: ZAP_INFO_PRIVACY_PATH,
    QUALITY: ZAP_INFO_QUALITY_PATH,
    TERM: ZAP_INFO_TERM_URL_DOMAIN,
  },
  URLS: {
    INFO: ZAP_INFO_URL,
    PRIVACY: ZAP_INFO_PRIVACY_URL,
    QUALITY: ZAP_INFO_QUALITY_URL,
    COOKIE: ZAP_INFO_COOKIE_URL,
    TERM: ZAP_INFO_TERM_URL_DOMAIN,
  },
  URLS_DOMAIN: {
    INFO: ZAP_INFO_URL_DOMAIN,
    PRIVACY: ZAP_INFO_PRIVACY_URL_DOMAIN,
    QUALITY: ZAP_INFO_QUALITY_URL_DOMAIN,
    COOKIE: ZAP_INFO_COOKIE_URL_DOMAIN,
    TERM: ZAP_INFO_TERM_URL_DOMAIN,
  },
}

const ZAP = {
  URL: ZAP_URL,
  MAIN: MAIN_URL,
  INFO: ZAP_INFO,
}

const EXTERNAL = {
  MY_ZAP_URL,
  BLOG,
  ANNOUNCE_ZAP_URL,
  GRUPO_ZAP_URL,
  PRIVACY_PORTAL_URL,
  ACCOUNT_ZAP_URL,
}

const HEADER_URL_PARAMS = {
  SALE: ZAP_SALE_HEADER_PARAMS,
  RENTAL: ZAP_RENTAL_HEADER_PARAMS,
  DEVELOPMENT: ZAP_DEVELOPMENT_HEADER_PARAMS,
  PLAN_ONLY: ZAP_PLAN_ONLY_HEADER_PARAMS,
  UNDER_CONSTRUCTION: ZAP_UNDER_CONSTRUCTION_HEADER_PARAMS,
  BUILT: ZAP_BUILT_HEADER_PARAMS,
  DISCOVER: ZAP_DISCOVER_HEADER_PARAMS,
  FINANCING: ZAP_FINANCING_HEADER_PARAMS,
  ANNOUNCE: ANNOUNCE_ZAP_HEADER_PARAMS,
  ZAPMAIS_HELP: ZAPMAIS_HELP_HEADER_PARAMS,
  TRANSACTION: TRANSACTION_HEADER_PARAMS,
  LOGGED_FAVORITES: LOGGED_FAVORITES_HEADER_PARAMS,
  LOGGED_CONTACTED: LOGGED_CONTACTED_HEADER_PARAMS,
  LOGGED_MY_PROFILE: LOGGED_MY_PROFILE_HEADER_PARAMS,
  LOGGED_MY_LISTINGS: LOGGED_MY_LISTINGS_HEADER_PARAMS,
  LOGGED_MY_SAVED_SEARCHES: LOGGED_MY_SAVED_SEARCHES_HEADER_PARAMS,
}

const FOOTER_URL_PARAMS = {
  SALE: ZAP_SALE_FOOTER_PARAMS,
  RENTAL: ZAP_RENTAL_FOOTER_PARAMS,
  DEVELOPMENT: ZAP_DEVELOPMENT_FOOTER_PARAMS,
  CHEAP_LISTING_RENTAL: ZAP_CHEAP_LISTING_RENTAL_FOOTER_PARAMS,
  CHEAP_LISTING_SALE: ZAP_CHEAP_LISTING_SALE_FOOTER_PARAMS,
  INFO_PRIVACY: ZAP_INFO_PRIVACY_FOOTER_PARAMS,
  INFO_COOKIE: ZAP_INFO_COOKIE_FOOTER_PARAMS,
  INFO_TERM: ZAP_INFO_TERM_FOOTER_PARAMS,
  INFO_QUALITY: ZAP_INFO_QUALITY_FOOTER_PARAMS,
  FINANCING: ZAP_FINANCING_FOOTER_PARAMS,
  BLOG: BLOG_FOOTER_PARAMS,
  SITE_MAP: ZAP_SITE_MAP_FOOTER_PARAMS,
  PRIVACY_PORTAL: PRIVACY_PORTAL_FOOTER_PARAMS,
  ZAPMAIS_HELP: ZAPMAIS_HELP_FOOTER_PARAMS,
  NEIGHBORHOODS_GUIDE: ZAP_NEIGHBORHOODS_GUIDE_FOOTER_PARAMS,
  ANNOUNCE: ANNOUNCE_ZAP_FOOTER_PARAMS,
  MY_ZAP: MY_ZAP_FOOTER_PARAMS,
  ANNOUNCE_PLANS: ANNOUNCE_PLANS_FOOTER_PARAMS,
  ANNOUNCE_SELL: ANNOUNCE_SELL_FOOTER_PARAMS,
  WORK_WITH_US: WORK_WITH_US_FOOTER_PARAMS,
}

export {
  GRUPO_ZAP_URL,
  ZAP_URL,
  MY_ZAP_URL,
  TRANSACTION_URL,
  ZAPMAIS_HELP,
  BLOG,
  ANNOUNCE_ZAP_URL,
  ZAP,
  EXTERNAL,
  ZAP_FAQ_URL,
  ZAP_DISCOVER_URL,
  ZAP_SEASONAL_CAMPAIGN_URL,
  ZAP_FINANCING_URL,
  HEADER_URL_PARAMS,
  FOOTER_URL_PARAMS,
}
