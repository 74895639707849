// TODO: Migrate to new tracking method
import Vue from 'vue'

const buildLoginFlowEvent = ({
  linkLabel, refererUrl, trig, type,
}) => ({
  login: {
    trig,
    referer_url: refererUrl,
    link_label: linkLabel,
    type,
  },
  event: 'loginFlow',
})

export default {
  loginFlow (data) {
    const event = buildLoginFlowEvent(data)
    Vue.$clickstream.trackEvent(event)
  },
}
