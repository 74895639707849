const ADDRESS_PRE_FIX = 'address'

const capitalize = (word) => word.charAt(0).toUpperCase() + word.slice(1)
const mountKeyName = (keyName) => `${ADDRESS_PRE_FIX}${capitalize(keyName)}`

const changeKeyAddressMapper = (item) => Object.entries(item).reduce((acc, [key, value]) => {
  if (!key) return { ...acc }

  return {
    ...acc,
    [`${mountKeyName(key)}`]: value,
  }
}, {})

export const transformLocationMapper = (address) => {
  if (!Array.isArray(address) || !address.length) return []

  const mountAddressWithPointValue = (location) => {
    const pointLat = location.pointLat ?? location.point?.lat
    const pointLon = location.pointLon ?? location.point?.lon

    if (location.point) {
      delete location.point
    }

    return {
      ...location,
      pointLat,
      pointLon,
    }
  }

  const currentAddressMapper = mountAddressWithPointValue(address[0])

  const getValue = (key, newValue) => ([currentAddressMapper[key], newValue].join(','))

  address.forEach((item, index) => {
    if (index === 0) { return }

    item = mountAddressWithPointValue(item)

    Object.entries(item).forEach(([key, value]) => {
      currentAddressMapper[key] = getValue(key, value).trim()
    })
  })

  return changeKeyAddressMapper(currentAddressMapper)
}

export default { transformLocationMapper }
