const httpStatusCode = (state) => state.httpStatus

const isHttpStatusClientError = (state) => state.httpStatus >= 400 && state.httpStatus < 500

const isHttpStatusServerError = (state) => {
  const statusCode = state.httpStatus
  return (statusCode >= 300 && statusCode < 400) || statusCode >= 500
}

const isHttpStatusError = (state) => {
  const isClientError = isHttpStatusClientError(state)
  const isServerError = isHttpStatusServerError(state)
  return isClientError || isServerError
}

export default {
  httpStatusCode,
  isHttpStatusClientError,
  isHttpStatusServerError,
  isHttpStatusError,
}
