import { buildUrl } from '@/utils/urls'
import { HttpStatusError } from '@/utils/error'

function is2xxStatusCode (statusCode) {
  return statusCode >= 200 && statusCode < 300
}

export function handleRedirectRuleException (response, routeQuery = {}) {
  const statusCode = response.status
  const httpStatusCode = response?.data?.page?.redirectRule?.httpStatusCode || 301
  const location = response?.data?.page?.redirectRule?.location || null

  if (is2xxStatusCode(statusCode) && location) {
    throw new HttpStatusError(
      'Http redirect rule',
      httpStatusCode,
      buildUrl(location, { query: routeQuery }),
    )
  }

  return response
}

export function handleRequestException (error) {
  const statusCode = error.response?.status
  const errorHandler = error.response?.config?.errorHandler

  if (errorHandler) throw errorHandler(error)

  throw new HttpStatusError(
    'Http server error',
    statusCode,
    null,
  )
}
