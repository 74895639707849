import addressFilter from '@/filters/address'
import humanizeFilter from '@/filters/humanize'

const getTitle = (isVisitLead, isVirtualTourRequest) => {
  if (isVirtualTourRequest) {
    return 'Gostaria de um tour virtual para o imóvel'
  }
  if (isVisitLead) {
    return 'Gostaria de agendar uma visita'
  }
  return 'Gostaria de ter mais informações'
}

/**
 *
 * @param {import('@/store/listing/state').Listing} listing
 * @param {{
 *  glossary: Glossary,
 *  isVisitLead?: boolean,
 *  isVirtualTourRequest?: boolean,
 *  omitPrice?: boolean
 * }} options
 * @returns
 */
export function buildLeadMessage (
  {
    address: rawAddress, pricingInfo: {
      price, businessDescription,
    }, unitTypes,
  },
  {
    glossary,
    isVisitLead = false,
    isVirtualTourRequest = false,
    omitPrice = false,
  },
) {
  const unitType = humanizeFilter({ glossary, key: 'unitType', value: unitTypes[0] })
  const listingAddress = addressFilter({ address: rawAddress, glossary })
  const title = getTitle(isVisitLead, isVirtualTourRequest)
  const subtitle = isVirtualTourRequest ? `${unitType}` : [businessDescription, unitType].filter(Boolean).join(': ')

  return [
    `Olá, ${title} `,
    ((isVirtualTourRequest || omitPrice)
      ? [subtitle, listingAddress, ''].filter(Boolean).join(', ')
      : [subtitle, price, listingAddress, ''].filter(Boolean).join(', ')),
    ', que encontrei no ZAP.',
    (isVirtualTourRequest
      ? ''
      : ' Aguardo seu contato, obrigado.'),
  ].join('')
}

const createLeadMessage = (
  listing,
  glossary,
  isVisitLead,
  isVirtualTourRequest,
) => buildLeadMessage(listing, { glossary, isVisitLead, isVirtualTourRequest })

export default createLeadMessage
