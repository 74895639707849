import { PAGE_SIZE_DEFAULT } from './constants/results'

function handlePagination (uriPagination, listingsSize, totalCounts) {
  const {
    page: currentPage = 1,
    size,
    total,
    totalCount = 0,
  } = uriPagination

  listingsSize ??= total

  const pageSize = size || listingsSize || PAGE_SIZE_DEFAULT
  const pageCount = Math.ceil(listingsSize / pageSize) || 0

  return {
    currentPage,
    listingsSize,
    pageCount,
    pageSize,
    totalCount,
    totalCounts,
  }
}

export default handlePagination
