import { HEADERS } from '@/utils/constants/api'

function addHeaders ({ authorization, xDeviceId } = {}) {
  return {
    headers: {
      ...(authorization && { [HEADERS.AUTHORIZATION]: `Bearer ${authorization}` }),
      [HEADERS.X_DEVICE_ID]: xDeviceId,
      [HEADERS.CONTENT_TYPE]: 'application/json',
    },
  }
}

export function createParamsUserInterestPayload (params = {}) {
  const {
    authorization, xDeviceId, ...rest
  } = params
  return {
    params: {
      ...rest,
    },
    withCredentials: true,
    ...addHeaders({ authorization, xDeviceId }),
  }
}

export function createBodyUserInterestPayload (params = {}) {
  const {
    authorization, xDeviceId, ...rest
  } = params
  return {
    data: {
      ...rest,
    },
    withCredentials: true,
    ...addHeaders({ authorization, xDeviceId }),
  }
}
