export const HOME_PAGE_HEADER = ({ sort }) => `
  zapHomePageHeaderCollection(
    publicationState: $state,
    ${sort === 'order' ? '' : `sort: "${sort}"`}
  ) {
    data {
      attributes {
        title
        image {
          data {
            attributes {
              url
              formats
            }
          }
        }
        altImage
        h2
        h3
      }
    }
  }
`

export const HOME_PAGE_SHOWCASE = ({ sort }) => `
  zapHomePageShowcaseCollection(
    publicationState: $state,
    sort: "${sort}",
  ) {
    data {
      attributes {
        h3
        image {
          data {
            attributes {
              url
            }
          }
        }
        altImage
        linkReference
        subject
        bodyCopy
        order
      }
    }
  }
`

export const HOME_PAGE_BANNER = ({ sort }) => `
  zapHomePageBannerCollection(
    publicationState: $state,
    ${sort === 'order' ? '' : `sort: "${sort}"`}
  ) {
    data {
      attributes {
        title
        image {
          data {
            attributes {
              url
            }
          }
        }
        altImage
        textColor
        backgroundColor
        icon {
          data {
            attributes {
              url
            }
          }
        }
        linkDesktop
        linkMobile
        subject
        bodyCopy
        h3
      }
    }
  }
`

export const HOME_PAGE_BLOG = ({ sort }) => `
  zapHomePageZapBlogCollection(
    publicationState: $state,
    ${sort === 'order' ? '' : `sort: "${sort}"`}
  ) {
    data {
      attributes {
        title
        h2
        linkReference
      }
    }
  }
`

const HOME_PAGE_QUERY = ({ sort }) => `
  ${HOME_PAGE_HEADER({ sort })}
  ${HOME_PAGE_SHOWCASE({ sort })}
  ${HOME_PAGE_BANNER({ sort })}
  ${HOME_PAGE_BLOG({ sort })}
`

export default HOME_PAGE_QUERY
