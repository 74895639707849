// @ts-check

/**
 * @typedef {{
 *  business: import('@/store/core/types').BusinessType
 *  listingType: import('@/store/core/types').ListingType
 * }} ResultsFilter
 *
 *
 * @typedef {{
 *  filters: ResultsFilter
 * }} ResultsState
 */

export default () => ({
  listings: [],
  superPremiumListings: [],
  filters: {},
  pagination: {},
  levels: '',
  loading: false,
  isMainLocationVisible: false,
  contentResultPage: { publicityContent: {} },
  isShowMobileMapButton: true,
  isShowRefineSearchOnMobile: false,
  isMapOpen: false,
  facets: {},
  seasonalCampaigns: [],
})
