import queryString from 'query-string'

const campaignQueries = {
  itm_id: true,
  itm_source: true,
  itm_medium: true,
  itm_campaign: true,
  itm_content: true,
  itm_term: true,
  itl_id: true,
  itl_source: true,
  itl_medium: true,
  itl_campaign: true,
  itl_content: true,
  itl_term: true,
  itl_name: true,
}

export const allowedQueries = Object.freeze({
  transacao: true,
  pagina: true,
  quartos: true,
  banheiros: true,
  vagas: true,
  precoMinimo: true,
  precoMaximo: true,
  precoMinimoCondo: true,
  precoMaximoCondo: true,
  precoTotalMinimo: true,
  precoTotalMaximo: true,
  areaMinima: true,
  areaMaxima: true,
  areaTotalMinima: true,
  areaTotalMaxima: true,
  proximoAoMetro: true,
  id: true,
  endereco: true,
  tipo: true,
  tipos: true,
  ordem: true,
  tipoUnidade: true,
  tipoAluguel: true,
  onde: true,
  visitaVirtual: true,
  gclid: true,
  amenities: true,
  stamps: true,
  __zt: true,
  utm_source: true,
  utm_medium: true,
  utm_campaign: true,
  utm_term: true,
  utm_content: true,
  utm_referrer: true,
  preview: true,
  warranties: true,
  skipCache: true,
  cmsPreview: true,
  webPushId: true,
  redirectAction: true,
  q: true,
  ...campaignQueries,
})

export function removeUnsafeQueries () {
  const search = window.location.search.substring(1)
  if (!search) return

  const queries = queryString.parse(search)
  const queriesNames = Object.getOwnPropertyNames(queries)
  const newQuery = {}

  if (!queriesNames.length) {
    return
  }

  queriesNames.forEach((q) => {
    if (allowedQueries[q]) {
      newQuery[q] = queries[q]
    } else {
      // eslint-disable-next-line
      console.error(`You tried to use a query string called "${q}" but isn't declared in router policy allowedQueries. Removing it from navigation.`)
    }
  })

  const safeQueries = new URLSearchParams(newQuery).toString()
  const safeUrl = `${window.location.origin}${window.location.pathname}?${safeQueries}${window.location.hash}`

  window.history.replaceState({}, document.title, safeUrl)
}
