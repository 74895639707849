const loginUrl = (state) => {
  const {
    context: { config: { loginUrl: url } },
  } = state

  return url
}

const fredoUrl = (state) => state.context.config.fredoEndpoint

const currentPath = (state) => {
  const {
    route: { path },
  } = state

  return path
}

const currentQuery = (state) => {
  const {
    route: { query },
  } = state

  return query
}

const safeQuery = (state) => {
  if (!state.route) return {}

  /* eslint-disable camelcase */
  const {
    __zt,
    gclid,
    utm_source,
    utm_medium,
    utm_campaign,
    utm_term,
    utm_content,
    utm_referrer,
  } = state.route.query ?? {}

  const nonEmptyValues = Object.entries({
    __zt,
    gclid,
    utm_source,
    utm_medium,
    utm_campaign,
    utm_term,
    utm_content,
    utm_referrer,
  }).filter(([_, value]) => value)
  /* eslint-enable camelcase */

  if (Object.fromEntries) {
    return Object.fromEntries(nonEmptyValues)
  }

  // Do not remove this please, it is for browsers that doesn't support Object.fromEntries
  // Sentry is reporting some errors on this, but they are normally
  // from city areas with less conditions, accessibility and tend to use older technology
  // let's keep this for a while, at least until https://caniuse.com/?search=Object.fromEntries
  // reports a 98% usage
  return nonEmptyValues.reduce((acc, [key, val]) => {
    acc[key] = val
    return acc
  }, {})
}

const currentFullPath = (state) => {
  const {
    route: { fullPath },
  } = state

  return fullPath
}

const currentHash = (state) => {
  const { route: { hash } } = state

  if (!hash) return {}

  return hash.split('&')
    .reduce((params, item) => {
      const [rawKey, value] = item.split('=')

      if (rawKey) {
        const key = rawKey.replace(/\W+/g, '')
        return Object.assign(params, { [key]: value })
      }

      return params
    }, {})
}

const glueEndpoint = (state) => {
  const {
    glueApiEndpoint,
    glueApiSsrEndpoint,
  } = state.context.config
  return __BROWSER__ ? glueApiEndpoint : glueApiSsrEndpoint
}

const aldoEndpoint = (state) => state.context.config.aldoApiEndpoint

const userInterestEndpoint = (state) => state.context.config.userInterestApiEndpoint

const zendeskEndpoint = (state) => state.context.config.zendeskApiEndpoint

const silvioEndpoint = (state) => state.context.config.silvioApiEndpoint

const casioEndpoint = (state) => state.context.config.casioApiEndpoint

const accountEndpoint = (state) => state.context.config.accountApiEndpoint

const myAccountEndpoint = (state) => state.context.config.myAccountApiEndpoint

// Assume mobile first when no device config available for ui intent (a.k.a. SSR)
const device = (state) => state.device || { platform: 'mobile', isMobile: true }

const currentPlatform = (state) => state.device.platform

const currentBreakpoint = (state) => state.device.innerWidth

const isMobile = (state) => state.device.isMobile

const isTablet = (state) => state.device.isTablet

const isDesktop = (state) => state.device.isDesktop

const simplifiedPlatform = (state, getters) => (getters.isMobile ? 'MOBILE' : 'DESKTOP')

export default {
  loginUrl,
  fredoUrl,
  currentPath,
  currentQuery,
  safeQuery,
  currentFullPath,
  currentHash,
  glueEndpoint,
  aldoEndpoint,
  userInterestEndpoint,
  zendeskEndpoint,
  silvioEndpoint,
  casioEndpoint,
  accountEndpoint,
  myAccountEndpoint,
  device,
  currentPlatform,
  simplifiedPlatform,
  currentBreakpoint,
  isMobile,
  isTablet,
  isDesktop,
}
