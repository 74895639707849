import { addressTransform } from '@/utils/tracking/address'

const pageView = ({ links = [], filters = { addresses: [] }, pagination = {} }) => {
  const { currentPage } = pagination
  const advertisersIds = links[currentPage - 1]?.map((item) => item.id) ?? []

  return {
    advertisers: {
      filter: {
        addresses: [addressTransform(filters.addresses[0])],
      },
      advertisersIds,
      pagination,
    },
    event: 'advertisersRendered',
  }
}

export default pageView
