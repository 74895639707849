import Cookie from 'js-cookie'
import isString from 'lodash/isString'

import cookies from '@/utils/constants/cookies'
import queryString from 'query-string'

const EXPIRE_ACCESS_TOKEN_DAYS = 1
const EXPIRE_AUTH_DAYS = 90

/**
 * @typedef {Object} SetOptions
 * @property {number} expires - number of days until the cookie expires in days
 */

export default {
  /**
   * @param {string} key
   * @return {string}
   */
  get (key) {
    return Cookie.get(key)
  },

  /**
   *
   * @param {string} key
   * @param {string} value
   * @param {SetOptions} options
   */
  set (key, value, options) {
    Cookie.set(key, value, options)
  },

  getAccessToken () {
    return Cookie.get(cookies.AUTH.AUTHENTICATION)
  },

  getRenderTime () {
    return Cookie.get(cookies.PERFORMANCE.RENDER)
  },

  getCacheStatus () {
    return Cookie.get(cookies.PERFORMANCE.CACHE)
  },

  setAccessToken (token) {
    Cookie.set(cookies.AUTH.AUTHENTICATION, token, { domain: '.zapimoveis.com.br', expires: EXPIRE_AUTH_DAYS })
  },

  removeAccessToken () {
    return Cookie.remove(cookies.AUTH.AUTHENTICATION, { domain: '.zapimoveis.com.br' })
  },

  getUserId () {
    const userId = Cookie.get(cookies.AUTH.USER_CODE_IDENTIFIER)
    return isString(userId) ? userId : ''
  },

  getUser () {
    const stringfiedUser = Cookie.get(cookies.AUTH.USER)

    if (stringfiedUser) return queryString.parse(stringfiedUser)

    return null
  },

  setUser (user) {
    const { uuid, ...rest } = user
    const stringfyUser = queryString.stringify(rest)
    Cookie.set(cookies.AUTH.USER, stringfyUser, { domain: '.zapimoveis.com.br', expires: EXPIRE_AUTH_DAYS })
    Cookie.set(cookies.AUTH.USER_CODE_IDENTIFIER, uuid, { domain: '.zapimoveis.com.br', expires: EXPIRE_AUTH_DAYS })
  },

  deleteUser () {
    Cookie.remove(cookies.AUTH.USER, { domain: '.zapimoveis.com.br' })
    Cookie.remove(cookies.AUTH.USER_CODE_IDENTIFIER, { domain: '.zapimoveis.com.br' })
  },

  getResearch () {
    return !!Cookie.get(cookies.RESEARCH.NAME)
  },

  setResearch (isViewed) {
    Cookie.set(
      cookies.RESEARCH.NAME,
      isViewed,
      { expires: cookies.RESEARCH.EXPIRES_DAYS },
    )
  },

  getLGPDCompliance () {
    return !!Cookie.get(cookies.LGPD.NAME)
  },

  setRentalUserData (rentalData) {
    Cookie.set(cookies.RENTAL.NAME, JSON.stringify(rentalData), { domain: '.zapimoveis.com.br', expires: EXPIRE_ACCESS_TOKEN_DAYS })
  },
}
