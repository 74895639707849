import SP from '../../assets/images/home/sp.jpg'
import RJ from '../../assets/images/home/rj.jpg'
import MG from '../../assets/images/home/mg.jpg'
import RS from '../../assets/images/home/rs.jpg'
import PR from '../../assets/images/home/pr.jpg'

export default {
  SP,
  RJ,
  MG,
  RS,
  PR,
}
