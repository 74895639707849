import Vue from 'vue'
import onVisible from './onVisible'

export default function renderWhenVisible (loader) {
  return Vue.extend({
    mixins: [{
      beforeCreate () {
        this.shouldRender = false
        this.cleanupHandlers = []
      },
      beforeDestroy () {
        this.cleanupHandlers.forEach((handler) => handler())
      },
      mounted () {
        const cleanup = onVisible(() => {
          this.shouldRender = true
          this.$forceUpdate()
        }, {
          el: this.$el,
          rootMargin: '100% 0px',
        })

        this.cleanupHandlers.push(cleanup)
      },
      render (h) {
        if (this.shouldRender) {
          return h(loader, {
            attrs: this.$attrs,
            on: this.$listeners,
            scopedSlots: this.$scopedSlots,
          }, this.$slots.default)
        }

        return h('div')
      },
    }],
  })
}
