import throttle from 'lodash/throttle'
import { mapState, mapGetters } from 'vuex'
import LazyHydrate, { hydrateWhenVisible } from 'vue-lazy-hydration'
import newHeader from '@/components/new-header'
import { GZUID_DATA } from '@/app/constants'
import cookieStorage from '@/storage/cookie-storage'
import localStorage from '@/storage/local-storage'
import device from '@/utils/device'
import gzuis from '@/utils/gzuis'
import events from '@/plugins/events'
import getUserLocation from '@/mixins/page-get-user-location'
import renderWhenVisible from '@/components/render-when-visible'
import { build as metadata } from '@/lib/metadata/metadata'
import gtm from '@/lib/metadata/gtm'
import {
  robots,
  description,
  image,
  title,
  schema,
  canonicalUrl,
} from '@/lib/metadata/seo'
import Vue from 'vue'
import select from '@/utils/select'
import { getBreadcrumbsSchema } from '@/store/page/selectors'
import webVitalsTracker from './web-vitals.tracker'
import loginTracker from './login.tracker'
import zapImoveisMetaImage from '../assets/images/zap-imoveis-ok.png'

const isProd = process.env.NODE_ENV === 'production'

export default Vue.extend({
  name: 'App',

  mixins: [getUserLocation],

  meta () {
    return metadata([
      ({ meta, link }) => {
        meta({ charset: 'utf-8' })
        meta({ name: 'viewport', content: 'width=device-width, initial-scale=1.0, maximum-scale=5.0' })
        meta({ name: 'theme-color', content: '#6e0ad6' })
        meta({ name: 'apple-itunes-app', content: 'app-id=514620313, app-clip-bundle-id=br.com.zap.ZAPImoveis.AppClip' })
        link({ rel: 'icon', sizes: '32x32', href: `${__PUBLICPATH__}32x32.png` })
        link({ rel: 'icon', sizes: '128x128', href: `${__PUBLICPATH__}128x128.png` })
        link({ rel: 'icon', sizes: '167x167', href: `${__PUBLICPATH__}167x167.png` })
        link({ rel: 'icon', sizes: '192x192', href: `${__PUBLICPATH__}192x192.png` })
        link({ rel: 'icon', sizes: '196x196', href: `${__PUBLICPATH__}196x196.png` })
        link({ rel: 'apple-touch-icon', sizes: '120x120', href: `${__PUBLICPATH__}120x120.png` })
        link({ rel: 'apple-touch-icon', sizes: '152x152', href: `${__PUBLICPATH__}152x152.png` })
        link({ rel: 'apple-touch-icon', sizes: '180x180', href: `${__PUBLICPATH__}180x180.png` })
        link({ rel: 'preconnect', crossorigin: true, href: 'https://assets.zap.com.br' })
        link({ rel: 'preconnect', crossorigin: true, href: 'https://fonts.gstatic.com/' })
        link({ rel: 'dns-prefetch', crossorigin: true, href: 'https://adservice.google.com' })
        link({ rel: 'dns-prefetch', crossorigin: true, href: 'https://www.googletagmanager.com' })
        link({ rel: 'dns-prefetch', crossorigin: true, href: 'https://connect.facebook.net' })
        link({ rel: 'dns-prefetch', crossorigin: true, href: 'https://securepubads.g.doubleclick.net.com' })
        link({ rel: 'dns-prefetch', crossorigin: true, href: 'https://ads.rubiconproject.com' })
        link({ rel: 'dns-prefetch', crossorigin: true, href: 'https://analytics.trovit.com' })
        link({ rel: 'dns-prefetch', crossorigin: true, href: 'https://tags.t.tailtarget.com' })
        link({ rel: 'dns-prefetch', crossorigin: true, href: 'https://static.criteo.net' })
        link({ rel: 'manifest', href: __COMANDANTE_DISABLED__ ? `${__PUBLICPATH__}/manifest.json` : `/assets/${__RELEASE__}/manifest.json` })
      },
      title(this.metaContent.title ?? 'ZAP Imóveis | Apartamentos, Casas e Imóveis à Venda e para Alugar'),
      description(this.metaContent.description ?? 'Encontre casas, apartamentos, terrenos e imóveis para compra, venda e aluguel. Acesse já!'),
      image(zapImoveisMetaImage),
      canonicalUrl(this.canonicalUrl),
      robots(this.renderRobotsMetaTag || this.metaContent.robots, { query: this.$route.query }),
      gtm(this.gtmId),
      schema(this.breadcrumbsSchema),
      schema(this.pageSchema),
    ])
  },

  data () {
    return {
      gzuid: GZUID_DATA,
    }
  },

  mounted () {
    if (this.isHttpStatusError) {
      window.location = `/http-error#${this.httpStatusCode}`
    }

    window.addEventListener('resize', ($event) => {
      this.emitEvent($event)
    })

    window.addEventListener('message', ({ origin, data: { GZUID } }) => {
      if (origin.includes(GZUID_DATA.domain) && GZUID) {
        localStorage.setGzuid(GZUID)
      }
    })

    gzuis.getCookie(['social_context', 'referer', 'trig'])
    gzuis.listen('social_context', (socialContext) => { localStorage.setSocialContext(socialContext) })
    gzuis.listen('referer', (referer) => { localStorage.setReferer(referer) })
    gzuis.listen('trig', (trig) => { localStorage.setTrig(trig) })

    setTimeout(() => {
      this.trackSuccessfulLogin()
    }, 500)

    this.$nextTick(() => {
      this.$store.dispatch('favorites/getFavorites').finally(() => {
        this.$store.dispatch('favorites/checkFavoriteStorage')
      })
      this.$store.dispatch('savedSearch/checkSavedSearchStorage')
    })

    if (isProd) {
      webVitalsTracker({
        ...this.performance,
        renderTime: cookieStorage.getRenderTime(),
        cacheStatus: cookieStorage.getCacheStatus(),
      })
    }
  },

  computed: {
    ...mapGetters({
      currentQuery: 'currentQuery',
      httpStatusCode: 'errors/httpStatusCode',
      isHttpStatusClientError: 'errors/isHttpStatusClientError',
      isHttpStatusError: 'errors/isHttpStatusError',
      metaContent: 'page/getMetaContent',
      pageSchema: 'schema/getJson',
      getWidgetByCategory: 'page/getWidgetByCategory',
      isLogged: 'auth/isLogged',
      userData: 'auth/user',
    }),

    ...mapState({
      canonicalUrl: (state) => state.context.canonicalUrl,
      renderRobotsMetaTag: (state) => state.context.renderRobotsMetaTag?.value,
      pagePath: (state) => state.page.path,
      performance: (state) => state.performance,
      uriCategory: (state) => state.page.uriCategory,
      gtmId: (state) => state.context.config.gtmId,
    }),

    breadcrumbsSchema: select(getBreadcrumbsSchema),

    showHeader () {
      return this.$route.name !== 'salesforce'
    },

    breadcrumb () {
      return this.breadcrumbWidget?.links ?? []
    },
  },

  watch: {
    isHttpStatusError () {
      if (this.isHttpStatusError) {
        this.redirectError()
      }
    },
  },

  methods: {
    emitEvent: throttle(function (event) {
      this.$store.commit('setDevice', device.getCurrentMediaConfig())
      events.$emit('resize', event)
    }, 200, { leading: false, trailing: true }),

    redirectError () {
      this.$router.push({ path: '/http-error', hash: `#${this.httpStatusCode}` })
    },

    trackSuccessfulLogin () {
      const socialContext = localStorage.getSocialContext()

      if (this.isLogged && socialContext) {
        loginTracker.loginFlow({
          link_label: socialContext,
          trig: localStorage.getTrig(),
          referer_url: localStorage.getReferer(),
          type: 'LOGIN',
        })

        localStorage.cleanSocialContext()
        localStorage.cleanReferer()
        localStorage.cleanTrig()
        gzuis.deleteCookie(['social_context', 'referer', 'trig'])
      }
    },
  },

  components: {
    LazyHydrate,
    newHeader,
    httpError: hydrateWhenVisible(() => import(/* webpackChunkName: "httpError" */ '@/pages/http-error')),
    ozFooter: renderWhenVisible(() => import(/* webpackChunkName: "ozFooter" */ '@/components/oz-footer')),
  },
})
