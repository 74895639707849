/**
 * @param { VoidFunction } callback
 * @param {{ el: Element, rootMargin: string }} options
 * @returns { VoidFunction } cleanup
 */
export default function onVisible (callback, { el, ...options }) {
  /** @type {IntersectionObserver} */
  let observer

  function cleanup () {
    observer?.unobserve(el)
  }

  function runCallback () {
    callback()
    cleanup()
  }

  if (typeof IntersectionObserver === 'undefined') {
    return runCallback()
  }

  observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      // Use `intersectionRatio` because of Edge 15's
      // lack of support for `isIntersecting`.
      // See: https://github.com/w3c/IntersectionObserver/issues/211
      const isIntersecting = entry.isIntersecting || entry.intersectionRatio > 0
      if (isIntersecting) {
        runCallback()
      }
    })
  }, options)

  observer.observe(el)

  return cleanup
}
