import transformerListing from '@/utils/transformers/listing'
import { RESULTS } from '@/utils/constants'
import { unitTypesFieldsEncode } from '@/utils/transformers/unit-type'
import {
  PAGE_SIZE_100,
  PAGE_SIZE_DEFAULT,
  PAGE_SIZE_PUBLISHER,
  TYPES_FOR_REGULAR_LISTINGS,
} from '@/utils/constants/results'
import { transformLocationMapper } from './location'
import { transformListForSingleObjectTypes } from './filters/unit-types'

const sortFieldTransform = (sortField, allFields) => {
  const sortOptionByValue = RESULTS.SORT_OPTIONS.find((opt) => opt.value === sortField)
  const sortMapped = sortOptionByValue ? { sort: sortOptionByValue.toParam(allFields) } : {}
  return sortMapped
}

function calculateFrom (from, page, size) {
  return Math.max(from || (page - 1 || 0) * size, 0)
}

const isDevelopment = (business, listingType) => business === 'SALE' && listingType === 'DEVELOPMENT'

const isRental = (business, listingType) => business === 'RENTAL' && listingType === 'USED'

const calctIncludeFieldsByListingType = (business, listingType, sort) => {
  if (isDevelopment(business, listingType)) {
    return sort ? RESULTS.SORT_INCLUDE_FIELDS : RESULTS.INCLUDE_FIELDS_ONLY_DEVELOPMENTS
  }

  if (isRental(business, listingType)) {
    return sort ? RESULTS.SORT_INCLUDE_FIELDS : RESULTS.INCLUDE_FIELDS_WITHOUT_DEVELOPMENTS
  }

  return false
}

const buildIncludeFields = (sort, publisherPage, business, listingType, isCampaignPage) => {
  let includeFields = RESULTS.INCLUDE_FIELDS

  if (isCampaignPage) includeFields = RESULTS.INCLUDE_FIELDS_WITHOUT_SCHEMA

  if (sort) includeFields = RESULTS.SORT_INCLUDE_FIELDS

  const includeFieldsByListingType = calctIncludeFieldsByListingType(business, listingType, sort)
  if (includeFieldsByListingType) includeFields = includeFieldsByListingType

  if (publisherPage) includeFields = RESULTS.PUBLISHER_INCLUDE_FIELDS

  return includeFields
}

// eslint-disable-next-line complexity
const fieldsTransform = (
  {
    fields,
    publisherPage = false,
    isCondominiumPage = false,
    isCampaignPage = false,
    isPageSize100 = false,
  },
) => {
  const NEW_PAGE_SIZE_DEFAULT = isPageSize100 ? PAGE_SIZE_100 : PAGE_SIZE_DEFAULT
  const {
    addresses,
    amenities = [],
    stamps = [],
    unitTypes = {},
    sort,
    from: rawFrom,
    page,
    size = publisherPage ? PAGE_SIZE_PUBLISHER : NEW_PAGE_SIZE_DEFAULT,
    viewport,
    labels,
    ...restFields
  } = fields

  const includeFields = buildIncludeFields(
    sort,
    publisherPage,
    fields.business,
    fields.listingType,
    isCampaignPage,
  )

  const addressFields = viewport ? { viewport } : transformLocationMapper(addresses)

  const amenitiesField = amenities.length ? { amenities: amenities.join(',') } : {}
  const stampsField = stamps.length ? { stamps: stamps.join(',') } : {}

  const seasonalCampaignFields = {
    stamps: stampsField.stamps,
    business: fields.business || 'SALE',
    listingType: fields.listingType || 'USED',
  }

  const condominiumFields = {
    facetsCondominium: true,
    paginateCondominium: true,
    similar: true,
  }

  const superPremiumSize = publisherPage
    ? RESULTS.SUPER_PREMIUM_FEATURED_SIZE : RESULTS.SUPER_PREMIUM_SIZE

  const notCondominiumFields = {
    includeFields,
    developmentsSize: RESULTS.DEVELOPMENTS_SIZE,
    superPremiumSize,
  }

  const campaignPage = isCampaignPage ? seasonalCampaignFields : {}
  const condominiumPage = isCondominiumPage ? condominiumFields : notCondominiumFields

  let treatedUnitTypes = {}

  if (Array.isArray(unitTypes)) {
    treatedUnitTypes = transformListForSingleObjectTypes(unitTypes)
  } else if (typeof unitTypes !== 'string') {
    treatedUnitTypes = unitTypesFieldsEncode(unitTypes)
  }

  return {
    ...restFields,
    ...treatedUnitTypes,
    ...sortFieldTransform(sort, fields),
    ...addressFields,
    ...amenitiesField,
    ...stampsField,
    size,
    from: calculateFrom(rawFrom, page, size),
    page,
    ...condominiumPage,
    ...campaignPage,
  }
}

const listingsResultTransformer = ({
  listings,
  type,
  businessTypeContext,
  glossary,
  noType,
}) => listings.map((item) => {
  let currentType = type
  if (!noType && !type && item.listing) {
    const { publicationType } = item.listing
    currentType = TYPES_FOR_REGULAR_LISTINGS[publicationType]
  }

  return {
    type: currentType,
    link: item.link,
    account: item.account,
    listing: transformerListing({
      ...item,
      alternateAddress: item.link.data,
      businessTypeContext,
      glossary,
    }),
  }
})

const isGenericRpFilterTransformer = (query) => {
  const {
    transacao,
    tipo,
    pagina,
    ...other
  } = query

  const validOther = Object.entries(other).filter(([_, val]) => val)
  const otherObj = Object.fromEntries(validOther)

  return Object.keys(otherObj).length === 0
    && (transacao === 'Venda' || transacao === 'Aluguel')
    && Number(pagina) === 1
    && tipo === 'Imóvel usado'
}

const checkMainLocations = (query, path) => {
  //if there are 3 default query string values for RP and other filters
  const hasFilters = Object.keys(query).length >= 3
  const isRentalOrSale = path === '/venda/' || path === '/aluguel/'
  return isRentalOrSale && (!hasFilters || isGenericRpFilterTransformer(query))
}

export {
  sortFieldTransform,
  fieldsTransform,
  listingsResultTransformer,
  checkMainLocations,
}
