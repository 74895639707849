const AUTH = Object.freeze({
  USER: 'conusu',
  USER_CODE_IDENTIFIER: 'uid',
  AUTHENTICATION: 'zapAccessToken',
})

const PERFORMANCE = Object.freeze({
  RENDER: 'X-render-time',
  CACHE: 'cf-cache-status',
})

const RENTAL = Object.freeze({
  NAME: 'rentalUserData',
})

const RESEARCH = Object.freeze({
  NAME: 'rsc',
  EXPIRES_DAYS: 15,
})

const LGPD = Object.freeze({
  NAME: 'cookie-notifier',
})

const ANONYMOUS_ID = Object.freeze({
  NAME: 'z_anonymous_id',
  EXPIRES_DAYS: 90,
})

export default {
  AUTH,
  RENTAL,
  ANONYMOUS_ID,
  RESEARCH,
  LGPD,
  PERFORMANCE,
}
