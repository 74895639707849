import getSortedObject from '@/utils/constants/cms/dataManagement'

export const treatHeader = (raw, treated = {}) => {
  treated.header = {
    ...raw.zapHomePageHeaderCollection.data[0].attributes,
    image: raw.zapHomePageHeaderCollection.data[0]
      .attributes.image.data.attributes,
  }
  return treated
}

export const treatShowcase = (raw, treated = {}) => {
  treated.showcase = getSortedObject(raw.zapHomePageShowcaseCollection.data)
    .map((item) => ({
      ...item,
      image: item.image.data.attributes,
    }))
  return treated
}

export const treatBanner = (raw, treated = {}) => {
  treated.banner = {
    ...raw.zapHomePageBannerCollection.data[0].attributes,
    image: raw.zapHomePageBannerCollection.data[0].attributes
      .image.data?.attributes,
    icon: raw.zapHomePageBannerCollection.data[0].attributes
      .icon.data?.attributes,
  }
  return treated
}

export const treatBlog = (raw, treated = {}) => {
  treated.blog = raw.zapHomePageZapBlogCollection.data[0].attributes
  return treated
}
