import merge from 'lodash/merge'

const transform = (accountData, { totalCount: relatedLinksCount }, [accountLink = {}]) => {
  const { href: url } = accountLink

  const account = {
    url,
    relatedLinksCount,
  }

  return merge({}, accountData, account)
}

export default transform
