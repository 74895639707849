import snakeCase from 'lodash/snakeCase'

import { mapKeysDeep } from './map-deep'

const trackingBlocklist = [
  'transactionId',
  'transactionTax',
  'transactionTotal',
  'transactionProducts',
  'transactionShipping',
  'transactionAffiliation',
  '_clear',
]

export const snakeCaseKeys = (data) => mapKeysDeep(data, snakeCase, trackingBlocklist)

export default { snakeCaseKeys }
