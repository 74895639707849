// @ts-check

import { getUserContact } from '../auth/selector'
import defineSelectorsOf from '../core/define-selectors-of'

/**
 * @type {SelectorFactory<import('./state').LeadsState>}
 */
const defineSelector = defineSelectorsOf('leads')

export const getLeadId = defineSelector((state) => state.lead?.id)

export const getLatestLead = defineSelector((state) => state.lead)

export const getLeadContact = defineSelector((state, select) => {
  const latestLead = select(getLatestLead)

  if (latestLead) {
    /** @type {import('@/store/auth/state').UserContact} */
    const contact = {
      name: latestLead.name,
      email: latestLead.email,
      phoneNumber: latestLead.phoneNumber,
    }

    return contact
  }

  return select(getUserContact)
})
