import ozAppIncentive from '@/components/oz-app-incentive'
import { icClose, icUser } from '@vivareal/lina-icons'

import zapLogo from '@/assets/logos/brand-zap/zap.svg'

import ozHeaderUser from '../oz-header-user'
import ozDropdownMenu from '../oz-dropdown-menu'

export default {
  name: 'oz-header-submenu',

  data () {
    return {
      icons: {
        icClose,
        icUser,
      },
      zapLogo,
    }
  },

  props: {
    user: { type: Object },
    submenu: { type: Array, required: true },
    logged: { type: Boolean, required: true },
  },

  computed: {
    items () {
      return this.submenu.filter((item) => !item.logged)
    },

    loggedItems () {
      return this.logged ? this.submenu.filter((item) => !!item.logged) : []
    },
  },

  methods: {
    logout () {
      this.$store.dispatch('auth/logout')
      this.$emit('logout')
    },

    handleLogin () {
      this.$emit('login')
    },

    track (item) {
      this.$emit('track', item)
    },

    openFullscreen (fullscreen) {
      this.$emit('fullscreen', fullscreen)
    },
  },

  components: {
    ozAppIncentive,
    ozHeaderUser,
    ozDropdownMenu,
  },
}
