const postLeadModal = (state) => state.postLeadModal

const leadModal = (state) => state.leadModal

const whatsappFormModal = (state) => state.whatsappFormModal

const showModal = (state) => (
  state.leadModal.show && !state.leadModal.listing?.isDefaulterInactive
)

const visitModal = (state) => state.visitModal

const showWhatsappFormModal = (state) => state.whatsappFormModal.show

const showVisitModal = (state) => state.visitModal.show

const showInactiveLeadModal = (state) => (
  state.leadModal.show && state.leadModal.listing?.isDefaulterInactive
)

const isConciergeListing = (state, getters, rootState, rootGetters) => (
  state.postLeadModal.listing?.isAllowedConcierge || rootGetters['listing/showConciergeVisit']
)

const isVisitPostLeadType = (state) => (
  ['EMAIL', 'VISIT', 'WHATSAPP'].includes(state.postLeadModal.type)
)

const showConciergePostLeadModal = (state, getters) => (
  state.postLeadModal.show && getters.isConciergeListing && getters.isVisitPostLeadType
)

const showPostLeadModal = (state, getters) => (
  state.postLeadModal.show && (!getters.isConciergeListing || !getters.isVisitPostLeadType)
)

const showSimilarAlertModal = (state) => state.similarAlertModal.show

const showSavedSearchModal = (state) => state.savedSearchModal.show

export default {
  postLeadModal,
  leadModal,
  showModal,
  visitModal,
  showVisitModal,
  whatsappFormModal,
  showPostLeadModal,
  isConciergeListing,
  isVisitPostLeadType,
  showWhatsappFormModal,
  showInactiveLeadModal,
  showConciergePostLeadModal,
  showSimilarAlertModal,
  showSavedSearchModal,
}
