// @ts-check

/**
 * @param {import('vuex').Store | import('vuex').ActionContext} store
 * @returns {SelectorExecutor}
 */
export function useSelect (store) {
  return (selector) => {
    const state = 'rootState' in store ? store.rootState : store.state
    return selector(state)
  }
}
