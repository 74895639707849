/**
 * @typedef {'WHATSAPP' | 'EMAIL' | 'PHONE'} PostLeadType
 */

/**
 * @typedef {{
 *  type: PostLeadType
 * }} PostLeadState
 *
 * @typedef {{
 *  postLeadModal: PostLeadState
 * }} ModalsState
 */

export default () => ({
  postLeadModal: {},
  leadModal: {},
  savedSearchModal: {},
  similarAlertModal: {},
  whatsappFormModal: {},
  visitModal: {},
})
