const LEAD_INTENT = {
  ANSWERS_REQUEST: 'ANSWERS_REQUEST',
  INACTIVE_LISTING_ANSWERS_REQUEST: 'INACTIVE_LISTING_ANSWERS_REQUEST',
  APPOINTMENT_REQUEST: 'APPOINTMENT REQUEST',
  DEFAULTER_ADVERTISER: 'DEFAULTER ADVERTISER',
  SEE_PHONE: 'SEE_PHONE',
  WHATSAPP_APPOINTMENT_REQUEST: 'WHATSAPP APPOINTMENT REQUEST',
}

export default LEAD_INTENT
