// @ts-check

import memoizeLatest from '@/lib/memoize-latest'
import cookies from '@/storage/cookie-storage'
import { getKnownExperimentsVariations } from '../context/selectors'

const GA_KEY = '_gaexp'
const GA_REGEXP = /(?<experimentId>\S{22})\.(?:\d{5})\.(?<variationId>\d{1})/

/**
 * @typedef {<T extends Experiment>
 *  (experiment: T, select: SelectorExecutor) => string
 * } VariationProvider
 */

/**
 * It gets the varition from initial context provided by Comandante
 * @type {VariationProvider}
 */
export const ComandanteProvider = (experiment, select) => {
  const experiments = select(getKnownExperimentsVariations)
  return experiments[experiment.id]
}

/**
 * Used on development, it picks the experiment from the local storage
 * @type {VariationProvider}
 */
export const DevProvider = (experiment) => {
  if (__BROWSER__ && __DEV__) {
    return localStorage?.getItem(experiment.id)
  }

  return undefined
}

/** @returns {Array<{experimentId: string, variationId: string}>} */
const pickExperimentsFromOptimizeCookie = memoizeLatest(() => {
  const gaExp = cookies.get(GA_KEY)

  if (!gaExp) return []

  // This is a hack - google doesn't provide this info in more "right" way
  // We need to split "!" to tracks multiple experiments at same time
  // (its not well documented but google appends them with !)
  // @ts-ignore
  return gaExp.split('!')
    .map((v) => v.match(GA_REGEXP))
    .filter(Boolean)
    .map((g) => g.groups)
})

/** @type {VariationProvider} */
function pickVariationFromOptimizeCookie (experiment) {
  return pickExperimentsFromOptimizeCookie()
    ?.find((exp) => exp.experimentId === experiment.id)
    ?.variationId
}

/** @type {VariationProvider} */
function pickVariationFromOptimizeSDK (experiment) {
  const { google_optimize: optimize } = window
  return optimize?.get(experiment.id)
}

/** @type {VariationProvider} */
export const OptimizeProvider = (experiment) => {
  if (__BROWSER__) {
    // TODO: check if this over engineering is required
    return pickVariationFromOptimizeSDK(experiment) ?? pickVariationFromOptimizeCookie(experiment)
  }

  return undefined
}
