// @ts-check

import logger from '@/utils/logger'
import useGlue from '../core/use-glue'
import assert from '@/utils/assert'
import mediasFilter from '@/filters/medias'
import { useSelect } from '../core/use-select'
import { DEDUPLICATION, getVariationOf } from '../experimentations'

/** @typedef {import('../listing/state').Listing} Listing */
/** @typedef {import('../listing/state').Publisher} Publisher*/
/** @typedef {import('../listing/state').Image[]} Medias*/
/** @typedef {{href: string;}} Link */

/**
 * @typedef {object} Duplicate
 * @property {string} id
 * @property {Publisher} publisher
 * @property {string} advertiserId
 * @property {string} description
 * @property {string} sourceId
 * @property {string} publicationType
 * @property {string[]} stamps
 * @property {Medias} images
 * @property {import('../listing/state').PricingInfo} pricingInfo
 * @property {import('../listing/state').PricingInfo[]} pricingInfos
 * @property {Link} [link]
 * @property {number} listingsCount
 */

/**
 * @typedef {object} DuplicateFilters
 * @property {Listing} listing
 * @property {number} pageSize
 */

/**
 * @typedef {object} DuplicateContent
 * @property {number} page
 * @property {number} total
 * @property {Duplicate[]} items
 */

/**
 * @typedef {object} DuplicatesState
 * @property {boolean} loading
 * @property {DuplicateFilters | null} filters
 * @property {DuplicateContent | null} content
 * @property {Error} [error]
 */

/**
 * @param {import('@/api/utils/glue-resources').WithPage<{
 *  search: {
 *   result: {
 *     listings: import('@/api/utils/glue-resources').ListingWrapperResource[]}
 *   }
 *  }
 * >} response
 * @returns {DuplicateContent}
 */
function decodeDeduplicationResponse (response) {
  const { listings } = response.search.result

  const items = listings.map(({
    account, link, listing, medias,
  }) => {
    const { sourceId } = listing

    assert(sourceId, {
      error: () => `Listing ${listing.id} has no sourceId`,
    })

    const prices = /** @type {import('../listing/state').PricingInfo[]} */ (
      listing.pricingInfos
    )

    const pricingInfo = /** @type {import('../listing/state').PricingInfo} */ (
      listing.pricingInfo ?? prices?.[0]
    )

    const publisher = /** @type {Publisher} */ {
      ...account,
      url: '',
      relatedLinksCount: 0,
    }

    return ({
      ...listing,
      images: mediasFilter.images(medias),
      sourceId,
      advertiserId: listing.advertiserId ?? account.id,
      link,
      pricingInfo,
      pricingInfos: prices,
      publisher,
    })
  })

  return {
    page: response.page.uriPagination.page,
    total: response.page.uriPagination.total,
    items,
  }
}

/**
 * @param {DuplicatesState} state
 * @returns {boolean}
 */
export function isEntirelyLoaded ({ content }) {
  return !!content && content.total <= content.items.length
}

/**
 * @param {DuplicatesState} state
 * @returns {boolean}
 */
export function canFetchNextPage (state) {
  return !state.loading && !isEntirelyLoaded(state)
}

let syncronizer = 0

/**
 * @template T
 */
class SyncronizationError {
  constructor (/** @type {T} */response) {
    /** @type {T} */
    this.response = response
  }
}

/**
 * It prevents that previous requests interfere with the current one
 *
 * @template T
 * @param {(this: void) => Promise<T>} handler
 * @returns {Promise<T>}
 */
async function syncronize (handler) {
  // eslint-disable-next-line no-plusplus
  const executionIndex = ++syncronizer

  const response = await handler()

  if (executionIndex !== syncronizer) {
    throw new SyncronizationError(response)
  }

  return response
}

/**
 * @param {import('vuex').ActionContext<DuplicatesState, any>} context
 * @returns {(
 *  filters: DuplicateFilters & { page?: number, from?: number }
 * ) => Promise<DuplicateContent>}
 */
function useFetchDuplicates (context) {
  const select = useSelect(context)
  const glue = useGlue(context)

  return async function ({
    listing, pageSize, page, from,
  }) {
    const { sourceId } = listing

    assert(sourceId)

    const [, { glueVariant: __zt }] = select(getVariationOf(DEDUPLICATION))

    const response = await glue.get(`/v2/listings/${sourceId}`, {
      params: {
        images: 'webp' ?? '',
        categoryPage: 'RESULT',
        sourceId,
        size: pageSize,
        ...(page && { page }),
        ...(from && { from }),
        ...(__zt && { __zt }),
      },
      fields: {
        search: {
          result: {
            listings: {
              account: {
                id: true,
                logoUrl: true,
                name: true,
                tier: true,
                config: true,
              },
              listing: {
                id: true,
                externalId: true,
                advertiserContact: true,
                publicationType: true,
                pricingInfos: true,
                pricingInfo: true,
                unitTypes: true,
                sourceId: true,
                listingsCount: true,
                stamps: true,
                description: true,
                advertiserId: true,
                showPrice: true,
                contractType: true,
              },
              medias: true,
              link: true,
            },
          },
        },
        page: true,
      },
    })

    return decodeDeduplicationResponse(response.data)
  }
}

/**
 * @typedef {{content: DuplicateContent;filters: DuplicateFilters;}} OnSuccessPayload
 */

/** @type {import('vuex').Module<DuplicatesState, any>} */
export default {
  namespaced: true,

  state: () => ({
    content: null,
    filters: null,
    loading: false,
  }),

  mutations: {

    onLoadingStarted (state) {
      state.loading = true
    },

    /** @param {{ error: Error }} payload */
    onLoadingFailed (state, payload) {
      state.error = payload.error
      state.loading = false
    },

    /** @param {OnSuccessPayload} payload */
    onLoadingSuccess (state, payload) {
      assert(payload.content)
      assert(payload.filters)

      state.content = payload.content
      state.filters = payload.filters
      state.loading = false
    },
  },

  actions: {

    sync: async (
      context,
      /** @type { DuplicateFilters } */ { listing, pageSize },
    ) => {
      assert(listing, { error: 'Listing is required' })

      const filters = { listing, pageSize }

      if (
        context.state.filters?.listing.sourceId === filters.listing.sourceId
        && (context.state.content?.items.length ?? 0) >= filters.pageSize
      ) {
        /** @type {OnSuccessPayload} */
        const payload = {
          content: /** @type {DuplicateContent} */ (context.state.content),
          filters,
        }
        // duplicates are already loaded, it prevents to load them unnecessarily
        context.commit('onLoadingSuccess', payload)
        return
      }

      const fetch = useFetchDuplicates(context)

      try {
        context.commit('onLoadingStarted')

        const content = await syncronize(() => fetch(filters))

        /** @type {OnSuccessPayload} */
        const payload = {
          content,
          filters: { listing, pageSize },
        }

        context.commit('onLoadingSuccess', payload)
      } catch (err) {
        if (err instanceof SyncronizationError) {
          // do nothing, it's just the request that has been canceled by syncronization
        } else {
          logger.error(err)
          context.commit('onLoadingFailed', { error: err })
          throw err
        }
      }
    },

    async fetchNextPage (context) {
      const { content, filters } = context.state

      assert(content, { error: 'The first page must be loaded' })
      assert(filters)

      if (!canFetchNextPage(context.state)) {
        // do nothing, it's completely loaded or loading
        return
      }

      const fetch = useFetchDuplicates(context)

      try {
        context.commit('onLoadingStarted')

        const loaded = await fetch({
          ...filters,
          from: content.items.length ?? 0,
          page: content.page + 1,
        })

        /** @type {OnSuccessPayload} */
        const payload = {
          content: {
            ...loaded,
            items: [...content.items, ...loaded.items],
          },
          filters,
        }

        context.commit('onLoadingSuccess', payload)
      } catch (err) {
        logger.error(err)
        context.commit('onLoadingFailed', { error: err })
        throw err
      }
    },
  },
}

