const isLogged = (state) => Boolean(state.token)

/**
 * @param {import("./state").AuthState} state
 * @returns {string}
 */
function anonymousId (state) {
  return state.anonymousId
}

const user = (state) => {
  if (state.user) {
    const {
      nome = '',
      sobrenome = '',
      email = '',
      dddCelular = '',
      celular = '',
      termsOptIns = '',
      picture = '',
    } = state.user

    return {
      name: [nome, sobrenome].filter(Boolean).join(' '),
      email,
      termsOptIns,
      phone: [dddCelular, celular].filter(Boolean).join(''),
      picture,
    }
  }

  return {}
}

export default {
  anonymousId,
  user,
  isLogged,
}
