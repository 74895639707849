import { getByPath } from '@/api/page'
import transformWidgets from '@/utils/transformers/widgets'
import condominiumInfoTransformer from '@/utils/transformers/page/page'
import { fieldsTransform, listingsResultTransformer } from '@/utils/transformers/results'
import localStorage from '@/storage/local-storage'
import { PAGE_SIZE_DEFAULT } from '@/utils/constants/results'
import basePageView from '@/utils/tracking/results/base-page-view'
import handlePagination from '@/utils/pagination'
import { pageFilters, getRecommendations } from './util'

async function getPageContent (
  store,
  { currentPath, routeQuery },
) {
  try {
    const { rootGetters, rootState, commit } = store

    const { glueEndpoint } = rootGetters
    const { domain } = rootState.context
    const hideSummary = true

    const params = {
      ...routeQuery,
      domain,
      hideSummary,
      images: 'webp',
    }

    const response = await getByPath(glueEndpoint, currentPath, params, routeQuery)
    const {
      widgets,
      metaContent,
      uriCategory,
      ...pageContent
    } = response.data ? response.data.page : {}

    commit('setUriCategory', uriCategory)
    commit('setPageContent', pageContent)
    commit('setWidgets', transformWidgets(widgets))
    commit('setMetaContent', metaContent)
  } catch (e) {
    const {
      code,
      location,
    } = e

    if (location) {
      throw e
    }

    store.dispatch('errors/setHttpStatus', code, { root: true })
  }
}

const setPageNumber = async (store, page) => {
  store.commit('setPage', page)
}

// eslint-disable-next-line complexity
const searchCondominium = async (store, {
  currentPath,
  translatedQuery = {},
  routeQuery,
  setContent = false,
}) => {
  store.commit('setLoading', true)
  try {
    const { glueEndpoint } = store.rootGetters
    const { domain, glossary } = store.rootState.context
    const { filters } = store.rootState.filters
    const variations = store.rootGetters['ab/getAllVariationNames']
    const { pageSize: size = PAGE_SIZE_DEFAULT, currentPage } = store.state.pagination
    const defaultBusiness = !translatedQuery?.business ? { business: 'SALE' } : {}
    const { sort } = filters

    const fields = {
      ...defaultBusiness,
      ...translatedQuery,
      sort,
      page: currentPage,
    }

    const rawRouteQuery = fieldsTransform(
      {
        store,
        fields,
        isCondominiumPage: true,
      },
    )
    const from = size * (+currentPage - 1)

    const response = await getByPath(
      glueEndpoint,
      currentPath,
      {
        ...rawRouteQuery,
        from,
        size,
        page: currentPage,
        variations,
        domain,
        images: 'webp',
      },
      routeQuery,
    )

    if (!response?.data) {
      return
    }

    const { data } = response
    const totalCount = data.page.uriPagination.total
    const uriPagination = data?.page?.uriPagination || {}
    const totalListing = totalCount || 0
    const totalCounts = { search: data?.listings?.totalCount || 0 }
    const paginationParams = handlePagination(
      {
        ...uriPagination,
        totalCount,
      },
      totalListing,
      totalCounts,
    )

    const filtersPage = {
      ...defaultBusiness,
      ...translatedQuery,
      sort,
      ...pageFilters(paginationParams?.pageSize, data?.page),
    }

    store.commit('page/setPath', currentPath, { root: true })
    store.commit('filters/setFilters', filtersPage, { root: true })

    const businessTypeContext = localStorage.getBusinessTypeContext()
    const { recommendations } = data

    let regularListings = []

    if (totalListing) {
      regularListings = listingsResultTransformer({
        listings: data.listings.result.listings,
        businessTypeContext,
        glossary,
      })
    }

    const condominiumRecommendations = getRecommendations(recommendations?.recommendations?.[0])

    store.commit('setPagination', paginationParams)
    store.commit('setRecommendations', condominiumRecommendations)
    store.commit('setCondominiumListing', regularListings)

    if (setContent) {
      const { page } = data

      const condominiumInfo = condominiumInfoTransformer(data, glossary)
      store.commit('setMetaContent', page.metaContent)
      store.commit('setCondominiumContent', condominiumInfo)

      const trackingData = basePageView({
        filters: filtersPage,
        pagination: paginationParams,
        listings: regularListings,
      })
      store.commit('page/setPageViewTrackingData', trackingData, { root: true })
    }
  } catch (e) {
    const {
      code,
      location,
    } = e

    if (location) {
      throw e
    }
    store.dispatch('errors/setHttpStatus', code, { root: true })
  } finally {
    store.commit('setLoading', false)
  }
}

export default {
  getPageContent,
  setPageNumber,
  searchCondominium,
}
