import Vue from 'vue'
import hasNotRenderedBanner from '@/utils/tracking/has-not-rendered-banner'
import mountEventData from './mount-event-data'

const track = (data) => {
  Promise.resolve(window.google_optimize).then(() => {
    Vue.$clickstream.trackEvent(data)
  }).catch(() => {
    Vue.$clickstream.trackEvent(data)
  })
}

// TODO Make this file the index of this module
// when all routes uses this pageView method
const trackPageView = (store, eventData) => {
  const data = mountEventData(store, eventData)
  track(data)
}

// TODO This can be moved elsewhere later
// when all pages gets their tracking flow refactored
// this is different from a rankingRendered,
// or homeRendered event cause is needed only
// to trigger some marketing tags on page render
const trackPageRendered = () => {
  track({ event: 'pageRendered' })
}

const trackAdsRefreshed = (ids) => {
  if (hasNotRenderedBanner(ids)) {
    setTimeout(() => {
      track({ event: 'adsRefreshed' })
    }, 1000)
  }
}

/**
 * @typedef {'imobiliaria' | 'corretor' | 'incorporadora' | 'smb' | 'ka'} SellerProType
 */

/**
 * @param {import('@/store/listing/state').ContractType} contractType
 * @returns {SellerProType | null}
 */
export function encodeProType (contractType) {
  switch (contractType) {
    case 'PROPERTY_DEVELOPER': return 'incorporadora'
    case 'REAL_ESTATE': return 'imobiliaria'
    case 'PROPERTY_DEVELOPER_SERVICE': return 'corretor'

    case 'RecordType_NONE':
    case 'OWNER':
    default:
      return null
  }
}

/**
 * @typedef {'privado' | 'profissional'} SellerCategory
 */

/**
 * @param {import('@/store/listing/state').ContractType} contractType
 * @returns {SellerCategory | null}
 */
export function encodeSellerCategory (contractType) {
  switch (contractType) {
    case 'PROPERTY_DEVELOPER':
    case 'REAL_ESTATE':
    case 'PROPERTY_DEVELOPER_SERVICE':
      return 'profissional'

    case 'OWNER':
      return 'privado'

    case 'RecordType_NONE':
    default:
      return null
  }
}

export default {
  trackPageView,
  trackPageRendered,
  trackAdsRefreshed,
  track,
}
