const setAttendanceQualityInfo = (state, attendanceQualityInfo) => {
  state.attendanceQualityInfo = attendanceQualityInfo
}

const setIsLoading = (state, isLoading) => {
  state.isLoading = isLoading
}

export default {
  setAttendanceQualityInfo,
  setIsLoading,
}
