const LEAD_CLICKED = {
  // RP
  LISTING_CARD: 'RESULT PAGE LISTING CARD',
  MAP_LISTING_CARD: 'MAP LISTING CARD',
  LISTING_CARD_ONE_CLICK_TO_LEAD: 'RESULT PAGE LISTING CARD ONE CLICK TO LEAD',
  DEFAULTER_ADVERTISER_FORM: 'DEFAULTER ADVERTISER FORM',
  // LDP
  TOP_ACTIONS: 'TOP LEAD ACTIONS',
  FLOAT_ACTIONS: 'FLOAT LEAD ACTIONS',
  AMENITIES: 'AMENITIES',
  MAP: 'MAP',
  ENDING: 'ENDING',
  VIRTUAL_TOUR: 'VIRTUAL TOUR',
  GALLERY: 'GALLERY',
  DESCRIPTION: 'DESCRIPTION ONE CLICK',
  // MOBILE OPEN LEAD FORM LDP
  VISIBLE_FORM: 'VISIBLE FORM ONE CLICK TO LEAD',
  // LDP WHATSAPP WITH LGPD CONSENT ONLY
  TOP_ACTIONS_ONE_CLICK_TO_LEAD: 'TOP LEAD ACTIONS ONE CLICK TO LEAD',
  FLOAT_ACTIONS_ONE_CLICK_TO_LEAD: 'FLOAT LEAD ACTIONS ONE CLICK TO LEAD',
  AMENITIES_ONE_CLICK_TO_LEAD: 'AMENITIES ONE CLICK TO LEAD',
  MAP_ONE_CLICK_TO_LEAD: 'MAP ONE CLICK TO LEAD',
  ENDING_ONE_CLICK_TO_LEAD: 'ENDING ONE CLICK TO LEAD',
  // RECOMMENDATION LISTING
  POST_LEAD: 'POST LEAD',
}

// LDP ONLY
const LEAD_PANEL_CLICKED = {
  TOP_ACTIONS: 'TOP LEAD ACTIONS',
  FLOAT_ACTIONS: 'FLOAT LEAD ACTIONS',
  AMENITIES: 'AMENITIES',
  MAP: 'MAP',
  ENDING: 'ENDING',
  // WHATSAPP WITH LGPD CONSENT ONLY
  TOP_ACTIONS_ONE_CLICK_TO_LEAD: 'TOP LEAD ACTIONS ONE CLICK TO LEAD',
  FLOAT_ACTIONS_ONE_CLICK_TO_LEAD: 'FLOAT LEAD ACTIONS ONE CLICK TO LEAD',
  AMENITIES_ONE_CLICK_TO_LEAD: 'AMENITIES ONE CLICK TO LEAD',
  MAP_ONE_CLICK_TO_LEAD: 'MAP ONE CLICK TO LEAD',
  ENDING_ONE_CLICK_TO_LEAD: 'ENDING ONE CLICK TO LEAD',
}

export default {
  LEAD_CLICKED,
  LEAD_PANEL_CLICKED,
}
