// @ts-check

import get from 'lodash/get'
import defineSelector from './define-selector'

/**
 * @template T
 * @template {object} [RootState = any]
 * @template {keyof RootState} [K = keyof RootState]
 * @param {K} namespace
 * @param {SelectionFn<RootState[K], T>} selectionFn
 * @returns {Selector<T, RootState>}
 */
export default function defineNamespacedSelector (namespace, selectionFn) {
  return defineSelector((rootState, select) => {
    const state = get(rootState, /** @type {string} */ (namespace)) ?? {}
    return selectionFn(state, select)
  })
}
