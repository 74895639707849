import Vue from 'vue'

const finCalcPotencialPageView = () => {
  Vue.$clickstream.trackEvent({ event: 'finCalcPotencialPageView' })
}

const finCalcPotencialInfoItbi = () => {
  Vue.$clickstream.trackEvent({ event: 'finCalcPotencialInfoItbi' })
}

const finCalcPotencialInfos = () => {
  Vue.$clickstream.trackEvent({ event: 'finCalcPotencialInfos' })
}

const finCalcPotencialSucesso = () => {
  Vue.$clickstream.trackEvent({ event: 'finCalcPotencialSucesso' })
}

const finCalcBannerClick = () => {
  Vue.$clickstream.trackEvent({ event: 'finCalcBannerClick' })
}

export {
  finCalcPotencialPageView,
  finCalcPotencialInfoItbi,
  finCalcPotencialInfos,
  finCalcPotencialSucesso,
  finCalcBannerClick,
}
