export default () => ({
  widgets: {},
  listingLinks: [],
  advertiserLinks: [],
  metaContent: {},
  uriFragments: {},
  uriCategory: {},
  uriPagination: {},
  link: {}, //TODO: remove if not using
  condominium: {},
  redirectRule: {},
  pageViewTrackingData: {},
  path: '',
  publisher: {},
  pagination: {},
  loading: false,
})
