import { AxiosError } from 'axios'
import axiosInstance from './instance'
import { handleRequestException } from './handlers'

const SAFE_HTTP_METHODS = ['get', 'head', 'options']
const IDEMPOTENT_HTTP_METHODS = ['put', 'delete'].concat(SAFE_HTTP_METHODS)

const RETRYABLE_CODES = ['ETIMEDOUT', 'ESOCKETTIMEDOUT', 'ECONNRESET', 'ENOTFOUND',
  'ECONNREFUSED', 'EHOSTUNREACH', 'EPIPE', 'EAI_AGAIN']

// eslint-disable-next-line no-console
const logError = console.error.bind(console)

function isExRetryable (ex) {
  return ex.code && RETRYABLE_CODES.indexOf(ex.code) > -1
    && IDEMPOTENT_HTTP_METHODS.indexOf(ex.config.method) > -1
}

function isResRetryable (res) {
  return res.status >= 500 && IDEMPOTENT_HTTP_METHODS.indexOf(res.config.method) > -1
}

function logRequestError (err) {
  if (err instanceof AxiosError) {
    const { request, config: req, ...response } = err?.response ?? err
    logError({
      request: {
        url: req.url,
        method: req.method,
        body: req.data,
        params: req.params,
        headers: req.headers,
      },
      response,
    })
  } else {
    logError(err)
  }
}
function logDebugMode () {
  const isProd = process.env.NODE_ENV === 'production'
  return !__BROWSER__ && !isProd
}

async function handleRetry (func, args, retrying = false) {
  try {
    const response = await func(...args)

    if (isResRetryable(response) && !retrying) {
      return handleRetry(func, args, true)
    }

    return response
  } catch (ex) {
    if (isExRetryable(ex) && !retrying) {
      return handleRetry(func, args, true)
    }

    if (logDebugMode()) {
      logRequestError(ex)
    }

    return handleRequestException(ex)
  }
}

export default {
  get: async (...args) => handleRetry(axiosInstance.get, args),

  post: async (...args) => handleRetry(axiosInstance.post, args),

  delete: async (...args) => handleRetry(axiosInstance.delete, args),

  put: async (...args) => handleRetry(axiosInstance.put, args),
}
