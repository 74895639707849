import { getOfficialStoreData } from '@/api/official-store'

const gettingOfficialStoreData = async (store, id) => {
  try {
    const { data } = await getOfficialStoreData(store, id)
    await store.commit('setStoreData', data?.site || null)
  } catch (e) {
    await store.commit('setStoreData', null)
  }
}

export default {
  gettingOfficialStoreData,
}
