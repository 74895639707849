import { HOME_PAGE_QUERY, FALLBACK_DATA } from '@/utils/constants'

import { getCMSContent } from '../content-manager'
import summary from './summary'

const isNotProd = process.env.NODE_ENV !== 'production'

export default async function getHomePageContent (endpoint, { domain }, queryParams) {
  const preview = queryParams.cmsPreview || isNotProd
  const skipCache = queryParams.skipCache || isNotProd
  const state = preview ? 'PREVIEW' : 'LIVE'
  const sort = preview ? 'publishedAt:desc' : 'order'

  const query = `
    query (
      $state: PublicationState
    ) {
      ${HOME_PAGE_QUERY({ sort })}
    }
  `

  try {
    const { data: cmsContent } = await getCMSContent(endpoint, {
      domain,
      state,
      skipCache,
      graphql: query,
    })

    return summary(cmsContent.data)
      .header()
      .showcase()
      .banner()
      .blog()
      .result()
  } catch (error) {
    const { location } = error

    if (location) throw error

    return FALLBACK_DATA.FALLBACK_HOME_PAGE_DATA
  }
}
