import { getByPath } from '@/api/page'
import transformAdvertisersLinks from '@/utils/transformers/advertisers-links'
import basePageView from '@/utils/tracking/advertisers/base-page-view'

const ITEMS_PER_PAGE = 36
const WIDGET_CATEGORY = 'ADVERTISERS_AVAILABLE'

const getLinks = (widgetLinks) => {
  if (widgetLinks.length <= 0) return []

  const links = []
  const transformedLinks = transformAdvertisersLinks(widgetLinks, WIDGET_CATEGORY)

  for (let i = 0; i < transformedLinks.length; i += ITEMS_PER_PAGE) {
    links.push(transformedLinks.slice(i, i + ITEMS_PER_PAGE))
  }

  return links
}

const getFilters = (address) => ({
  addresses: [address],
})

const getPagination = (page, widgetLinks, links) => {
  const results = links[Number(page) - 1]

  const pagination = {
    advertisersCount: widgetLinks.length,
    currentPage: Number(page),
    pagesCount: links.length,
    pageResults: results ? results.length : 0,
  }

  return pagination
}

const getAdvertisers = async (store, { currentPath, translatedQuery, routeQuery }) => {
  try {
    if (store.state.links.length) return

    const { glueEndpoint } = store.rootGetters
    const { domain } = store.rootState.context
    const variations = store.rootGetters['ab/getAllVariationNames']

    const response = await getByPath(
      glueEndpoint,
      currentPath,
      {
        ...translatedQuery, variations, domain, images: 'webp',
      },
      routeQuery,
    ) || {}

    if (!response.data) return

    const { data: { page: { uriFragments: { address }, metaContent, widgets } } } = response

    const { page = 1 } = translatedQuery
    const { links: widgetLinks = [] } = widgets
      .find((item) => item.category === WIDGET_CATEGORY) || {}

    const links = getLinks(widgetLinks)
    const filters = getFilters(address)
    const pagination = getPagination(page, widgetLinks, links)
    const pageViewTrackingData = basePageView({ links, filters, pagination })

    store.commit('advertisers/setLinks', links, { root: true })
    store.commit('advertisers/setFilters', filters, { root: true })
    store.commit('advertisers/setPagination', pagination, { root: true })
    store.commit('page/setMetaContent', metaContent, { root: true })
    store.commit('page/setPageViewTrackingData', pageViewTrackingData, { root: true })
  } catch (e) {
    const { code, location } = e

    if (location) {
      throw e
    }

    store.dispatch('errors/setHttpStatus', code, { root: true })
  }
}

const paginate = async (store, page) => {
  store.commit('advertisers/paginate', page, { root: true })
}

export default {
  getAdvertisers,
  paginate,
}
