import apiClient from '@/api/client'
import createGluePayload from '@/api/utils/glue'

export async function getCMSContent (
  endpoint,
  {
    domain,
    state,
    skipCache,
    graphql,
  },
) {
  const payload = createGluePayload({
    domain,
    variables: {
      state,
    },
    skipCache,
    graphql,
  })

  return apiClient.get(`${endpoint}/v2/content-manager`, payload)
}
