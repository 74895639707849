// @ts-check

import defineSelector from '@/store/core/define-selector'
import { experiments } from './define-experiment'
import {
  ComandanteProvider,
  DevProvider,
  OptimizeProvider,
} from './variation-provider'
import { getKnownExperimentsVariations } from '../context/selectors'

/** @typedef {import('./variation-provider').VariationProvider} VariationProvider */

/** @type {VariationProvider[]} */
const defaultProviders = [
  DevProvider,
  ComandanteProvider,
  OptimizeProvider,
]

class VariationNotFound {}

/**
 * @template {Experiment<string, any>} E
 * @param {E} experiment
 * @param {{
 *  providers?: VariationProvider[],
 *  throwIfNotFound?: boolean,
 * }} [options]
 * @returns {Selector<VariationOf<E>>}
 */
export function getVariationOf (experiment, options = {}) {
  const {
    providers = defaultProviders,
    throwIfNotFound = false,
  } = options

  return defineSelector((_state, select) => {
    const { length } = providers

    for (let i = 0; i < length; i += 1) {
      let found

      try {
        found = providers[i](experiment, select)
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err)
      }

      const variation = found && experiment.pickVariation(found)
      if (variation) {
        return variation
      }
    }

    if (throwIfNotFound) {
      throw new VariationNotFound()
    }

    return experiment.defaultVariation
  })
}

/**
 * @param {{
 *  providers?: VariationProvider[],
 *  includeNotFound?: boolean
 * }} [options]
 * @returns {Selector<Map<Experiment, Variation>>}
 */
export function getEveryVariation (options = {}) {
  const getVariationOptions = {
    ...options,
    throwIfNotFound: !options.includeNotFound,
  }

  return defineSelector((state, select) => {
    /** @type {Map<Experiment, Variation>} */
    const map = new Map()

    experiments
      .forEach((e) => {
        try {
          const v = select(getVariationOf(e, getVariationOptions))
          map.set(e, v)
        } catch (err) {
          if (!(err instanceof VariationNotFound)) {
            throw err
          }
        }
      })

    return map
  })
}

/**
 * @param {import('vuex').Store<any>} store
 */
export const getTrackingParams = defineSelector((state, select) => {
  const found = select(getKnownExperimentsVariations)
  const entries = Object.entries(found)

  return {
    // It's used to track abelardo experiments
    ab_tests: entries
      .map(([e, v]) => [e.replace('_', ''), '_', v].join(''))
      .join('.'),

    // It's been used on previous tracking integrations.
    // The values on the same index must be from the same experiment.
    experimentId: entries.map(([e]) => e).join(','),
    variationName: entries.map(([, v]) => v).join(','),
  }
})
