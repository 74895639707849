// @ts-check

import apiClient from '@/api/client'
import { createBodyUserInterestPayload } from './utils/user-interest'

const DEFAULT_ORIGIN = 'zap'

/**
 * @typedef {{
 *   ddd: number;
 *   number: number;
 * }} Phone
 */

/**
 * @typedef {{accepted: boolean;}} LeadReuseTermsOptIn
 */

/**
 * @typedef {object} PrivacyTermsOptIn
 * @property {boolean} accepted
 * @property {string} version
 */

/**
 * @typedef {object} LeadPayloadBase
 * @property {string} origin
 * @property {string} originType
 * @property {string} transactionType
 * @property {string} leadType
 * @property {LeadReuseTermsOptIn} leadReuseTermsOptIn
 * @property {PrivacyTermsOptIn} privacyTermsOptIn
 * @property {string} [name]
 * @property {string} [email]
 * @property {string} [message]
 * @property {Phone} [phone]
 */

/**
 * @typedef {LeadPayloadBase & { listingIdList: string[] }} LeadBatchPayload
 */

/**
 * @typedef {Object} BatchOptions
 * @property {string} endpoint
 * @property {string} fingerprint
 */

export default {
  /**
   *
   * @param {string} endpoint
   * @param {any} param1
   * @returns
   */
  send: (endpoint, { params: { fingerprint }, ...data }) => {
    const url = `${endpoint}/v3/leads`
    return apiClient.post(url, JSON.stringify(data), {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        fingerprint,
      },
    }).then((response) => ({ ...response.data, ...response.headers }))
  },

  /**
   * @param {LeadBatchPayload} payload
   * @param {BatchOptions} options
   */
  async batch (payload, { endpoint, fingerprint }) {
    const url = (new URL('/v3/leads/batch', endpoint)).href

    const response = await apiClient.post(url, payload, {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        fingerprint,
      },
    })

    return response.data
  },

  /**
   *
   * @param {string} endpoint
   * @param {{
   *  xDeviceId: string;
   *  lead: LeadPayloadBase;
   *  id: string;
   *  isDevelopment: boolean;
   * }} options
   * @returns
   */
  sendSimilar: (endpoint, {
    xDeviceId, lead, id, isDevelopment,
  }) => {
    const url = `${endpoint}/recommendation`

    const { data, ...config } = createBodyUserInterestPayload({
      ...lead,
      xDeviceId,
      listing: {
        id,
        category: isDevelopment ? 'DEVELOPMENT' : 'PROPERTY',
      },
      fingerPrint: xDeviceId,
      origin: DEFAULT_ORIGIN,
    })

    return apiClient.post(url, data, config)
  },

}
