// @ts-check

import Vue from 'vue'
import 'intersection-observer'
import 'array-flat-polyfill'

import VueMeta from 'vue-meta'

import LinaDs from '@vivareal/lina/dist/zap/system'
import LinaVue from '@vivareal/lina-vue/dist/zap/lina-vue.common'

import '@vivareal/lina/dist/zap/system.css'
import '@vivareal/lina-vue/dist/zap/lina-vue.css'

import VueObserveVisibility from 'vue-observe-visibility'

import VueClickstream from '@/plugins/vue-clickstream'
import VueSentry from '@/plugins/vue-sentry'

Vue.use(VueObserveVisibility)
Vue.use(LinaDs)
Vue.use(LinaVue)

Vue.use(VueMeta, {
  keyName: 'meta',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
})

export default {
  initialize (store) {
    const {
      context: {
        config = {},
        featureToggle = {},
        variationtest = {},
      },
    } = store.state ?? {}

    Vue.use(VueSentry, {
      dsn: config.ozassetsFrontendSentryDsn,
      featureToggleOn: featureToggle.ozassets_frontend_sentry,
      variationtest,
    })

    Vue.use(VueClickstream, {
      trackerEndpoint: config.clickstreamTrackerEndpoint,
      debug: __DEV__ && __TRACKING_DEBUG__,
      store,
    })
  },
}

