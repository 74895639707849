import apiClient from './client'
import apiBase from './base'

export const getLeadPartner = async (store, {
  brand,
  listing: {
    type,
    price,
    address: {
      city,
      state,
    },
  },
}) => {
  const url = `${apiBase.getGlueUrl(store)}/v2/re-lead-control-panel/leadPartner?brand=${brand}&type=${type}&addressCity=${city}&addressState=${state}&price=${price}`
  return apiClient.get(url)
}
