const updatePartnerObject = (state, payload) => {
  const {
    url, partner, avaliablePartner, iframeUrl,
  } = payload

  state.url = url
  state.partner = partner
  state.avaliablePartner = avaliablePartner
  state.iframeUrl = iframeUrl
}

export default {
  updatePartnerObject,
}
