import { sync } from 'vuex-router-sync'
import Vue from 'vue'

import App from '@/app'

import plugins from '@/plugins'
import { createRouter, globalHooks } from './router'
import createStore from './store'

Vue.config.productionTip = __DEV__
Vue.config.performance = __DEV__
Vue.config.env = process.env

// eslint-disable-next-line import/no-mutable-exports
let store

const createApp = async ({
  beforeApp = () => {},
  afterApp = () => {},
  context = {},
  device,
} = {}) => {
  store = createStore({ device, context })
  const router = createRouter(context)

  await beforeApp({
    router,
    store,
  })

  // sync the router with the vuex store.
  // this registers `store.state.route`
  sync(store, router)
  plugins.initialize(store)

  globalHooks.beforeEachConfig(router, store)
  globalHooks.afterEachConfig(router, store)

  const app = new Vue({
    router,
    store,
    render: (h) => h(App),
  })

  const result = {
    app,
    router,
    store,
  }

  await afterApp(result)

  return result
}

export default createApp
export { store }
