import picker from '@/utils/picker'
import { addressTransform, normalizedAddressTransform } from '@/utils/tracking/address'
import { AMENITIES } from '@/utils/constants'

const sortMap = {
  DEFAULT: 'DEFAULT',
  DATE: 'UPDATE DATE',
  AREA: 'AREA',
  PRICE: 'PRICE',
}

const filterNone = (val) => !/_NONE/i.test(val)
const toCleanArray = (val) => (Array.isArray(val) ? val : [val]).filter(Boolean)
const toArrayClearNones = (val) => toCleanArray(val).filter(filterNone).filter(Boolean)
const toNumber = (val) => Number(val || '') || 0
const toString = (val) => String(val || '')
const getArray = (val) => [].concat(val).filter(Boolean)
const toArrayNumberValues = (val) => (val ? val.split(',').map((str) => toNumber(str)) : [])

const filtersMap = {
  sort: (sortVal = 'DEFAULT') => sortMap[sortVal.toUpperCase()],
  businessType: 'business',
  listingTypes: {
    key: 'listingType',
    transform: toCleanArray,
  },
  unitTypes: {
    key: 'unitTypes',
    transform: (value) => {
      const values = toArrayClearNones(value)
      const { unitTypesV3 } = values[0] || {}
      const unitTypeList = unitTypesV3?.split(',')
      const unitTypesStringsList = values.length !== 0
        ? unitTypeList
        : values

      return unitTypesStringsList
    },
  },
  addresses: {
    key: 'addresses',
    transform: (addresses) => getArray(addresses)
      .map((address) => addressTransform(address))
      .filter((item) => Object.keys(item).length !== 0),
  },
  advertiserId: {
    key: 'advertiserId',
    transform: toString,
  },
  constructionStatuses: {
    key: 'constructionStatus',
    transform: (constructionStatus) => toArrayClearNones(constructionStatus?.split(',')),
  },
  fromPrice: {
    key: 'priceMin',
    transform: toNumber,
  },
  toPrice: {
    key: 'priceMax',
    transform: toNumber,
  },
  priceMinCondo: {
    key: 'priceMinCondo',
    transform: toNumber,
  },
  priceMaxCondo: {
    key: 'priceMaxCondo',
    transform: toNumber,
  },
  rentalTotalPriceMax: {
    key: 'rentalTotalPriceMax',
    transform: toNumber,
  },
  rentalTotalPriceMin: {
    key: 'rentalTotalPriceMin',
    transform: toNumber,
  },
  fromArea: {
    key: 'usableAreasMin',
    transform: toNumber,
  },
  toArea: {
    key: 'usableAreasMax',
    transform: toNumber,
  },
  fromTotalArea: {
    key: 'totalAreasMin',
    transform: toNumber,
  },
  toTotalArea: {
    key: 'totalAreasMax',
    transform: toNumber,
  },
  parkingSpaces: {
    key: 'parkingSpaces',
    transform: toArrayNumberValues,
  },
  bedrooms: {
    key: 'bedrooms',
    transform: toArrayNumberValues,
  },
  bathrooms: {
    key: 'bathrooms',
    transform: toArrayNumberValues,
  },
  suites: {
    key: 'suites',
    transform: toArrayClearNones,
  },
  rentalPeriod: {
    key: 'rentalPeriod',
    transform: toArrayClearNones,
  },
  amenities: {
    key: 'amenities',
    transform: (amenities = []) => {
      const amenitiesTexts = AMENITIES.reduce((texts, { id, text }) => {
        if (amenities.includes(id)) {
          texts = [...texts, text]
        }
        return texts
      }, [])

      return amenitiesTexts
    },
  },
  stamps: {
    key: 'stamps',
    transform: (stamps) => (stamps ? toArrayClearNones(stamps) : undefined),
  },
  searchTerms: {
    key: 'q',
    transform: (terms = '') => {
      const termsStringsList = terms
        ? terms.split(',').map((str) => str.trim())
        : []

      return termsStringsList
    },
  },
  filterCategories: {
    key: 'addresses',
    transform: (addresses) => getArray(addresses).reduce((categories, address) => {
      const category = ['street', 'neighborhood', 'city', 'state'].find((key) => !!address[key])
      if (category && !categories.includes(category)) categories.push(category)
      return categories
    }, []),
  },
  normalizedAddresses: {
    key: 'addresses',
    transform: (addresses) => getArray(addresses)
      .map((address) => normalizedAddressTransform(address)),
  },
  viewport: true,
  warranties: {
    key: 'warranties',
    transform: (warranties) => (warranties ? toArrayClearNones(warranties) : undefined),
  },
  displayAddressType: 'displayAddressType',
  nearBy: {
    key: 'transportNearBy',
    transform: (val) => (val ? [{ field: 'TRANSPORT', distance: 2 }] : []),
  },
}

const paginationMap = {
  pageResults: {
    key: 'pageSize',
    transform: toNumber,
  },
  currentPage: {
    key: 'currentPage',
    transform: toNumber,
  },
  listingsCount: {
    key: 'listingsSize',
    transform: toNumber,
  },
  pagesCount: {
    key: 'pageCount',
    transform: toNumber,
  },
}

export default {
  filters: (filters = {}) => picker(filtersMap, filters),
  pagination: (pagination) => picker(paginationMap, pagination),
}
