const setLead = (state, lead) => {
  Object.assign(state, { lead })
}

const setRecommendations = (state, recommendations) => {
  Object.assign(state, { recommendations })
}

const clearRecommendations = (state) => {
  Object.assign(state, { recommendations: null })
}

const setLoading = (state, loading) => {
  Object.assign(state, { loading })
}

const setRecommendationsLoading = (state, recommendationsLoading) => {
  Object.assign(state, { recommendationsLoading })
}

// ps: this should replace lead contract in the future
const setLeadData = (state, data) => {
  Object.assign(state, {
    leadData: { ...state.leadData, ...data },
  })
}

export default {
  setLead,
  setRecommendations,
  setRecommendationsLoading,
  clearRecommendations,
  setLoading,
  setLeadData,
}
