import AbstractHandler from './abstractHandler'
import { APPROVAFACIL_URL, PARTNERS } from './constants'

export default class ApprovaFacilHandler extends AbstractHandler {
  handle (data) {
    const { partner } = data
    if (partner === 'approvafacil') {
      return {
        partner: PARTNERS.APPROVAFACIL,
        url: APPROVAFACIL_URL,
        avaliablePartner: true,
      }
    }

    return super.handle(data)
  }
}
