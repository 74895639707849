import AMENITIES from './constants'

const pluralOrSingular = (quantity) => {
  if (quantity > 1) {
    return {
      range: 'Até ',
      plural: 's',
    }
  }
  return {
    range: '',
    plural: '',
  }
}

function parseNumber (value) {
  return value && +value
}

const getPreDeterminedAmenities = (amenities) => {
  const groupedBySynonym = {}
  amenities.forEach((amenity) => {
    const amenityConfig = AMENITIES[amenity]
    if (amenityConfig) {
      groupedBySynonym[amenityConfig.nameSynonym] = amenityConfig
    }
  })
  return Object.values(groupedBySynonym)
}

const getSecondayHeadingPhrase = (amenities, glossary, bedroomsTo) => {
  const amenitiesSingular = [...(amenities ?? [])].map((amenity) => glossary[amenity].singular)

  // returns the last element and remove it from the new array
  const lastAmenity = amenitiesSingular.pop()
  let initialAmenitiesText = ''
  let lastAmenityText = ''
  let bedroomsText = ''

  if (amenitiesSingular.length) {
    // if there's only 1 amenity, this list will be empty now, no need to run a Regex after
    //`.join()`, and if it's not empty, it means there're 2 or more amenities on the list and
    //this part of the text will need the `, ` prefix
    initialAmenitiesText = `, ${amenitiesSingular.join(', ')}`
  }

  if (lastAmenity) {
    // If there's 1 amenity on the list, it will be the last, and should always have the `e ` prefix
    lastAmenityText = ` e ${lastAmenity}`
  }

  if (+bedroomsTo) {
    bedroomsText = ` de até ${bedroomsTo} dormitório${pluralOrSingular(+bedroomsTo).plural}`
  }

  return ` conta com imóveis${bedroomsText}${initialAmenitiesText}${lastAmenityText}`.toLowerCase()
}

const infoCondominium = (amenityQuantity, amenityName) => {
  if (amenityQuantity) {
    const pluralObj = pluralOrSingular(amenityQuantity)
    return `${pluralObj.range}${amenityQuantity} ${amenityName}${pluralObj.plural}`
  }
  return null
}

const condominiumInfoTransformer = (data, glossary) => {
  const { items: { amenity: { items } } } = glossary

  const { condominium: { amenities, bedroomsTo }, page } = data

  const { condominium: condominiumData } = data

  const secondaryHeadingText = getSecondayHeadingPhrase(amenities, items, bedroomsTo)
  const completeAmenities = getPreDeterminedAmenities(amenities)

  return (
    {
      ...data,
      condominium: {
        ...data.condominium,
        amenities: completeAmenities,
        bathrooms: infoCondominium(parseNumber(condominiumData?.bathroomsTo), 'banheiro'),
        bedrooms: infoCondominium(parseNumber(condominiumData?.bedroomsTo), 'quarto'),
        parkingSpaces: infoCondominium(parseNumber(data.condominium?.parkingSpacesTo), 'vaga'),
      },
      page: {
        metaContent: {
          ...page.metaContent,
          secondaryHeading: `${page?.metaContent?.secondaryHeading}${secondaryHeadingText}.`,
        },
      },
    }
  )
}

export default condominiumInfoTransformer
export { pluralOrSingular, getPreDeterminedAmenities, getSecondayHeadingPhrase }
