import {
  treatHeader,
  treatShowcase,
  treatBanner,
  treatBlog,
} from '@/utils/constants/cms/dataManagement/home'
import { FALLBACK_DATA } from '@/utils/constants'

export default function summary (raw, treated = {}) {
  if (!raw) {
    return FALLBACK_DATA.FALLBACK_HOME_PAGE_DATA
  }

  return {
    header: () => {
      treated = treatHeader(raw, treated)
      return summary(raw, treated)
    },
    showcase: () => {
      treated = treatShowcase(raw, treated)
      return summary(raw, treated)
    },
    banner: () => {
      treated = treatBanner(raw, treated)
      return summary(raw, treated)
    },
    blog: () => {
      treated = treatBlog(raw, treated)
      return summary(raw, treated)
    },
    result: () => treated,
  }
}
