import apiClient from './client'

import {
  createParamsAccountPayload,
  createBodyAccountPayload,
} from './utils/account'

export async function checkToken (endpoint, { token }) {
  const url = `${endpoint}/oauth/check_token?token=${token}`
  await apiClient.post(url)
}

export async function logoutUser (endpoint, { token }) {
  const accountUrl = `${endpoint}/api/logout`
  const { data, ...config } = createBodyAccountPayload({
    authorization: token,
  })
  await apiClient.post(accountUrl, data, config)
}

export async function revokeUserSession (endpoint) {
  const url = `${endpoint}/user/logout`
  await apiClient.post(url)
}

export async function grantUserCompliance (endpoint, { token, xDeviceId, version }) {
  const url = `${endpoint}/api/accounts/authenticated/privacy-terms-opt-in`

  const { data, ...config } = createBodyAccountPayload({
    authorization: token,
    xDeviceId,
    accepted: true,
    version,
  })

  await apiClient.post(url, data, config)
}

export async function getUserData (endpoint, { token, xDeviceId }) {
  const url = `${endpoint}/api/accounts/me`
  return apiClient.get(url, createParamsAccountPayload({
    authorization: token,
    xDeviceId,
  }))
}

export async function getGoogleOneTapLoginToken (endpoint, { xDeviceId, googleToken }) {
  const url = `${endpoint}/api/accounts/google/one-tap?source=zap`
  const { data, ...config } = createBodyAccountPayload({
    access_token: googleToken,
    xDeviceId,
  })

  return apiClient.post(url, data, config)
}
