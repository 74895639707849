import get from 'lodash/get'
import set from 'lodash/set'
import transform from 'lodash/transform'

/**
 * Pick props from src and set in target
 * @param {Object} target - The destination of props picked from src
 * @param {Function} target.any - sets the value as the return of fn(src[any])
 * the function receives 2 params: current val on src and the src object itself
 * @param {Boolean} target.any - true: a short way of just copy the value of the same key on src
 * @param {string} target.any - will get the value from src[any]
 * @param {Object} target.any - gets value from src[any.key|target.key] and
 * transform if any.transform is a Function
 * this function receives 2 params: current val on src and the src object itself
 * @param {Object} src - Object where the information must be fetched from
 * @param {Object} [initial] - initial properties to be returned with the picked ones
 */
export default (target, src, initial = {}) => (
  transform(target, (acc, val, key) => {
    if (val === true) {
      set(acc, key, get(src, key))
    }
    if (typeof val === 'string') {
      set(acc, key, get(src, val))
      return
    }

    const valIsArray = Array.isArray(val)
    const srcKey = valIsArray ? val[0] : get(val, 'key', key)
    const defaultVal = valIsArray ? val[1] : get(val, 'default')
    const fn = (typeof val === 'function') ? val : get(val, 'transform', (_) => _)
    const srcVal = get(src, srcKey, defaultVal)
    let result

    try {
      result = fn(srcVal, src)
    } catch (e) {
      set(acc, key, e)
      return
    }

    set(acc, key, result)
  }, initial)
)
