const showConciergeVisit = ({ listing, publisher }) => {
  if (!publisher.config) return false
  const hasProperty = Object.prototype.hasOwnProperty.call(publisher.config, 'concierge')
  return hasProperty
    && publisher.config.concierge
    && listing.isAllowedConcierge
}

const offerUrl = ({ listing }, getters, rootState, rootGetters) => {
  const { fredoUrl, 'ui/modals/postLeadModal': postLeadModal } = rootGetters
  return `${fredoUrl}/iniciar-negociacao-aluguel/${listing?.id ?? postLeadModal?.listingId}`
}

const hasConcierge = ({ publisher, listing }) => {
  const isResidential = listing?.usageTypes?.includes('RESIDENTIAL') ?? false
  const isRental = listing?.pricingInfo?.isRent ?? false
  const hasConciergeConfig = publisher?.config?.concierge ?? false
  const hasAnyWarranty = listing?.pricingInfo?.warranties?.length > 0
  return isResidential && isRental && hasConciergeConfig && hasAnyWarranty
}

export default {
  showConciergeVisit,
  offerUrl,
  hasConcierge,
}
