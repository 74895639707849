function getWidgetByCategory ({ widgets }) {
  return (widgetCategory) => widgets[widgetCategory]
}

function breadcrumbWidget ({ widgets }) {
  return widgets.BREADCRUMB
}

function getFragments ({ uriFragments }) {
  return uriFragments
}

function getLink ({ link }) {
  return link
}

function getListingLinks ({ listingLinks }) {
  return listingLinks
}

function getMetaContent ({ metaContent }) {
  return metaContent
}

function getCategory ({ uriCategory }) {
  return uriCategory
}

function getPagination ({ uriPagination }) {
  return uriPagination
}

function getCondominium ({ condominium }) {
  return condominium
}

function getRedirectRule ({ redirectRule }) {
  return redirectRule
}

function parseNumber (value) {
  return value && +value
}

const getCondominiumInfo = ({ condominium }) => {
  const { condominium: condominiumState, page } = condominium

  return (
    {
      headings: {
        primaryHeading: page?.metaContent?.primaryHeading,
        secondaryHeading: page?.metaContent?.secondaryHeading,
      },
      address: {
        neighborhood: condominiumState?.address?.neighborhood,
        city: condominiumState?.address?.city,
        state: condominiumState?.address?.state,
        street: condominiumState?.address?.street,
        streetNumber: condominiumState?.address?.streetNumber,
        geoLocation: condominiumState?.address?.geoLocation,
      },
      priceInfo: {
        rentalPriceFrom: parseNumber(condominiumState?.rentalPriceFrom?.price),
        salePriceFrom: parseNumber(condominiumState?.salePriceFrom?.price),
      },
    }
  )
}

const getCondominiumAmenities = (state) => {
  const { condominium } = state.condominium

  return {
    areaFrom: parseNumber(condominium?.areaFrom),
    areaTo: parseNumber(condominium?.areaTo),
    bathrooms: condominium?.bathrooms,
    bedrooms: condominium?.bedrooms,
    parkingSpaces: condominium?.parkingSpaces,
    amenitiesItems: condominium.amenities,
  }
}

export default {
  getWidgetByCategory,
  getFragments,
  getLink,
  getListingLinks,
  getMetaContent,
  getCategory,
  getPagination,
  getCondominium,
  getRedirectRule,
  breadcrumbWidget,
  getCondominiumInfo,
  getCondominiumAmenities,
}
