const getElapsedTime = (startTime, endTime) => endTime - startTime

const setGluePagePerformance = (state, { startTime, endTime }) => {
  state.glue.page = getElapsedTime(startTime, endTime)
}

const setGlueContentManagerPerformance = (state, { startTime, endTime }) => {
  state.glue.contentManager = getElapsedTime(startTime, endTime)
}

export default {
  setGluePagePerformance,
  setGlueContentManagerPerformance,
}
