// @ts-check
import { URLS } from '@/utils/constants'
import { icHeart } from '@vivareal/lina-icons'

const SALE = {
  name: 'Comprar',
  href: `${URLS.ZAP.MAIN.URLS.SALE}${URLS.HEADER_URL_PARAMS.SALE}`,
}

const RENTAL = {
  name: 'Alugar',
  href: `${URLS.ZAP.MAIN.URLS.RENTAL}${URLS.HEADER_URL_PARAMS.RENTAL}`,
}

/**
 * @typedef {{
 *  fredoUrl?: string
 *  isSeasonalCampaignActive?: boolean
 * }} MakeMenuOptions
 */

/**
 * @typedef {{
 *  name: string
 *  href: string
 *  showLabel?: boolean
 *  textLabel?: string
 *  class?: string
 *  logged?: boolean
 * }} Link
 */

/**
 * @typedef {{
*  title: string
*  linkList: Link[]
* }} DropdownLink
*/

/**
 * @param {Pick<MakeMenuOptions, 'fredoUrl'> & { isSubmenu?: boolean }} options
 */
function makeFinancingLink (options) {
  const { isSubmenu = false } = options

  return {
    name: 'Financiamento',
    href: `${URLS.ZAP_FINANCING_URL}${URLS.HEADER_URL_PARAMS.FINANCING}`,
    showLabel: false,
    textLabel: 'Novo',
    class: isSubmenu ? 'submenu__item--align-center' : 'new-header__item--align-center',
  }
}

const DISCOVERY = {
  name: 'Descobrir',
  href: `${URLS.ZAP_DISCOVER_URL}${URLS.HEADER_URL_PARAMS.DISCOVER}`,
}

const HELP = {
  name: 'Ajuda',
  href: `${URLS.ZAPMAIS_HELP}${URLS.HEADER_URL_PARAMS.ZAPMAIS_HELP}`,
}

const CREATE_ACCOUNT = {
  name: 'Criar Conta',
  href: `${URLS.EXTERNAL.ACCOUNT_ZAP_URL}zap/signup`,
  target: '_blank',
  class: 'new-header__item--highlight',
  noHover: true,
  logged: false,
}

const FAVORITES_BUTTON = {
  name: 'Favoritos',
  href: `${URLS.MY_ZAP_URL}favoritos${URLS.HEADER_URL_PARAMS.LOGGED_FAVORITES}`,
  target: '_blank',
  class: 'new-header__item--favorite',
  noHover: true,
  logged: true,
  icon: icHeart,
}

const OWNERS = {
  name: 'Anunciar',
  href: `${URLS.EXTERNAL.ANNOUNCE_ZAP_URL}${URLS.HEADER_URL_PARAMS.ANNOUNCE}`,
}

const OWNERS_MOBILE = {
  ...OWNERS,
  class: '',
}

const FAVORITES = {
  name: 'Favoritos',
  href: `${URLS.MY_ZAP_URL}favoritos${URLS.HEADER_URL_PARAMS.LOGGED_FAVORITES}`,
  logged: true,
}

const CONTACTED = {
  name: 'Anúncios contatados',
  href: `${URLS.MY_ZAP_URL}contatados${URLS.HEADER_URL_PARAMS.LOGGED_CONTACTED}`,
  logged: true,
}

const MY_PROFILE = {
  name: 'Perfil',
  href: `${URLS.MY_ZAP_URL}meus-dados${URLS.HEADER_URL_PARAMS.LOGGED_MY_PROFILE}`,
  logged: true,
}

const MY_LISTINGS = {
  name: 'Meus anúncios',
  href: `${URLS.MY_ZAP_URL}anuncios${URLS.HEADER_URL_PARAMS.LOGGED_MY_LISTINGS}`,
  logged: true,
}

const MY_SAVED_SEARCHES = {
  name: 'Buscas salvas',
  href: `${URLS.MY_ZAP_URL}buscas-salvas${URLS.HEADER_URL_PARAMS.LOGGED_MY_SAVED_SEARCHES}`,
  logged: true,
}

const NOTIFICATIONS = {
  name: 'Notificações',
  href: `${URLS.MY_ZAP_URL}mensagens`,
  logged: true,
}

const NO_WARRANTY = {
  name: 'Aluguel online e sem fiador',
  href: `${URLS.TRANSACTION_URL}meus-imoveis${URLS.HEADER_URL_PARAMS.TRANSACTION}`,
  logged: true,
}

const INCREASE_LISTINGS = {
  name: 'Melhorar anúncios',
  href: `${URLS.MY_ZAP_URL}melhorar-anuncios`,
  logged: true,
}

const PLAN_ONLY = {
  name: 'Na planta',
  href: `${URLS.ZAP.MAIN.URLS.PLAN_ONLY}${URLS.HEADER_URL_PARAMS.PLAN_ONLY}`,
}

const UNDER_CONSTRUCTION = {
  name: 'Em construção',
  href: `${URLS.ZAP.MAIN.URLS.UNDER_CONSTRUCTION}${URLS.HEADER_URL_PARAMS.UNDER_CONSTRUCTION}`,
}

const BUILT = {
  name: 'Pronto pra morar',
  href: `${URLS.ZAP.MAIN.URLS.BUILT}${URLS.HEADER_URL_PARAMS.BUILT}`,
}

const DEVELOPMENT_ALL = {
  name: 'Todos os imóveis',
  href: `${URLS.ZAP.MAIN.URLS.DEVELOPMENT}${URLS.HEADER_URL_PARAMS.DEVELOPMENT}`,
}

const DEVELOPMENT_SUB_MENU = {
  title: 'Lançamentos',
  linkList: [
    PLAN_ONLY,
    UNDER_CONSTRUCTION,
    BUILT,
    DEVELOPMENT_ALL,
  ],
}

const SEASONAL_CAMPAIGN = {
  name: 'Festival da Mudança',
  href: new URL(
    '/feirao/festival-da-mudanca/',
    `${URLS.ZAP.URL}`,
  ).href,
}

/**
 * @param {MakeMenuOptions} options
 * @return {{
 *    DESKTOP: (Link | DropdownLink)[],
 *    MENU_BUTTONS: Link[]
 *    MOBILE: (Link | DropdownLink)[]
 * }}
 */
export function makeMenu (options) {
  return {
    DESKTOP: [
      RENTAL,
      SALE,
      DEVELOPMENT_SUB_MENU,
      ...(options.isSeasonalCampaignActive ? [SEASONAL_CAMPAIGN] : []),
      DISCOVERY,
      OWNERS,
      makeFinancingLink(options),
      HELP,
    ],
    MENU_BUTTONS: [CREATE_ACCOUNT, FAVORITES_BUTTON],
    MOBILE: [
      OWNERS,
    ],
  }
}

/**
 * @param {MakeMenuOptions} options
 * @return {{
 *    DESKTOP: (Link | DropdownLink)[]
 *    MOBILE: (Link | DropdownLink)[]
 * }}
 */
export function makeSubMenu (options) {
  return ({
    DESKTOP: [
      MY_SAVED_SEARCHES,
      CONTACTED,
      NOTIFICATIONS,
      MY_PROFILE,
      NO_WARRANTY,
      MY_LISTINGS,
      INCREASE_LISTINGS,
    ],
    MOBILE: [
      RENTAL,
      SALE,
      DEVELOPMENT_SUB_MENU,
      ...(options.isSeasonalCampaignActive ? [SEASONAL_CAMPAIGN] : []),
      DISCOVERY,
      OWNERS_MOBILE,
      makeFinancingLink({ ...options, isSubmenu: true }),
      HELP,
      FAVORITES,
      MY_SAVED_SEARCHES,
      CONTACTED,
      NOTIFICATIONS,
      MY_PROFILE,
      NO_WARRANTY,
      MY_LISTINGS,
      INCREASE_LISTINGS,
    ],
  })
}

