const setDevice = (state, device) => {
  state.device = device
}

const setUserLocation = (state, location) => {
  state.location = location
}

const setCanonicalUrl = (state, path = '') => {
  if (__BROWSER__) {
    const { origin } = window.location
    state.context.canonicalUrl = `${origin}${path}`
  }
}

export default {
  setDevice,
  setUserLocation,
  setCanonicalUrl,
}
