import { HEADERS } from '@/utils/constants/api'
import { casioErrorHandler } from '@/utils/error'

function addHeaders ({ authorization } = {}) {
  return {
    headers: {
      ...(authorization && { [HEADERS.AUTHORIZATION]: `Bearer ${authorization}` }),
      [HEADERS.CONTENT_TYPE]: 'application/json',
    },
  }
}

export function createParamsCasioPayload (params = {}) {
  const {
    authorization, xDeviceId, ...rest
  } = params
  return {
    params: {
      ...rest,
    },
    errorHandler: casioErrorHandler,
    ...addHeaders({ authorization, xDeviceId }),
  }
}

export function createBodyCasioPayload (params = {}) {
  const {
    authorization, xDeviceId, ...rest
  } = params
  return {
    data: {
      ...rest,
    },
    errorHandler: casioErrorHandler,
    ...addHeaders({ authorization, xDeviceId }),
  }
}
