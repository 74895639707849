// copy from v-money also used by this project :D
const between = (min, n, max) => Math.max(min, Math.min(n, max))

const toStr = (value) => (value ? value.toString() : '')

const onlyNumbers = (input) => (toStr(input).replace(/\D+/g, '') || '0')

const fixed = (precision) => between(0, precision, 20)

const numbersToCurrency = (numbers, precision) => {
  const exp = 10 ** precision
  const float = parseFloat(numbers) / exp
  return float.toFixed(fixed(precision))
}

const addThousandSeparator = (integer, separator) => (
  integer.replace(/(\d)(?=(?:\d{3})+\b)/gm, `$1${separator}`)
)

const joinIntegerAndDecimal = (integer, decimal, separator) => (
  decimal ? integer + separator + decimal : integer
)

const formatLargeNumber = (value) => {
  const base = Math.floor(Math.log(Math.abs(value)) / Math.log(1000))
  const suffix = ['', 'mi', 'bi'][base - 1]

  if (suffix) return String(value / (1000 ** base)).substring(0, 3) + suffix
  return Number(value).toLocaleString('pt-BR')
}

const formatDecimal = ({
  value: input,
  prefix = '',
  suffix = '',
  thousands = '.',
  decimal = ',',
  precision = 0,
  limitLargeNumbers,
}) => {
  let value = input
  if (typeof value === 'number') value = value.toFixed(fixed(precision))
  const negative = value.indexOf('-') >= 0 ? '-' : ''

  const numbers = onlyNumbers(value)
  const currency = numbersToCurrency(numbers, precision)
  const [integerPart, decimalPart] = toStr(currency).split('.')
  const integer = addThousandSeparator(integerPart, thousands)

  if (limitLargeNumbers) return prefix + negative + formatLargeNumber(value)
  return prefix + negative + joinIntegerAndDecimal(integer, decimalPart, decimal) + suffix
}

const unformatDecimal = (input, precision) => {
  const negative = toStr(input).indexOf('-') >= 0 ? -1 : 1
  const numbers = onlyNumbers(input)
  const currency = numbersToCurrency(numbers, precision)
  return parseFloat(currency) * negative
}

export default formatDecimal
export { unformatDecimal }
