// @ts-check

import defineNamespacedSelector from './define-namespaced-selector'

/**
 * @template {object} [RootState = any]
 * @template {keyof RootState} [K = keyof RootState]
 * @param {K} namespace
 * @returns {SelectorFactory<RootState[K], RootState>}
 */
export default function defineSelectorsOf (namespace) {
  return (selectionFn) => defineNamespacedSelector(namespace, selectionFn)
}

