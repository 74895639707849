const env = process.env.NODE_ENV

const blocklistUrls = [
  /ampproject/,
]

const ignoreErrors = [
  /__AMP_.+/,
  /ampproject/,
  /googletag\.pubads/, // remove this line as soon as we check GTM/DFP config
  'ResizeObserver loop limit exceeded',
]

async function initSentry (Vue, options) {
  const { default: init } = await import('./init-sentry')
  init(Vue, {
    release: __RELEASE__,
    environment: env,
    blocklistUrls,
    ignoreErrors,
    ...options,
  })
}

const { error: logErrorOnConsole } = console

function noop () {}
const isSSR = typeof window === 'undefined'
const isFacebookWebView = isSSR ? noop : window.navigator.userAgent.includes('FBAV') && window.navigator.userAgent.includes('iPhone')
const isSafari = isSSR ? noop : window.navigator.userAgent.includes('Safari') && !window.navigator.userAgent.includes('Chrome')
// eslint-disable-next-line no-nested-ternary
const onClientIdle = isSSR ? noop : isSafari || isFacebookWebView ? setTimeout : requestIdleCallback

const plugin = {
  /**
   * Installation procedure
   *
   * @param Vue
   * @param options
   */
  install (Vue, options = {}) {
    if (this.installed) return
    this.installed = true

    const shouldInit = [
      __BROWSER__,
      env === 'production',
      options.featureToggleOn,
      options.variationtest.env !== 'e2e',
    ].every(Boolean)

    if (shouldInit) {
      onClientIdle(() => {
        initSentry(Vue, options).catch(logErrorOnConsole)
      })
    }
  },
}

export default plugin
