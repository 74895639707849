/**
 *
 * @param {{
 *  pageSize: number
 *  total: number
 * } & ({
 *  index: number
 * } | {
 *  page: number
 * })} params
 * @returns
 */
export default function encodePagination ({
  index, pageSize, total, page,
}) {
  page ??= Math.ceil((index + 1) / pageSize)
  const maxPages = Math.ceil(total / pageSize)
  const pagination = {
    currentPage: page,
    listingsCount: total,
    pageResults: page < maxPages ? pageSize : total % pageSize,
    maxListingsPerPage: pageSize,
  }
  return pagination
}
