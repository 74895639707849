import Vue from 'vue'

const buildUseIncentiveAppClickedEvent = ({ context, subject, platform }) => ({
  incentiveapp: {
    context,
    subject,
    platform,
  },
  event: 'useIncentiveAppClicked',
})

export default {
  useIncentiveAppClicked (data) {
    const event = buildUseIncentiveAppClickedEvent(data)
    Vue.$clickstream.trackEvent(event)
  },
}

// Tests
export {
  buildUseIncentiveAppClickedEvent,
}
