// @ts-check

import { getVariationsAliasOf } from '../ab/selectors'

/**
 * @typedef {<T>(params: T) => (T & {variations: string[]})} VariationsAppender
 */

/**
 * @typedef ContextHelpers
 * @property { SelectorExecutor } select
 * @property { VariationsAppender } withVariations
 */

/**
 * @template S
 * @param {import("vuex").ActionContext<S>} context
 * @param {{ experiments?: Experiment[] }} options
 * @return {ContextHelpers}
 */
export default function useHelpers (context, { experiments = [] } = {}) {
  /** @type {SelectorExecutor} */
  const select = (selector) => selector(context.rootState)

  const getVariationsAlias = getVariationsAliasOf(experiments)

  return {
    select,

    /** @type {VariationsAppender} */
    withVariations (params) {
      /** @type { string[] } */
      const variations = select(getVariationsAlias)
      return { ...params, variations }
    },
  }
}
