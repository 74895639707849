const recommendations = (/** @type {import("./state").LeadsState} */ state) => state.recommendations

const loading = (/** @type {import("./state").LeadsState} */ state) => state.loading
const recommendationsLoading = (/** @type {import("./state").LeadsState} */ state) => state
  .recommendationsLoading

const leadUser = (/** @type {import("./state").LeadsState} */ state) => state.lead || {}

export default {
  recommendations,
  recommendationsLoading,
  loading,
  leadUser,
}
