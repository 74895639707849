import { SCHEMA_IMAGE_SIZES } from '@/app/constants'
import isEmpty from 'lodash/isEmpty'
import { getResizedImage } from '@vivareal/javascript-utils'

/**
 *
 * @param {import('@/store/listing/state').Listing} listing
 * @returns
 */
export function hasDuplicates (listing) {
  return (listing?.listingsCount ?? 0) > 1
}

const getListingLinkData = (listingLink) => {
  const { name, data: address } = (listingLink || {})
  const { neighborhood, city } = (address || {})
  return { name, neighborhood, city }
}

const buildListingTitle = (listingLink, shouldExcludeSize) => {
  const { name, neighborhood } = getListingLinkData(listingLink)
  const normalizedName = shouldExcludeSize ? name.split(',')[0] : name
  const title = [normalizedName, neighborhood].filter((x) => x).join(' - ')

  return title.replace(/<(.|\n)*?>/g, ' ').substr(0, 100)
}

const buildImageDescription = (listingLink) => {
  const { name = '', neighborhood = '', city = '' } = getListingLinkData(listingLink)
  return `${name} no ${neighborhood}, ${city}`
}

function buildNormalizedSchemaImages (images = [], listingLink = {}) {
  const description = buildImageDescription(listingLink)

  const parsed = images.reduce(
    (prev, url) => prev.concat(
      SCHEMA_IMAGE_SIZES
        .map((size) => getResizedImage({
          size,
          url,
          description,
          cropType: 'crop',
        })),
    ),
    [],
  )

  return parsed
}

const priceIsLowerThanExpected = (listing) => {
  if (isEmpty(listing.priceSuggestion) || !listing.pricingInfo.isRent) {
    return false
  }

  const [usableArea] = listing.usableAreas
  const { min } = listing.priceSuggestion

  return parseInt(listing.pricingInfo?.priceMap?.RENTAL?.rawPrice, 10) < (usableArea * min)
}

export {
  buildListingTitle,
  buildImageDescription,
  priceIsLowerThanExpected,
  buildNormalizedSchemaImages,
}
