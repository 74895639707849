function setPageContent (state, page = {}) {
  Object.assign(state, page)
}

const setPath = (state, path = '') => {
  state.path = path
}

const setWidgets = (state, widgets = {}) => {
  state.widgets = widgets
}

const setPageViewTrackingData = (state, data) => {
  state.pageViewTrackingData = data
}

const setPublisher = (state, publisher = {}) => {
  state.publisher = publisher
}

const setMetaContent = (state, metaContent = {}) => {
  state.metaContent = metaContent
}

const setCondominiumContent = (state, data) => {
  state.condominium = {
    ...data,
    recommendations: state.condominium.recommendations,
    listings: state.condominium.listings,
  }
}

const setCondominiumListing = (state, data) => {
  state.condominium = {
    ...state.condominium,
    listings: data,
  }
}

const setRecommendations = (state, data) => {
  state.condominium = {
    ...state.condominium,
    recommendations: data,
  }
}

const setPagination = (state, pagination) => {
  state.pagination = pagination
}

const setLoading = (state, loading) => {
  state.loading = loading
}

const setPage = (state, page) => {
  state.pagination = {
    ...state.pagination,
    currentPage: page,
  }
}

const setUriCategory = (state, uriCategory = {}) => {
  state.uriCategory = uriCategory
}

export default {
  setPageContent,
  setPath,
  setPageViewTrackingData,
  setPublisher,
  setMetaContent,
  setWidgets,
  setCondominiumContent,
  setCondominiumListing,
  setPage,
  setPagination,
  setLoading,
  setRecommendations,
  setUriCategory,
}
