import mediasFilter from '@/filters/medias'
import pricingInfoFilter from '@/filters/pricing-info'
import { isAllowedConcierge as allowConcierge } from '@/utils/transformers/listing'
import localStorage from '@/storage/local-storage'

const getPhoneNumber = (advertiserContact) => {
  const { phones } = advertiserContact

  return phones.some((phone) => phone.length > 7)
}

const businessTypeContext = localStorage.getBusinessTypeContext()

// TODO: refactor this later, check if the complexity can be lowered
const transformRecommendation = (({ scores = [], ...rest } = {}) => (
  {
    ...rest,
    scores: scores.map(({ listing: { listing, medias, ...restListing }, ...subRest }) => {
      const images = mediasFilter.images(medias)
      const { pricingInfos = [], showPrice, account: publisher } = listing
      const pricingInfo = pricingInfoFilter(pricingInfos, showPrice)
      const isAllowedConcierge = (
        pricingInfo
        && pricingInfo.priceMap
        && pricingInfo.priceMap.RENTAL
      ) ? allowConcierge({ pricingInfo: pricingInfo.priceMap.RENTAL }) : false
      const showPhoneButton = getPhoneNumber(listing.advertiserContact)

      return {
        ...subRest,
        listing: {
          ...restListing,
          listing: Object.assign(listing, {
            address: restListing.link.data,
            images,
            isAllowedConcierge,
            publisher,
            pricingInfo,
            showPhoneButton,
            businessTypeContext,
          }),
        },
      }
    }),
  }
))

export default (recommedantionData) => {
  const {
    recommendations = [],
  } = recommedantionData || {}
  return recommendations.map(transformRecommendation)
}

export { transformRecommendation }
