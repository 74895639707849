import isPlainObject from 'lodash/isPlainObject'
import mapKeys from 'lodash/mapKeys'
import mapValues from 'lodash/mapValues'

export const mapKeysDeep = (obj, fn, blacklist) => {
  const transformTo = (_, key) => {
    if (blacklist && blacklist.includes(key)) return key
    return fn(key)
  }

  if (!isPlainObject(obj)) return obj
  const result = mapKeys(obj, transformTo)
  return mapValues(result, (value) => (mapKeysDeep(value, fn, blacklist)))
}

export default { mapKeysDeep }
