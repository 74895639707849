export default {
  isDevelopment () {
    return typeof window !== 'undefined' && /dev|localhost|qa|stg/.exec(window.location.host)
  },

  _getState (store) {
    // In modules, we need root state (store is "contextualized" in module state), but in unit tests
    // for example, we just need to access state.
    return store.rootState || store.state
  },

  getGlueUrl (store) {
    const urlClient = this._getState(store).context.config.glueApiEndpoint
    const urlSSR = this._getState(store).context.config.glueApiSsrEndpoint
    return __BROWSER__ ? urlClient : urlSSR
  },

  getAldoUrl (store) {
    return this._getState(store).context.config.aldoApiEndpoint
  },

  getUserInterestUrl (store) {
    return this._getState(store).context.config.userInterestApiEndpoint
  },

  getSilvioUrl (store) {
    return this._getState(store).context.config.silvioApiEndpoint
  },

  getCasioUrl (store) {
    return this._getState(store).context.config.casioApiEndpoint
  },

  getAccountUrl (store) {
    return this._getState(store)?.context?.config?.accountApiEndpoint
  },

  getMyAccountUrl (store) {
    return this._getState(store).context.config.myAccountApiEndpoint
  },
  getOfficialStoreUrl (store) {
    return this._getState(store).context.config.officialStoreApiEndpoint
  },
}
