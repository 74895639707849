import { makeClient } from '@/api/utils/glue'

/**
 * @template State
 * @param {import('vuex').ActionContext<State>} context
 */
export default function useGlue (context) {
  return makeClient({
    domain: context.rootState.context.domain,
    glueEndpoint: context.rootGetters.glueEndpoint,
  })
}
