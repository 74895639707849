/* eslint-disable max-len */
import localStorage from '@/storage/local-storage'
import { listingsResultTransformer } from '@/utils/transformers/results'
import transformWidgets from '@/utils/transformers/widgets'
import basePageView from '@/utils/tracking/results/base-page-view'
import handlePagination from '@/utils/pagination'
import { buildListingTitle, buildNormalizedSchemaImages } from '@/utils/listing'
import useHelpers from '@/store/core/use-helpers'
import { TRUNCATE_IMAGES, getVariationOf } from '@/store/experimentations'
import getters from '../getters'

export const glueResponseHandler = (data) => {
  const defaultResult = { search: { result: {} } }
  const totalCount = data?.page?.uriPagination?.totalListingCounter || 0
  const totalCounts = {}
  const defaultFacets = { facets: {} }

  Object.entries(data).forEach(([key, obj]) => {
    if (!obj) return

    const {
      search: { totalCount: searchCount = 0 } = {},
      totalCount: total = searchCount,
    } = obj

    if (total) {
      totalCounts[key] = total
    }
  })

  const {
    account,
    accountStats,
    page: {
      metaContent,
      widgets,
      uriPagination = {},
      uriCategory = {},
      link: {
        href: path,
      } = {},
    } = {},
    fullUriFragments: {
      unitTypes = [],
      usageTypes = [],
      unitSubTypes = [],
      unitTypesV3 = [],
      addresses = [],
      business = 'SALE',
      listingType = 'USED',
      ...restFullUriFragments
    } = {},
    search: { result: { listings = [] } } = defaultResult.search,
    developments: { search: { result: { listings: developments = [] } } } = defaultResult,
    superPremium: { search: { result: { listings: superPremium = [] } } } = defaultResult,
    schema,
    facets: { amenities: facets = {} } = defaultFacets,
  } = data
  return {
    listings,
    totalCount,
    totalCounts,
    developments,
    superPremium,
    metaContent,
    widgets,
    uriPagination,
    uriCategory,
    path,
    account,
    unitTypes,
    unitSubTypes,
    usageTypes,
    unitTypesV3,
    addresses,
    business,
    listingType,
    restFullUriFragments,
    schema,
    facets,
    accountStats,
  }
}

export const handleExpandedResults = ({
  store,
  developments,
  superPremium,
  listings,
}) => {
  const {
    rootState: {
      context: {
        glossary,
      },
    },
  } = store

  const businessTypeContext = localStorage.getBusinessTypeContext()

  const developmentsResults = listingsResultTransformer({
    listings: developments,
    businessTypeContext,
    glossary,
    type: 'developments',
  })

  const superPremiumResults = listingsResultTransformer({
    listings: superPremium,
    businessTypeContext,
    glossary,
    type: 'superPremium',
  })

  const regularListings = listingsResultTransformer({
    listings,
    businessTypeContext,
    glossary,
  })

  return [].concat(
    developmentsResults,
    superPremiumResults,
    regularListings,
  )
}

function fixSchema (input, { getListingById }) {
  if (!input) {
    return input
  }

  const { itemListElement = [], ...schema } = input

  return {
    ...schema,
    itemListElement: itemListElement.map(({ item, ...element }) => {
      const listingLink = getListingById(item.sku)?.link

      return ({
        ...element,
        item: {
          ...item,
          name: buildListingTitle(listingLink),
          image: buildNormalizedSchemaImages(item.image, listingLink),
        },
      })
    }),
  }
}

/**
 * @param {{listing: import('@/store/listing/state').Listing}[]} listings
 * @param {{ context: import('vuex').ActionContext }} options
 */
function applyTruncateImagesExperiment (listings, { context }) {
  const { select } = useHelpers(context)

  const [, { maxImages }] = select(getVariationOf(TRUNCATE_IMAGES))

  if (maxImages) {
    listings = listings.map(({ listing, ...props }) => ({
      ...props,
      listing: {
        ...listing,
        images: listing.images?.slice(0, maxImages),
      },
    }))
  }

  return listings
}

export const onFetch = ({
  store,
  listings,
  superPremiumListings,
  filters,
  uriPagination,
  metaContent,
  widgets,
  path,
  account,
  seasonalCampaigns,
  categoryPage,
  levels,
  totalCounts,
  schema,
  accountStats,
  facets,
  uriCategory,
}) => {
  listings = applyTruncateImagesExperiment(listings, { context: store })

  const { total: listingsSize = listings.length } = uriPagination

  store.commit('setListings', listings)

  if (superPremiumListings) store.commit('SPGABsetSuperPremiumListings', superPremiumListings)

  const pagination = handlePagination(uriPagination, listingsSize, totalCounts)

  const pageFilters = {
    size: pagination.pageSize,
    from: 0,
    categoryPage,
    ...filters,
  }
  store.commit('setFilters', pageFilters)
  store.commit('setLevels', levels)

  store.commit('setFacets', facets)

  store.commit('setPagination', pagination)
  store.commit('page/setPublisher', { ...account, ...accountStats }, { root: true })
  store.commit('page/setMetaContent', metaContent, { root: true })
  store.commit('page/setWidgets', transformWidgets(widgets), { root: true })
  store.commit('page/setPath', path, { root: true })
  store.commit('setCanonicalUrl', path, { root: true })
  store.commit('schema/setJson', fixSchema(schema, { getListingById: getters.getListingById({ listings, superPremiumListings: [] }) }), { root: true })
  const trackingData = basePageView({ filters: pageFilters, pagination, listings })
  store.commit('page/setPageViewTrackingData', trackingData, { root: true })
  store.commit('page/setUriCategory', { uriCategory }, { root: true })
  store.commit('setSeasonalCampaigns', seasonalCampaigns)
}
