// @ts-check

import { getAuthInfo } from '@/store/auth/selector'
import { useSelect } from '@/store/core/use-select'
import { getPlatform } from '@/store/device'
import { getTrackingParams as getExperimentationParams } from '@/store/experimentations/selectors'
import { getPageCategory } from '@/store/page/selectors'

export default (store) => {
  const select = useSelect(store)

  const user = select(getAuthInfo)

  return ({
    pageCategory: select(getPageCategory),
    platform: select(getPlatform),
    ...select(getExperimentationParams),
    anonymousId: user.anonymousId,
    userId: user.userId,
    deviceSentTimestamp: Date.now(),
    gzuid: user.gzuid,
  })
}

