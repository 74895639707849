import { HEADERS } from '@/utils/constants/api'
import { glueErrorHandler } from '@/utils/error'
import client from '../client'

function addHeaders ({ domain, authorization, xDeviceId }) {
  return {
    headers: {
      [HEADERS.X_DOMAIN]: domain,
      ...(authorization && { [HEADERS.AUTHORIZATION]: `Bearer ${authorization}` }),
      ...(xDeviceId && { [HEADERS.X_DEVICE_ID]: xDeviceId }),
    },
  }
}

function addAbParams (variations = []) {
  return variations.length && { __zt: variations.join(',') }
}

function addHideSummaryParam (hideSummary = false) {
  return hideSummary && { hideSummary: true }
}

function normalizeAddressType (addressType) {
  if (addressType) {
    return { addressType: addressType.split('|').join(',') }
  }
  return {}
}

/**
 * @typedef {object} GluePlayload
 * @property {Record<string, string>} params
 * @property {(err: Error) => void} errorHandler
 * @property {Record<string, string>} headers
 */

/**
 * @typedef {object} GluePlayloadInput
 * @property {string} domain
 * @property {string} [authorization]
 * @property {string} xDeviceId
 * @property {string[]} variations
 * @property {boolean} hideSummary
 * @property {string} addressType
 */

/**
 * @template T
 * @param {T & GluePlayloadInput} params
 * @returns {GluePlayload}
 */
export default function createGluePayload (params = {}) {
  const {
    domain, authorization, xDeviceId, variations, hideSummary, addressType, ...rest
  } = params

  return {
    params: {
      ...rest,
      ...addAbParams(variations),
      ...addHideSummaryParam(hideSummary),
      ...normalizeAddressType(addressType),
    },
    errorHandler: glueErrorHandler,
    ...addHeaders({ domain, authorization, xDeviceId }),
  }
}

/**
 * @typedef {object} GlueFields
 */

/**
 * @param {GlueFields | string} fields
 * @returns {string}
 */
export function encodeFields (fields) {
  if (typeof fields === 'string') return fields

  return Object.entries(fields)
    .map(([key, value]) => value && (typeof value === 'object' ? `${key}(${encodeFields(value)})` : `${key}`))
    .filter(Boolean)
    .join(',')
}

/**
 * @typedef {object} RequestOptions
 * @property {Record<string, string | number>} [params]
 * @property {GlueFields | string} [fields]
 */

/**
 * @typedef {object} GlueClient
 * @property {<Response>(path: string, options?: RequestOptions) => Promise<Response>} get
 */

/**
 * @param {{
*  domain: string
*  glueEndpoint: string
* }} options
* @returns {GlueClient}
*/
export function makeClient ({ domain, glueEndpoint }) {
  return {
    get: (path, { params, fields } = {}) => {
      const payload = createGluePayload({
        domain,
        ...(fields ? { includeFields: encodeFields(fields) } : {}),
        ...params,
      })

      const url = new URL(path, glueEndpoint)
      return client.get(url.href, payload)
    },
  }
}
