import Agent from 'agentkeepalive'
import axios from 'axios'

const isProd = process.env.NODE_ENV === 'production'

// For browser, we can expect a higher timeout than backend process
const REQUEST_TIMEOUT_MS_PROD = __BROWSER__ ? 30000 : 2000
const REQUEST_TIMEOUT_MS_QA = 30000

const agentConfig = {
  keepAlive: true,
  keepAliveMsecs: 2000,
  maxFreeSockets: 2048,
  socketActiveTTL: 300000,
  timeout: 60000,
  freeSocketTimeout: 30000,
}

const httpsAgent = new Agent.HttpsAgent(agentConfig)
const httpAgent = new Agent(agentConfig)

export default axios.create({
  httpsAgent,
  httpAgent,
  timeout: isProd ? REQUEST_TIMEOUT_MS_PROD : REQUEST_TIMEOUT_MS_QA,
})
