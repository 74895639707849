import AbstractHandler from './abstractHandler'
import {
  KZAS_IFRAME_URL, KZAS_URL, PARTNERS,
} from './constants'

export default class KzasHandler extends AbstractHandler {
  handle (data) {
    const { partner, listingInformation } = data

    if (partner === 'kzas') {
      return {
        partner: PARTNERS.KZAS,
        url: KZAS_URL,
        iframeUrl: `${KZAS_IFRAME_URL}${listingInformation.listing.price}`,
        avaliablePartner: true,
      }
    }

    return super.handle(data)
  }
}
