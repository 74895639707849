/* eslint-disable max-len */
// eslint-disable-next-line no-unused-vars
import { getByPath } from '@/api/page'
import { fieldsTransform } from '@/utils/transformers/results'
import { getUnitTypeInUnitTypeGlossary } from '@/utils/transformers/unit-type'
import { UNIT_TYPES } from '@/utils/constants'
import { BUILT_TEXT, PLAN_ONLY_TEXT, UNDER_CONSTRUCTION_TEXT } from '@/utils/constants/construction-status'
import { glueResponseHandler, handleExpandedResults, onFetch } from './util'

// TODO: This is doing too much
// eslint-disable-next-line
const listingsFetch = async (store, { currentPath, translatedQuery, routeQuery }) => {
  store.commit('setLoading', true)
  try {
    const { glueEndpoint } = store.rootGetters
    const { domain, featureToggle } = store.rootState.context
    const { context: { unitTypeGlossary } } = store.rootState
    const variations = store.rootGetters['ab/getAllVariationNames']
    const hideSummary = true

    const publisherPage = currentPath.startsWith('/imobiliaria')
    const isCampaignPage = currentPath.startsWith('/feirao')

    const isPageSize100 = featureToggle.zap_page_size_100
    const rawRouteQuery = fieldsTransform(
      {
        store,
        fields: translatedQuery,
        publisherPage,
        isCampaignPage,
        isPageSize100,
      },
    )

    const levels = rawRouteQuery.addressType?.toUpperCase() ?? ''
    const ref = rawRouteQuery.addressUrl ?? ''

    const startTime = performance.now()
    const response = await getByPath(
      glueEndpoint,
      currentPath,
      {
        ...rawRouteQuery,
        variations,
        domain,
        hideSummary,
        levels,
        ref,
        images: 'webp',
      },
      routeQuery,
    )
    const endTime = performance.now()
    store.commit('performance/setGluePagePerformance', { startTime, endTime }, { root: true })

    if (!response?.data) return

    const {
      listings,
      totalCount,
      totalCounts,
      developments,
      superPremium,
      metaContent,
      widgets,
      path,
      uriPagination,
      uriCategory,
      account,
      unitTypes,
      usageTypes,
      unitSubTypes,
      unitTypesV3,
      addresses,
      business,
      listingType,
      restFullUriFragments,
      schema,
      facets,
      accountStats,
    } = glueResponseHandler(response.data)

    let unitTypesFilters = { unitTypes: UNIT_TYPES.UNIT_TYPE_DEFAULT }

    if (unitTypes.length !== 0 && usageTypes.length !== 0) {
      const unitTypesfullUri = {
        usageTypes,
        unitTypes,
        unitSubTypes,
        unitTypesV3,
      }

      const unitTypeSelected = getUnitTypeInUnitTypeGlossary(unitTypeGlossary, unitTypesfullUri)
      unitTypesFilters = { unitTypes: { ...unitTypeSelected } }
    }

    store.commit('page/setUriCategory', { uriCategory }, { root: true })

    const currentPathAddress = path ? { url: path } : { }

    const setFields = (neighborhood, street) => {
      if (street) return 'street'

      if (neighborhood) return 'neighborhood'

      return 'city'
    }

    const mappedAddresses = addresses.map(({ point = {}, ...rest }) => {
      const { neighborhood, street } = rest
      const type = setFields(neighborhood, street)
      return {
        ...rest,
        pointLat: point.lat,
        pointLon: point.lon,
        type,
        ...currentPathAddress,
      }
    })

    const allDevelopmentUrl = currentPath === '/lancamentos/'

    const constructionStatus = () => {
      if (allDevelopmentUrl) return `${PLAN_ONLY_TEXT},${UNDER_CONSTRUCTION_TEXT},${BUILT_TEXT}`
      if (restFullUriFragments.constructionStatus) return restFullUriFragments.constructionStatus.toString()

      return ''
    }

    const filters = {
      business,
      listingType,
      ...restFullUriFragments,
      ...unitTypesFilters,
      addresses: mappedAddresses,
      sort: translatedQuery?.sort,
      constructionStatus: constructionStatus(),
    }

    const expandedListings = handleExpandedResults({
      store,
      developments,
      superPremium: publisherPage ? [] : superPremium,
      listings,
    })

    const expandedPremiumListings = publisherPage ? handleExpandedResults({
      store,
      developments: [],
      superPremium,
      listings: [],
    }) : null

    const levelsUriCategory = uriCategory?.levels?.join() ?? ''
    onFetch({
      store,
      listings: expandedListings,
      superPremiumListings: expandedPremiumListings,
      filters,
      uriPagination: { ...uriPagination, totalCount },
      metaContent,
      widgets,
      path,
      account,
      seasonalCampaigns: response.data.seasonalCampaigns,
      uriCategory,
      categoryPage: uriCategory.page || 'RESULT',
      levels: levelsUriCategory,
      totalCounts,
      schema,
      facets,
      accountStats,
    })
  } catch (e) {
    const { code, location } = e

    if (location) {
      throw e
    }

    store.dispatch('errors/setHttpStatus', code, { root: true })
  } finally {
    store.commit('setLoading', false)
  }
}

const setListing = ({ commit, state }, updatedListing) => {
  const listings = state.listings.map((listing) => {
    if (listing.listing.id === updatedListing.listing.id) {
      return updatedListing
    }
    return listing
  })

  commit('setListings', listings)
}

export { listingsFetch, setListing }
