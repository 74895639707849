import apiClient from '@/api/client'
import createGluePayload from '@/api/utils/glue'
import { handleRedirectRuleException } from './client/handlers'

export async function getByPath (endpoint, path, params, routeQuery = {}) {
  const payload = createGluePayload(params)
  const response = await apiClient.get(`${endpoint}/v2/page${path}`, payload)
  return handleRedirectRuleException(response, routeQuery)
}

export async function getListings (endpoint, params) {
  const payload = createGluePayload(params)
  return apiClient.get(`${endpoint}/v2/listings`, payload)
}

export async function getListing (endpoint, listingId, params) {
  const payload = createGluePayload(params)
  return apiClient.get(`${endpoint}/v2/listing/${listingId}`, payload)
}

export async function getListingStats (endpoint, listingId, params) {
  const payload = createGluePayload(params)
  return apiClient.get(`${endpoint}/v1/listing-stats/${listingId}`, payload)
}

export async function getAttendanceQualityInfo (endpoint, advertiserId, params) {
  const payload = createGluePayload(params)
  return apiClient.get(`${endpoint}/v2/enem-api/advertiser/${advertiserId}?filters=['ALL']`, payload)
}
