import rpTrackingMapper from '@/pages/results/components/filters/filters.tracker.mapper'
import { EXPANSIONS, TIER_TYPES } from '@/utils/constants/results'

const isDefaulterResult = (listing) => listing.status === 'INACTIVE'

const registerExpansionType = (type, expansionTypesList) => {
  // register in passed list the type when first created
  // arg mutation needed to avoid other iteration outside
  // the main loop
  expansionTypesList.push(type)
  return {
    type,
    expanded_listing_ids: [],
    expanded_listings_count: 0,
  }
}

const mountListingsTrackingData = (listings) => {
  const defaulterResultsOnPage = []
  const listingIds = []

  // expansions
  const expansions = {}
  const expansionTypes = []

  // tiers
  const expansionsTiers = {}
  const expansionsTiersTypes = []

  const mountExpansions = (type, valueId) => {
    expansions[type] = expansions[type] || registerExpansionType(type, expansionTypes)

    expansions[type].expanded_listing_ids.push(valueId)
    expansions[type].expanded_listings_count += 1
  }

  const mountExpansionsTiers = (tier, id) => {
    const initialValues = {
      type: '',
      expanded_listing_ids: [],
      expanded_listings_count: 0,
    }

    expansionsTiers[tier] = expansionsTiers[tier] || initialValues
    expansionsTiers[tier].type = TIER_TYPES[tier] || tier
    expansionsTiers[tier].expanded_listing_ids.push(id)
    expansionsTiers[tier].expanded_listings_count += 1
  }

  // eslint-disable-next-line complexity
  listings.forEach(({ account, listing, type }) => {
    const { id } = listing
    const { tier } = account
    listingIds.push(id)

    if (isDefaulterResult(listing)) {
      defaulterResultsOnPage.push(id)
    }

    if (EXPANSIONS[type]) {
      mountExpansions(EXPANSIONS[type], id)
    }

    if (!expansionsTiersTypes.includes(TIER_TYPES[tier] || 'NO TIER')) {
      expansionsTiersTypes.push(TIER_TYPES[tier] || 'NO TIER')
    }

    mountExpansionsTiers(tier || 'NO TIER', id)
  })

  return {
    listingIds,
    expandedResultsAvailableTypes: expansionTypes.length ? expansionTypes : null,
    expandedResultsTiersAvailableTypes: expansionsTiersTypes,
    expandedResultsOnPage: expansionTypes.length ? Object.values(expansions) : null,
    expandedResultsTiersOnPage: Object.values(expansionsTiers),
    defaulterResultsOnPage: defaulterResultsOnPage.length ? defaulterResultsOnPage : null,
  }
}

const basePageView = (data) => {
  const { filters, pagination, listings } = data

  const {
    hasVirtualTour, amenities = [], stamps = [],
  } = filters

  const ranking = {
    filter: rpTrackingMapper.filters(filters),
    pagination: rpTrackingMapper.pagination(pagination),
    ...mountListingsTrackingData(listings),
  }

  ranking.filter.metadata = []
  ranking.filter.amenities = amenities
  ranking.filter.stamps = stamps

  if (hasVirtualTour) {
    ranking.filter.metadata.push('VIRTUAL TOUR')
  }

  return ranking
}

export default basePageView

export {
  isDefaulterResult,
  registerExpansionType,
  mountListingsTrackingData,
}
