export const TYPE_FORM_VALUE = 'CONTACT_FORM'
export const TYPE_PHONE_VALUE = 'PHONE_VIEW'

export const LEAD_TYPE = /** @type {const} */ ({
  CHAT: 'CHAT',
  EMAIL: 'EMAIL',
  PHONE: 'PHONE',
  TOUR: 'TOUR',
  WHATSAPP: 'WHATSAPP',
})

/**
 * @typedef {(typeof LEAD_TYPE)[keyof typeof LEAD_TYPE]} LeadType
 */
