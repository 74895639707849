/* eslint-disable max-len */
import { UNIT_TYPES } from '@/utils/constants'
import { UNIT_TYPES_URL } from './enum-urls'

const getUsageTypeGroupName = (usageType) => {
  const {
    [usageType]: {
      label: group = usageType,
    } = {},
  } = UNIT_TYPES.TYPES_MAPPER

  return group
}

const unitTypesGlossaryInGroups = (unitTypeGlossary = []) => (
  unitTypeGlossary.reduce((acc, curr) => {
    const { usageType } = curr

    if (!acc[usageType]) {
      acc[usageType] = {
        group: getUsageTypeGroupName(usageType),
        items: [],
      }
    }

    acc[usageType].items.push({
      unitSubTypes: [curr.unitSubTypes.join(',')],
      unitTypes: curr.unitType,
      usageTypes: usageType,
      unitTypesV3: curr.unitTypeV3,
      text: curr.name,
    })

    return acc
  }, {})
)

const unitTypesTransform = (unitTypesGlossary) => {
  const {
    UsageType_NONE: noneType,
    ...unitTypesGroups
  } = unitTypesGlossaryInGroups(unitTypesGlossary)

  return unitTypesGroups
}

const unitTypesValues = (items, listingType, group) => {
  if (listingType === 'DEVELOPMENT') {
    items = items.filter(({ text }) => UNIT_TYPES.DEVELOPMENT_MAPPER_TEXTS[group].includes(text))
  }

  return items.map((rule) => {
    const treatedSubTypes = Array.isArray(rule.unitSubTypes) ? rule.unitSubTypes.join(',') : rule.unitSubTypes
    return {
      label: rule.text,
      value: `${rule.unitTypes}|${treatedSubTypes}|${rule.usageTypes}|${rule.unitTypesV3}`,
    }
  })
}

const unitTypesOptions = (listingType, unitTypes) => {
  const type = listingType ?? 'SALE'
  const { COMMERCIAL, RESIDENTIAL } = UNIT_TYPES.TYPES_MAPPER

  return {
    RESIDENTIAL: {
      group: RESIDENTIAL.label,
      items: unitTypesValues(unitTypes.RESIDENTIAL.items, type, 'RESIDENTIAL'),
    },
    COMMERCIAL: {
      group: COMMERCIAL.label,
      items: unitTypesValues(unitTypes.COMMERCIAL.items, type, 'COMMERCIAL'),
    },
  }
}

const unitTypeFieldsParam = (selectedUnitTypes, unitTypeGlossary) => {
  const unitTypesList = selectedUnitTypes.split(',')

  const unitTypeSelectedList = []

  const getUnitTypesV3 = (unit) => Object.entries(UNIT_TYPES_URL)
    .reduce((acc, [key, value]) => {
      if (value === unit) { acc = key }
      return acc
    }, '')

  const usageTypeSelected = (groupUnitType) => Object.keys(unitTypeGlossary)
    .find((groupKey) => unitTypeGlossary[groupKey].group.toLowerCase() === groupUnitType)

  const unitTypeSelected = (usageType, unitTypeV3) => unitTypeGlossary[usageType].items
    .find((item) => item.unitTypesV3 === unitTypeV3)

  unitTypesList.forEach((el) => {
    const unitTypeV3 = getUnitTypesV3(el)

    if (unitTypeV3) {
      const groupUnitType = el.split('_')[1]

      const usageType = usageTypeSelected(groupUnitType)

      const { unitSubTypes, ...rest } = unitTypeSelected(usageType, unitTypeV3)
      const unitType = { ...rest, unitSubTypes: unitSubTypes.toString() }

      unitTypeSelectedList.push(unitType)
    }
  })
  return unitTypeSelectedList
}

const unitTypesFieldsEncode = (obj) => Object.entries(obj).reduce((acc, [key, value]) => {
  const parsedValue = Array.isArray(value) ? value.join(',') : value
  return {
    ...acc,
    [key]: parsedValue,
  }
}, {})

const getUnitTypeInUnitTypeGlossary = (unitTypeGlossary, value) => {
  if (Object.keys(value).length === 0) return {}

  const {
    usageTypes = '', unitTypes = '', unitSubTypes = '', unitTypesV3 = '',
  } = value

  const convertUnitSubTypes = Array.isArray(unitSubTypes) ? unitSubTypes.join('|') : unitSubTypes
  const units = {
    usageTypes: usageTypes?.toString(),
    unitTypes: unitTypes?.toString(),
    unitSubTypes: convertUnitSubTypes,
    unitTypesV3: unitTypesV3?.toString(),
  }

  return units
}

export {
  unitTypesTransform,
  unitTypesOptions,
  unitTypesValues,
  unitTypesFieldsEncode,
  getUnitTypeInUnitTypeGlossary,
  unitTypeFieldsParam,
}
