const getListingById = ({ listings, superPremiumListings }) => (id) => (
  listings.find((listing) => listing.listing.id === id)
  || superPremiumListings.find((listing) => listing.listing.id === id)
)

const SPGABshouldRenderCarousel = (state, getters, rootState, rootGetters) => {
  if (rootGetters.currentQuery?.__zt === 'spg:b') {
    return !!rootState.device?.isDesktop && state.superPremiumListings.length >= 3
  }

  return state.superPremiumListings.length >= 3
}

const filters = (state) => state.filters

const loading = (state) => state.loading

const pagination = (state) => state.pagination

const mapToggle = (state) => state.isMapOpen

const getFacets = (state) => state.facets

export default {
  getListingById,
  SPGABshouldRenderCarousel,
  filters,
  pagination,
  loading,
  mapToggle,
  getFacets,
}
