const setFilters = (state, filters) => {
  Object.assign(state, { filters })
}

const setListings = (state, { listings }) => {
  Object.assign(state, { listings })
}

const setPagination = (state, pagination) => {
  Object.assign(state, { pagination })
}

const setLoading = (state, loading) => {
  Object.assign(state, { loading })
}

export default {
  setFilters,
  setListings,
  setLoading,
  setPagination,
}
