const listCategories = ['SITEMAP_LINK', 'MARKDOWN_PAGE_LINK']

function transformWidgets (widgets = []) {
  if (!widgets.length) return {}

  const transformedWidgets = {}

  widgets.forEach((widget) => {
    const category = widget?.category

    if (!category) return

    if (listCategories.includes(category)) {
      const listWidgets = transformedWidgets[category] ?? []
      listWidgets.push(widget)
      transformedWidgets[category] = listWidgets
      return
    }

    transformedWidgets[category] = widget
  })

  return transformedWidgets
}

export default transformWidgets
