const HEADERS = Object.freeze({
  X_DOMAIN: 'X-Domain',
  CONTENT_TYPE: 'Content-Type',
  AUTHORIZATION: 'Authorization',
  X_DEVICE_ID: 'X-DeviceId',
})

const CASIO_CODE_ERROR_MESSAGE = Object.freeze({
  SCH001: 'A data de visita não está mais disponível, por favor atualize a página.',
  SCH009: 'Já existe uma visita agendada por você no imóvel, por favor faça o login para ver.',
  SCH011: 'A data escolhida é muito próxima para o anunciante, por favor atualize a página.',
  SCH002: 'A data de visita não está acessível, por favor faça o login para ver.',
  SCH004: 'A data de visita já foi cancelada, por favor atualize a página.',
  SCH010: 'Essa visita já foi finalizada e não pode mais ser cancelada, por favor atualize a página.',
})

// eslint-disable-next-line
export { HEADERS, CASIO_CODE_ERROR_MESSAGE }
