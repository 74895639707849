import modals from './modals'
import ldp from './ldp'

export default {
  namespaced: true,
  modules: {
    modals,
    ldp,
  },
}
