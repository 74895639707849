import { CASIO_CODE_ERROR_MESSAGE } from '@/utils/constants/api'

// comandate-hamilton needs this error with code and location to make redir
function HttpStatusError (message, statusCode, redirectTo = null) {
  this.name = 'HttpStatusError'
  this.message = message
  this.code = statusCode
  this.location = redirectTo
}

HttpStatusError.prototype = Error.prototype

function CasioApiError (message, code) {
  this.name = 'CasioApiStatusError'
  this.message = message
  this.code = code
}

CasioApiError.prototype = Error.prototype

const HTTP_SERVER_FAULT_STATUS_CODE = Object.freeze([424])

function isServerFaultStatusCode (serverFaultCode) {
  return HTTP_SERVER_FAULT_STATUS_CODE
    .includes(serverFaultCode)
}

function glueErrorHandler (error) {
  const statusCode = error.response?.status

  return new HttpStatusError(
    'Http server error',
    isServerFaultStatusCode(statusCode) ? 500 : statusCode,
    null,
  )
}

function casioErrorHandler (error) {
  const { code } = error.response.data

  return new CasioApiError(
    CASIO_CODE_ERROR_MESSAGE[code],
    code,
  )
}

export {
  HttpStatusError,
  CasioApiError,
  glueErrorHandler,
  casioErrorHandler,
}
