// @ts-check

/** @type {any} */
const DEFAULT_VARIATION = 'default'

export const experiments = []

/**
 * @template {VariationId} K
 * @template [P = void]
 *
 * @param {K} id
 * @param {P} [payload]
 * @returns {Variation<K, P>}
 */
export function defineVariation (id, payload) {
  return [id, payload]
}

/**
 * @param {Variation|VariationId} v
 * @returns {v is Variation}
 */
function isVariation (v) {
  return Array.isArray(v)
}

/**
 * @template {VariationId} K
 * @template P
 * @param {K | Variation<K, P>} v
 * @returns {Variation<K, P>}
 */
function ensureIsVariation (v) {
  if (isVariation(v)) {
    return v
  }

  return defineVariation(v)
}

/**
 * @template {VariationId} K
 * @template {Variation<K, any>} V
 * @param {Record<string | number, V> | undefined} map
 * @param {K} variationId
 * @returns {V}
 */
function pickDefaultVariation (map, variationId) {
  /** @returns {V} */
  function makeDefault () {
    // @ts-ignore
    return defineVariation(variationId)
  }

  if (!map) {
    return makeDefault()
  }

  const [found] = Object.entries(map)
    .filter(([, [id]]) => id === variationId)

  if (found) {
    return found[1]
  }

  if (__DEV__) {
    throw new Error('')
  }

  return makeDefault()
}

/**
 * @template {VariationId} V
 * @template P
 * @template {string|number} I
 *
 * @param {Array<V | Variation<V, P>> | Record<I, Variation<V, P> | V>} variations
 * @return {Partial<Record<V | I, Variation<V, P>>>}
 */
function makeVariationsMap (variations) {
  if (Array.isArray(variations)) {
    return variations.reduce((r, v) => {
      const variation = ensureIsVariation(v)
      return { ...r, [variation[0]]: variation }
    }, {})
  }

  return Object.entries(variations)
    .reduce((r, [m, v]) => ({ ...r, [m]: ensureIsVariation(v) }), {})
}

/**
 * @template {ExperimentId} K
 * @template {VariationId} V
 * @template [P = void]
 * @template {string | number} [I = string]
 * @template {V} [DV = V]
 *
 * @param {K} id
 * @param {{
 *  defaultVariation: DV
 *  alias?: string,
 *  variations?: V[] | Variation<V, P>[] | Record<I, Variation<V, P> | V>
 * }} [options]
 *
 * @returns {Experiment<K, Variation<V, P>>}
 */
export default function defineExperiment (id, options) {
  const {
    variations,
    alias = id,
    defaultVariation: defaultId = DEFAULT_VARIATION,
  } = options ?? {}

  const map = variations && makeVariationsMap(variations)

  const defaultVariation = pickDefaultVariation(map, defaultId)

  /** @type {Experiment<K, Variation<V, P>>} */
  const experiment = {
    id,
    defaultVariation,

    pickVariation (v) {
      if (map) {
        const found = map[v]

        if (found) {
          return found
        }

        if (__DEV__) {
          throw new Error([
            `Não foi possível encontrar a variante de "${id}" identificada por "${v}".`,
            'Talvez ela não tenha sido adicionada ao `map` do `defineExperiment`.',
          ].join('\n'))
        }

        return defaultVariation
      }

      // @ts-ignore
      return defineVariation(v)
    },

    makeVariationAlias ([variationId]) {
      return `${alias}:${variationId}`
    },
  }

  experiments.push(experiment)

  return experiment
}

