export const CONSTRUCTION_STATUS_DEFAULT = 'constructionStatus_NONE'

export const PLAN_ONLY_TEXT = 'PLAN_ONLY'
export const UNDER_CONSTRUCTION_TEXT = 'UNDER_CONSTRUCTION'
export const BUILT_TEXT = 'BUILT'

const moutObject = (constructionStatus, text) => ({
  constructionStatus,
  text,
})

const PLAN_ONLY = moutObject(PLAN_ONLY_TEXT, 'Na planta')
const UNDER_CONSTRUCTION = moutObject(UNDER_CONSTRUCTION_TEXT, 'Em construção')
const BUILT = moutObject(BUILT_TEXT, 'Pronto para morar')

export const DEVELOPMENT_TYPE = [PLAN_ONLY, UNDER_CONSTRUCTION, BUILT]
