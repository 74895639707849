import {
  checkToken,
  logoutUser,
  revokeUserSession,
  grantUserCompliance,
  getGoogleOneTapLoginToken,
  getUserData,
} from '@/api/account'
import cookieStorage from '@/storage/cookie-storage'
import userTransform from '@/utils/transformers/user'
import auth from '@/utils/auth'
import { getAnonymousId } from './selector'
import loginTracker from '../../app/login.tracker'

const checkAuthStatus = async ({
  rootGetters, state, commit,
}) => {
  try {
    const { token } = state

    if (!token) return

    const { accountEndpoint } = rootGetters

    await checkToken(accountEndpoint, { token })
  } catch {
    cookieStorage.deleteUser()
    cookieStorage.removeAccessToken()
    commit('revokeCredentials')
    commit('deleteUser')
  }
}

const logout = async ({ rootGetters, state, commit }) => {
  const { token } = state

  const { accountEndpoint, myAccountEndpoint } = rootGetters

  await Promise.all([
    logoutUser(accountEndpoint, { token }),
    revokeUserSession(myAccountEndpoint),
  ])

  cookieStorage.deleteUser()
  cookieStorage.removeAccessToken()
  commit('revokeCredentials')
  commit('deleteUser')
}

const acceptTerms = async ({ rootGetters, state, commit }, version) => {
  const { token, user } = state

  const data = {
    xDeviceId: cookieStorage.getUserId(),
    version,
    token,
  }

  const { accountEndpoint } = rootGetters

  await grantUserCompliance(accountEndpoint, data)

  const newUser = {
    ...user,
    termsOptIns: version,
  }

  cookieStorage.setUser(newUser)
  commit('setUser', newUser)
}

const googleOneTapLogin = async ({ rootGetters, rootState, commit }, googleToken) => {
  try {
    const xDeviceId = getAnonymousId(rootState)
    const { accountEndpoint } = rootGetters

    const {
      data: { access_token: token },
    } = await getGoogleOneTapLoginToken(accountEndpoint, { xDeviceId, googleToken })

    const { data: userData } = await getUserData(accountEndpoint, { token, xDeviceId })

    const user = userTransform.mapUser(userData)

    cookieStorage.setUser(user)
    cookieStorage.setAccessToken(token)
    commit('grantCredentials', token)
    commit('setUser', user)

    loginTracker.loginFlow({
      login: {
        link_label: 'ONE TAP',
        referer_url: window.location.href,
        trig: 'login google',
        type: 'LOGIN',
      },
    })
  } catch {
    const { origin } = window.location
    const { loginUrl, currentFullPath } = rootGetters

    const redirectUrl = `${origin}${currentFullPath}`
    window.location = auth.createLoginUrl(loginUrl, 'login google', redirectUrl)
  }
}

export default {
  checkAuthStatus,
  logout,
  acceptTerms,
  googleOneTapLogin,
}
