import saveSearch from '@/api/saved-search'
import { getAnonymousId } from '@/store/auth/selector'
import storage from '@/storage/local-storage'

const addSearch = async ({ rootState, rootGetters }, filters) => {
  const { token } = rootState.auth
  const xDeviceId = getAnonymousId(rootState)

  const { userInterestEndpoint } = rootGetters

  const params = {
    authorization: token,
    xDeviceId,
    ...filters,
  }

  const response = await saveSearch(userInterestEndpoint, params)

  return response
}

function checkSavedSearchStorage () {
  const savedSearch = storage.getSavedSearchState()

  if (savedSearch.name) {
    this
      .dispatch('savedSearch/addSearch', savedSearch)
      .finally(() => storage.cleanSavedSearchState())
  }
}

export default {
  addSearch,
  checkSavedSearchStorage,
}
