import Vue from 'vue'
import { buildRankingRenderedData } from './ranking-data'

const buildRankingRenderedEvent = ({
  filter,
  pagination,
  listings,
  action,
}) => ({
  ranking: buildRankingRenderedData(
    filter,
    pagination,
    listings,
    action,
  ),
  event: 'rankingRendered',
})

export default {
  rankingRendered (data) {
    const event = buildRankingRenderedEvent(data)
    Vue.$clickstream.trackEvent(event)
  },
}

export { buildRankingRenderedEvent }
