import {
  getVisitUser,
  getVisitSchedule,
  getVisitScheduleDates,
  createVisitSchelude,
  cancelVisitSchedule,
  sendVisitSurvey,
} from '@/api/visit'

const getVisit = (item, id = null) => ({
  id: id || item.id,
  listingId: item.listingId,
  date: new Date(item.date),
})

const getUserInfo = ({ document, phoneNumber }) => ({
  document,
  phoneNumber,
})

const getUser = async ({ commit, rootState, rootGetters }) => {
  const { token } = rootState.auth
  const { casioEndpoint } = rootGetters

  const {
    data: {
      document = null,
      phoneNumber = null,
    },
  } = await getVisitUser(casioEndpoint, { token })

  if (document) {
    commit('setUserInfo', {
      document,
      phoneNumber,
    })
  }
}

const sendSchedule = async ({ commit, rootGetters, rootState }, schedule) => {
  const { token } = rootState.auth
  const { casioEndpoint } = rootGetters

  const {
    data: { id },
  } = await createVisitSchelude(casioEndpoint, { schedule, token })

  if (id) {
    commit('setVisit', getVisit(schedule, id))
    commit('setUserInfo', getUserInfo(schedule.contact))
  }
}

const getScheduleDates = async (
  { commit, rootGetters },
  { advertiserId, listingId },
) => {
  const { casioEndpoint } = rootGetters

  const {
    data: { dates },
  } = await getVisitScheduleDates(casioEndpoint, { advertiserId, listingId })
  commit('setDates', dates)
}

const getSchedule = async ({ commit, rootState, rootGetters }, listingId) => {
  const { token } = rootState.auth
  const { casioEndpoint } = rootGetters

  const {
    data: { total, results },
  } = await getVisitSchedule(casioEndpoint, { listingId, token })

  if (total > 0) {
    const [item] = results
    commit('setVisit', getVisit(item))
    return
  }

  commit('setVisit', null)
}

const cancelSchedule = async ({ commit, rootState, rootGetters }, scheduleId) => {
  const { token } = rootState.auth
  const { casioEndpoint } = rootGetters
  const {
    data: { id },
  } = await cancelVisitSchedule(casioEndpoint, { scheduleId, token })
  if (id) {
    commit('setVisit', null)
  }
}

const sendSurvey = async ({ rootGetters }, survey) => {
  const { casioEndpoint } = rootGetters
  await sendVisitSurvey(casioEndpoint, { survey })
}

export default {
  getUser,
  sendSchedule,
  getScheduleDates,
  getSchedule,
  cancelSchedule,
  sendSurvey,
}
