import { getAuthInfo } from '@/store/auth/selector'
import defineSelector from '@/store/core/define-selector'
import { getPlatform } from '@/store/device'
import { getTrackingParams } from '@/store/experimentations/selectors'
import { getPageCategory } from '@/store/page/selectors'

const getPageParams = defineSelector((_, select) => {
  const document = __BROWSER__ ? window.document : undefined

  return ({
    page_url: document?.location?.href,
    referer_url: document?.referrer || '',
    page_category: select(getPageCategory),
  })
})

const getExperimentationParams = defineSelector((_, select) => {
  const { ab_tests: abTests, experimentId, variationName } = select(getTrackingParams)

  return ({
    ab_tests: abTests,
    experiment_id: experimentId,
    variation_name: variationName,
  })
})

const getUserInfoParams = defineSelector((_, select) => {
  const {
    gzuid,
    userId,
    anonymousId,
    sessionId,
  } = select(getAuthInfo)

  return ({
    gzuid,
    user_id: userId,
    anonymous_id: anonymousId || 'unknown',
    session_id: sessionId,
  })
})

const getTrackingContext = defineSelector((state, select) => ({
  ...select(getPageParams),
  ...select(getExperimentationParams),
  ...select(getUserInfoParams),

  platform: select(getPlatform),
  event_version: 'v.2',
}))

export default getTrackingContext
