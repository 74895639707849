// @ts-check

import {
  getLegacyVariationsAlias,
} from './selectors'

function getAllVariationNames (state, getters, rootState) {
  return getLegacyVariationsAlias(rootState)
}

export default { getAllVariationNames }
