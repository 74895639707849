import ClickstreamPlugin from './clickstream-plugin'
import sendToClickstream from './clickstream'

/** @type {import('vue').PluginObject<import('./clickstream-plugin').TrackingOptions>} */
const plugin = {
  install (Vue, options) {
    if (this.installed) return
    this.installed = true

    const dataLayer = __BROWSER__ ? (window.dataLayer ??= []) : []

    Vue.$clickstream = new ClickstreamPlugin({ ...options, dataLayer })
    Vue.prototype.$clickstream = Vue.$clickstream

    if (__BROWSER__) {
      const { trackerEndpoint } = options
      window.clickstreamTracker = function (rawData) {
        sendToClickstream(rawData, trackerEndpoint)
      }
    }
  },
}

export default plugin
