
/**
 * @typedef {Object} UserContact
 * @property {string} [name]
 * @property {string} [email]
 * @property {string} [phoneNumber]
 */

/**
 * @typedef {object} User
 * @property {string} [nome]
 * @property {string} [sobrenome]
 * @property {string} [email]
 * @property {string} [dddCelular]
 * @property {string} [celular]
 * @property {boolean} [termsOptIns]
 */

/**
 * @typedef {Object} AuthState
 * @property {User | null} [user]
 * @property {string | null} [token]
 * @property {boolean} authenticated
 * @property {string} anonymousId
 */

export default () => ({
  user: null,
  token: null,
  authenticated: false,
  anonymousId: 'unknown',
})
