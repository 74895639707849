import apiClient from '@/api/client'

import {
  createParamsCasioPayload,
  createBodyCasioPayload,
} from './utils/casio'

export async function getVisitUser (endpoint, { token }) {
  const url = `${endpoint}public/v1/me`

  const payload = createParamsCasioPayload({
    authorization: token,
  })

  return apiClient.get(url, payload)
}

export async function getVisitSchedule (endpoint, { listingId, token }) {
  const url = `${endpoint}public/v1/schedule`

  const payload = createParamsCasioPayload({
    authorization: token,
    listingId,
    parentStatus: 'IN_PROGRESS',
  })

  return apiClient.get(url, payload)
}

export async function getVisitScheduleDates (endpoint, {
  advertiserId, listingId,
}) {
  const url = `${endpoint}public/v1/schedule/dates`

  const payload = createParamsCasioPayload({
    listingId,
    advId: advertiserId,
  })

  return apiClient.get(url, payload)
}

export async function createVisitSchelude (endpoint, { token, schedule }) {
  const url = `${endpoint}public/v1/schedule`

  const { data, ...config } = createBodyCasioPayload({
    authorization: token,
    ...schedule,
  })

  return apiClient.post(url, data, config)
}

export async function cancelVisitSchedule (endpoint, { token, scheduleId }) {
  const url = `${endpoint}public/v1/schedule/${scheduleId}/cancel`

  const { data, ...config } = createBodyCasioPayload({
    authorization: token,
  })

  return apiClient.put(url, data, config)
}

export async function sendVisitSurvey (endpoint, { survey }) {
  const url = `${endpoint}public/v1/survey`

  const { data, ...config } = createBodyCasioPayload({
    ...survey,
  })

  return apiClient.post(url, data, config)
}
