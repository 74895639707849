function pick (target, keyList) {
  const result = {}
  keyList.forEach((keyName) => {
    const hasKey = Object.prototype.hasOwnProperty.call(target, keyName)
    if (hasKey) {
      Object.assign(result, { [keyName]: target[keyName] })
    }
  })
  return result
}

export default pick
