import apiClient from './client'

import {
  createBodyUserInterestPayload,
} from './utils/user-interest'

const saveSearch = async (endpoint, params) => {
  const url = `${endpoint}/searches`

  const { data, ...config } = createBodyUserInterestPayload(params)

  return apiClient.post(url, data, config)
}

export default saveSearch
