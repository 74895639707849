import defineSelectorsOf from '../core/define-selectors-of'
import defineRootSelector from '../core/define-selector'
import getters from './getters'

const defineSelector = defineSelectorsOf('page')

export const getUriCategory = defineSelector((state) => state?.uriCategory?.uriCategory)

export const PAGE_CATEGORY = {
  listing: 'LISTING DETAIL PAGE',
  results: 'RESULT PAGE',
  home: 'HOME',
  advertisers: 'ADVERTISERS PAGE',
  markdown: 'MARKDOWN RESULT PAGE',
  inactive: 'DEFAULTER ADVERTISER LISTING DETAIL PAGE',
  'neighborhood-guide': 'NEIGHBORHOOD GUIDE',
  condominium: 'CONDOMINIUM RESULT PAGE',
}

export const getPageCategory = defineRootSelector((state) => {
  const {
    route: { name },
    listing: { listing = undefined } = {},
  } = state

  let pageCategory = PAGE_CATEGORY[name]

  if (name === 'listing' && listing?.isDefaulterInactive) {
    pageCategory = PAGE_CATEGORY.inactive
  }

  return pageCategory
})

export const getBreadcrumbsSchema = defineSelector((state) => {
  const links = getters.breadcrumbWidget(state)?.links

  if (!links) {
    return undefined
  }

  return {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: links.map((item, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      item: {
        '@id': item.href,
        name: item.name,
      },
    })),
  }
})
