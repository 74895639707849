import { icBell } from '@vivareal/lina-icons'
import UaParser from 'ua-parser-js'
import * as BANNER_DATA from '@/lib/constants/mobile-store'
import tracker from './oz-app-incentive.tracker'

const {
  REDIRECT_URL,
} = BANNER_DATA

export default {
  name: 'oz-app-incentive',

  props: {
    isMenu: { type: Boolean, default: false },
    location: { type: String, default: '' },
  },

  data () {
    return {
      icon: icBell,
      platform: new UaParser().getOS().name,
    }
  },

  mounted () {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault()
    })
  },

  computed: {
    redirectUrl () {
      return REDIRECT_URL + BANNER_DATA[`${this.platform.toUpperCase()}_PATH`]
    },
    boldText () {
      return this.isMenu ? 'Instale o aplicativo e seja o primeiro a saber das novidades!' : 'Seja o primeiro a saber das novidades!'
    },
    buttonStyle () {
      return this.isMenu ? 'inverse' : 'standard'
    },
    isMenuStyle () {
      return this.isMenu && 'primary'
    },
  },
  methods: {
    redirectToStore () {
      this.track()
      window.open(this.redirectUrl)
    },
    track () {
      tracker.useIncentiveAppClicked({
        context: this.location,
        subject: 'Baixar Aplicativo',
        platform: this.platform.toUpperCase(),
      })
    },
  },
}
