import { OFFICIAL_STORE_STAMPS } from '@/utils/constants/results'

const getOfficialStoreFeature = (state, featureName) => state.storeData.features?.find(
  (feature) => feature.name === featureName,
)

const getOfficialStoreStamp = (state, stampName) => {
  const stamps = getOfficialStoreFeature(state, 'STAMP')
  return stamps?.data[stampName]
}

export default {
  hasOfficialStore: (state) => !!state.storeData,
  getOfficialStoreDescription: (state) => state.storeData?.description,
  getOfficialStoreWhatsApp: (state) => {
    const whatsapp = getOfficialStoreFeature(state, 'WHATSAPP')
    return whatsapp?.enable ? whatsapp.data.number : false
  },
  getActiveStamps: (state) => OFFICIAL_STORE_STAMPS.reduce((map, stamp) => {
    map[stamp] = getOfficialStoreStamp(state, stamp)?.active
    return map
  }, {}),
  getAnswerRate: (state) => {
    const answerRate = getOfficialStoreFeature(state, 'ANSWER_RATE')
    return answerRate?.enable ? answerRate.data.scoreQuality : false
  },
  getAdvertiserRating: (state) => {
    const adversiterRating = getOfficialStoreFeature(state, 'ADVERTISER_RATING')
    return adversiterRating?.enable ? adversiterRating.data : false
  },
  getSocialMedia: (state) => {
    const socialMedia = getOfficialStoreFeature(state, 'SOCIAL')
    return socialMedia?.enable ? socialMedia.data : false
  },
}
