const LEAD = {
  VIRTUAL_TOUR: 'VIRTUAL TOUR',
  ANSWERS_REQUEST: 'ANSWERS_REQUEST',
  INACTIVE_LISTING: 'INACTIVE LISTING',
  DEFAULTER_ADVERTISER: 'DEFAULTER ADVERTISER',
  FLOATING_MESSAGE_BUTTON: 'FLOATING MESSAGE BUTTON',
  FLOATING_PHONE_BUTTON: 'FLOATING PHONE BUTTON',
  ALERT_MESSAGE: 'ALERT MESSAGE',
  POST_LEAD: 'POST LEAD',
  PANEL: 'PANEL',
  MAP: 'MAP',
  ENDING: 'ENDING',
  FAVORITES: 'FAVORITES',
  AMENITIES: 'AMENITIES',
  WHATSAPP: 'WHATSAPP',
  WHATSAPP_APPOINTMENT_REQUEST: 'WHATSAPP APPOINTMENT REQUEST',
  LISTING_CARD: 'LISTING CARD',
}

const GALLERY = {
  PHOTO_BUTTON: 'PHOTO BUTTON',
  PHOTO: 'PHOTO',
  TAB: 'GALLERY TAB',
  BUTTON: 'GALLERY BUTTON',
  PHOTO_TAB: 'PHOTO TAB',
  VIDEO_TAB: 'VIDEO TAB',
  MAP: 'LISTING ADDRESS',
}

const AUTOCOMPLETE = {
  LOCATION: 'LOCATION',
}

const FAVORITE = {
  MODAL: 'FAVORITE MODAL',
  BUTTON: 'FAVORITE BUTTON',
  GALLERY: 'GALLERY',
}

const SHARE = {
  BUTTON: 'SHARE BUTTON',
}

const MAP = {
  BUTTON: 'MAP BUTTON',
}

export {
  LEAD,
  GALLERY,
  AUTOCOMPLETE,
  FAVORITE,
  SHARE,
  MAP,
}
