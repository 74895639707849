import { noIndexQueryStringParams } from '@/app/constants'
import { define } from './metadata'

/**
 * @typedef {object} SEOProperties
 * @property {string} [title]
 * @property {string} [description]
 * @property {string} [image]
 * @property {string} [canonicalUrl]
 * @property {string} [robots]
 * @property {object} [query]
 */

/**
 * @param {string} value
 */
export function title (value) {
  return define(({ meta, title: setTitle }) => {
    setTitle(value)
    meta({ name: 'og:title', content: value })
    meta({ name: 'twitter:title', content: value })
  })
}

/**
 * @param {string} value
 */
export function description (value) {
  return define(({ meta }) => {
    meta({ name: 'description', content: value })
    meta({ name: 'og:description', content: value })
    meta({ name: 'twitter:description', content: value })
  })
}

/**
 * @param {string} value
 */
export function image (value) {
  return define(({ meta }) => {
    meta({ name: 'twitter:card', content: 'summary_large_image' })
    meta({ name: 'og:image', content: value })
    meta({ name: 'twitter:image', content: value })
  })
}

/**
 * @param {string} value
 */
export function canonicalUrl (value) {
  return define(({ meta, link }) => {
    link({ rel: 'canonical', href: value })
    meta({ name: 'og:url', content: value })
  })
}

/**
 * @param {string} value
 * @param {{ query: Record<string, unknown> }} properties
 */
export function robots (value, { query }) {
  return define(({ meta }) => {
    if (value) {
      meta({ name: 'robots', content: value })
    } else {
      const queryKeys = Object.keys(query || {})
      const hasNoIndexParameter = queryKeys.some((key) => noIndexQueryStringParams[key])
      const content = hasNoIndexParameter ? 'NOINDEX,NOFOLLOW' : 'INDEX,FOLLOW'

      meta({ name: 'robots', content })
    }
  })
}

/**
 * @param {object} json
 */
export function schema (json) {
  return define(({ script }) => {
    if (json) {
      script({
        type: 'application/ld+json',
        json,
      })
    }
  })
}
