import merge from 'lodash/merge'
import isEmpty from 'lodash/isEmpty'

import humanize from '@/filters/humanize'
import mediasFilter from '@/filters/medias'
import pricingInfoFilter from '@/filters/pricing-info'

const CONCIERGE_WARRANTY = 'GRUPO_ZAP'
const handleTypesForSubTitle = (unitTypes, unitSubTypes, glossary) => {
  if (!isEmpty(unitSubTypes)) {
    return humanize({ glossary, key: 'unitSubType', value: unitSubTypes[0] })
  }
  return humanize({ glossary, key: 'unitType', value: unitTypes[0] })
}

const handlePhoneNumbers = (advertiserContact) => {
  const { phones } = advertiserContact
  return phones.some((phone) => phone.length > 7)
}

/**
 * Validations to check if listing can be ellected to concierge
 * @param {pricingInfo} object listing pricingInfo data
 * @returns Boolean
 */
export const isAllowedConcierge = ({ pricingInfo }) => Boolean(
  pricingInfo
  && pricingInfo.warranties
  && pricingInfo.warranties.some((warranty) => warranty === CONCIERGE_WARRANTY),
)

export const showRentalDatazapStamp = (listing, pricingInfo) => {
  if (isEmpty(listing.priceSuggestion) || !pricingInfo.isRent) {
    return false
  }

  const [usableArea] = listing.usableAreas
  const { max } = listing.priceSuggestion

  return parseInt(pricingInfo?.priceMap?.RENTAL?.rawPrice, 10) < (usableArea * max)
}

// eslint-disable-next-line complexity
export default ({
  listing: listingData,
  medias = [],
  preview = false,
  alternateAddress = undefined,
  listingLinks = [],
  link = {},
  priceHistory = null,
  listingSummary = {},
  businessTypeContext,
  glossary,
}) => {
  const {
    advertiserContact,
    pricingInfos,
    showPrice,
    listingType,
    unitTypes,
    unitSubTypes,
    address,
    status,
    nonActivationReason,
    priceSuggestion,
    listingsCount = 0,
  } = listingData

  const pricingInfo = pricingInfoFilter(
    pricingInfos,
    showPrice,
    undefined, // this must be refact :S
    priceHistory,
  )

  const groupedMedias = mediasFilter.groupMedias(medias)
  const hasAddress = Boolean(address && address.state)
  const isInactive = status === 'INACTIVE'
  const isDefaulterInactive = isInactive && nonActivationReason === 'CONTRACT_NOT_AVAILABLE'
  const showPhoneButton = handlePhoneNumbers(advertiserContact)
  const listingHref = link.href || listingLinks[0]?.href || ''
  const listingLink = listingLinks.length > 0 ? listingLinks[0] : listingData.listingLink

  const listing = {
    ...groupedMedias,
    address: alternateAddress,
    hasAddress,
    isAllowedConcierge: isAllowedConcierge({ pricingInfo }),
    isDevelopment: listingType === 'DEVELOPMENT',
    isInactive,
    isDefaulterInactive,
    pricingInfo,
    subtitle: [
      handleTypesForSubTitle(unitTypes, unitSubTypes, glossary),
      pricingInfo.businessDescription,
      hasAddress ? 'em' : '',
    ].join(' '),
    businessTypeContext: businessTypeContext || pricingInfo.businessType?.split('_')[0],
    preview,
    showPhoneButton,
    link: listingHref,
    listingLink,
    listingSummary,
    priceSuggestion,
    showRentalDatazapStamp: showRentalDatazapStamp(listingData, pricingInfo),
    listingsCount,
  }
  return merge({}, listingData, listing)
}
