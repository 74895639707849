class AbstractHandler {
  constructor () {
    this.nextHandler = null
  }

  setNext (handler) {
    this.nextHandler = handler
    return handler
  }

  handle (portal) {
    if (this.nextHandler) {
      return this.nextHandler.handle(portal)
    }

    return {
      avaliablePartner: false,
      url: null,
    }
  }
}

export default AbstractHandler
