const getMedia = (medias, type) => medias.filter((item) => item.type === type)

const images = (medias) => getMedia(medias, 'IMAGE')
const videos = (medias) => getMedia(medias, 'VIDEO')
const videoTour = (medias) => getMedia(medias, 'VIDEO_TOUR')

const labelMap = {
  IMAGE: 'images',
  VIDEO: 'videos',
  VIDEO_TOUR: 'videoTour',
}

const groupMedias = (medias) => {
  const group = {
    images: [],
    videos: [],
    videoTour: [],
  }

  medias.forEach((media) => {
    const label = labelMap[media.type]
    if (group[label]) {
      group[label].push(media)
    }
  })

  return group
}

export default {
  images,
  videos,
  videoTour,
  groupMedias,
}
