// @ts-check

/**
 * @typedef {object} MetadataScriptTag
 * @property {string} [innerHTML]
 * @property {string} [src]
 * @property {object} [json]
 * @property {string} [type]
 * @property {boolean} [defer]
 */

/**
 * @typedef {object} MetadataMetaTag
 * @property {string} [charset]
 * @property {string} [name]
 * @property {string} [content]
 */

/**
 * @typedef {object} MetadataLinkTag
 * @property {string} [rel]
 * @property {string} [href]
 */

/**
 * @typedef {string} MetadataDangerouslyScript
 */

/**
 * @template T
 * @typedef {(metadata: T) => void} MetadataHelper
 */

/**
 * @typedef {object} MetadataHelpers
 * @property {MetadataHelper<MetadataScriptTag | MetadataDangerouslyScript>} script
 * @property {MetadataHelper<MetadataDangerouslyScript>} noscript
 * @property {MetadataHelper<MetadataMetaTag>} meta
 * @property {MetadataHelper<MetadataLinkTag>} link
 * @property {MetadataHelper<string>} title
 */

/**
 * @typedef {(helpers: MetadataHelpers) => void} MetadataBuilder
 */

/**
 * @param {MetadataBuilder} builder
 */
export function define (builder) {
  return builder
}

/**
 * @template T
 * @typedef {T & { vmid?: string }} WithVmid
 */

/**
 *
 * @param {MetadataBuilder[]} builders
 */
export function build (builders) {
  let counter = 1

  /** @type {string} */
  let title

  const dangerously = {}

  /** @type {WithVmid<MetadataScriptTag>[]} */
  const scripts = []

  /** @type {WithVmid<MetadataLinkTag>[]} */
  const links = []

  /** @type {WithVmid<MetadataScriptTag>[]} */
  const noscripts = []

  /** @type {WithVmid<MetadataMetaTag>[]} */
  const metas = []

  function setTitle (t) {
    title = t
  }

  function uniqueId () {
    // eslint-disable-next-line no-plusplus
    return `metadata-${counter++}`
  }

  /** @type {MetadataHelper<MetadataScriptTag | MetadataDangerouslyScript>} */
  function script (input) {
    if (typeof input === 'string') {
      const id = uniqueId()

      scripts.push({
        vmid: id,
        type: 'text/javascript',
        innerHTML: input,
      })

      dangerously[id] = ['innerHTML']
    } else {
      scripts.push({
        type: 'text/javascript',
        ...input,
      })
    }
  }

  /** @type {MetadataHelper<MetadataDangerouslyScript>} */
  function noscript (input) {
    const id = uniqueId()

    noscripts.push({
      vmid: id,
      type: 'text/javascript',
      innerHTML: input,
    })

    dangerously[id] = ['innerHTML']
  }

  /** @type {MetadataHelper<MetadataMetaTag>} */
  function meta (input) {
    metas.push({
      ...input,
      vmid: input.name,
    })
  }

  /** @type {MetadataHelper<MetadataLinkTag>} */
  function link (input) {
    links.push(input)
  }

  builders.forEach((builder) => builder({
    script,
    noscript,
    meta,
    link,
    title: setTitle,
  }))

  return {
    title,
    script: scripts,
    noscript: noscripts,
    meta: metas,
    link: links,
    __dangerouslyDisableSanitizersByTagID: dangerously,
  }
}
