// @ts-check

import defineSelectorsOf from '../core/define-selectors-of'

/** @type {SelectorFactory<ContextState>} */
const defineSelector = defineSelectorsOf('context')

export const getKnownExperimentsVariations = defineSelector((state) => state?.variationtest ?? {})

export const getGlossary = defineSelector(({ glossary }) => glossary)

/**
 * @param {string} name
 * @returns {Selector<boolean>} */
export function isFeatureEnabled (name) {
  return defineSelector(({ featureToggle = {} }) => featureToggle[name] ?? false)
}

/**
 * @param {string} name
 * @returns {Selector<string>}
 */
export function getEnv (name) {
  return defineSelector(({ config = {} }) => config[name])
}
