import { addressTransform, normalizedAddressTransform } from '@/utils/tracking/address'
// TODO: Move this constants to the correct folder
import { BUSINESS_TYPE_MAP } from '@/filters/business-type'

const getNumberArray = (array) => (array.map((item) => Number(item)).filter((i) => i))

const mountPriceTrackingData = (pricingInfos) => {
  const priceGroups = {
    businessTypes: [],
    salePrices: [],
    rentalPrices: [],
    condoFees: new Set(),
    iptuPrices: new Set(),
  }

  pricingInfos.forEach((priceInfo) => {
    const {
      businessType,
      price,
      monthlyCondoFee,
      yearlyIptu,
    } = priceInfo

    const businessPrice = businessType === BUSINESS_TYPE_MAP.SALE ? 'salePrices' : 'rentalPrices'

    const priceNumber = Number(price)
    const monthlyCondoFeeNumber = Number(monthlyCondoFee)
    const yearlyIptuNumber = Number(yearlyIptu)

    priceGroups.businessTypes.push(businessType)

    if (priceNumber) {
      priceGroups[businessPrice].push(priceNumber)
    }

    if (monthlyCondoFeeNumber) {
      priceGroups.condoFees.add(monthlyCondoFeeNumber)
    }

    if (yearlyIptuNumber) {
      priceGroups.iptuPrices.add(yearlyIptuNumber)
    }
  })

  return {
    ...priceGroups,
    condoFees: [...priceGroups.condoFees],
    iptuPrices: [...priceGroups.iptuPrices],
  }
}

const pageViewBase = (data) => {
  const { listing = {}, publisher: { legacyZapId: legacyAdvertiserId } = {} } = data

  const {
    id: listingId,
    listingType,
    unitTypes,
    pricingInfos = [],
    constructionStatus = '',
    usableAreas: areas,
    parkingSpaces,
    bathrooms,
    bedrooms,
    status,
    suites,
    amenities,
    advertiserId = '',
  } = listing || {}

  const address = addressTransform(listing.address || {})
  const normalizedAddress = normalizedAddressTransform(listing.address || {})
  const priceTrackingData = mountPriceTrackingData(pricingInfos)
  const normalizedAreas = getNumberArray(areas)

  const {
    percentage: priceVariation,
    basePrice: listingHighestPrice,
  } = listing.pricingInfo.priceVariation || {}

  return Object.freeze({
    ...priceTrackingData,
    listingId,
    listingType,
    unitTypes,
    address,
    constructionStatus,
    areas: normalizedAreas,
    parkingSpaces,
    bathrooms,
    bedrooms,
    status,
    suites,
    amenities,
    advertiserId,
    legacyAdvertiserId: legacyAdvertiserId.toString(),
    normalizedAddress,
    priceVariation,
    listingHighestPrice,
  })
}

export default pageViewBase
