// @ts-check
import { getTrackingParams } from '@/store/experimentations/selectors'
import defineSelector from '@/store/core/define-selector'
import { getAuthInfo } from '@/store/auth/selector'
import isNil from 'lodash/isNil'

/**
 * @typedef {{
 *  pageName: string,
 *  pageType: string,
 *  previousPageName?: string,
 * }} PageInfo
 */

/**
 * @typedef StaticTrackingMeta
 * @property {string} type
 * @property {string} [name] If undefined, it'll be generated from type
 * @property {any} [getName]
 * @property {string} [campaign]
 * @property {Selector<object> | object} [pageInfo]
 * @property {Selector<object> | object} [adInfo]
 * @property {Selector<object> | object} [sellerInfo]
 */

/**
 * @typedef {(route: import('vue-router').Route) => StaticTrackingMeta} DynamicTrackingMeta
 */

/**
 * @typedef {StaticTrackingMeta | DynamicTrackingMeta} TrackingMeta
 */

/**
 * @typedef {{
 *  pageInfo: PageInfo
 * }} OnPageViewEvent
 */

/**
 * @param {string | StaticTrackingMeta | DynamicTrackingMeta} meta
 * @returns {TrackingMeta}
 */
export function tracking (meta) {
  return typeof meta === 'string' ? { type: meta } : meta
}

/**
 * @param {import('vue-router').Route} route
 * @returns {StaticTrackingMeta}
 */
function metadata (route) {
  /** @type {TrackingMeta} */
  const stored = route.meta.tracking

  let meta = typeof stored === 'function' ? stored(route) : stored

  if (isNil(meta)) {
    meta = {
      name: 'unknown',
      type: 'unknown',
    }
  }

  if (!meta.name) {
    meta.name = `/zapimoveis/${meta.type}`
  }

  return meta
}

/**
 * @param {{
 *  version?: string
 *  referrer?: string
 * }} options
 */
export function makeSiteInfo ({ version, referrer }) {
  const {
    location: {
      pathname, hostname, protocol, hash, search, href,
    },
  } = document

  const siteInfo = {
    hostname,
    pathname,
    protocol,
    search,
    hash,
    href,
    referrer,
    version,
    division: 'portal',
  }

  return siteInfo
}

const PAGE_INFO = {
  costumerType: 'b2c',
  brand: 'zapimoveis',
  brandId: '1',
  subBrand: undefined,
  subBrandId: undefined,
  segment: 'real-estate',
}

/**
 * @template T
 * @param {T | Selector<T>} selector
 * @returns {selector is Selector<T>} */
function isSelector (selector) {
  return typeof selector === 'function'
}

/**
 * @param {SelectorExecutor} select
 * @returns {<T>(selector: T | Selector<T>) => T}
 */
function makeGetter (select) {
  /**
     * @template T
     * @param {T | Selector<T> | undefined} selector
     * @returns {T | undefined}
     */
  function run (selector) {
    if (!selector) {
      return undefined
    }

    if (isSelector(selector)) {
      return select(selector)
    }

    return selector
  }

  return run
}

function getPreviousPageName (previous, get) {
  const meta = previous && metadata(previous)

  if (meta) {
    if (meta.getName) return get(meta.getName)
    return meta.name
  }

  return null
}

const getUserInfo = defineSelector((_, select) => {
  const {
    userId,
    anonymousId,
    sessionId,
    ...params
  } = select(getAuthInfo)

  return ({
    ...params,
    user_id: userId,
    anonymous_id: anonymousId || 'unknown',
    session_id: sessionId,
  })
})

/**
 * @param {import('vue-router').Route} route
 * @param {{
 *  previous?: import('vue-router').Route
 * }} options
 * @returns {Selector<import('@/router/tracking').OnPageViewEvent>}
 */
export function onPageView (route, { previous } = {}) {
  return defineSelector((state, select) => {
    const meta = metadata(route)
    const get = makeGetter(select)

    const {
      type, name, getName, pageInfo, adInfo, sellerInfo, campaign,
    } = meta ?? {}

    const page = get(pageInfo)
    const previousPath = previous?.fullPath
    const pageName = getName ? get(getName) : name
    const previousPageName = getPreviousPageName(previous, get)

    // it fixes the `document.referrer` issue on SPAs
    const referrer = previousPath && new URL(previousPath, document.location.origin).href

    return {
      event: 'customPageView',
      pageInfo: {
        ...PAGE_INFO,
        pageName,
        pageType: type,
        previousPageName,
        feirao: campaign,
        ...page,
      },
      adInfo: get(adInfo),
      sellerInfo: get(sellerInfo),
      siteInfo: makeSiteInfo({ version: __VERSION__, referrer }),
      userInfo: select(getUserInfo),

      // it's needed for experiments tracking
      ...select(getTrackingParams),
      // it's needed for clickstream tracking integration
      spa_referrer: referrer ?? document.referrer,
    }
  })
}

