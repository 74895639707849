// @ts-check

import Vue from 'vue'
import Router from 'vue-router'
import { tracking } from '@/router/tracking'
import { getNamePage } from '@/store/official-store/selectors'
import { getResultsPageInfo } from '@/store/results/selectors'
import { getListingAdInfo, getListingPageInfo, getListingSellerInfo } from '@/store/listing/selectors'

const Condominium = () => import(/* webpackChunkName: "condominium" */ '@/pages/condominium')
const Home = () => import(/* webpackChunkName: "home" */ '@/pages/home')
const BuyingPower = () => import(/* webpackChunkName: "buying-power" */ '@/pages/buying-power')
const NeighborhoodGuide = () => import(/* webpackChunkName: "neighborhood-guide" */ '@/pages/neighborhood-guide')
const Listing = () => import(/* webpackChunkName: "listing" */ '@/pages/listing')
const Results = () => import(/* webpackChunkName: "results" */ '@/pages/results')
const Advertisers = () => import(/* webpackChunkName: "publishers" */ '@/pages/advertisers')
const Markdown = () => import(/* webpackChunkName: "markdown" */ '@/pages/markdown')
const SalesforceFooter = () => import(/* webpackChunkName: "salesforce-footer" */ '@/components/salesforce-footer')
const Discover = () => import(/* webpackChunkName: "discover" */ '@/pages/discover')
const Links = () => import(/* webpackChunkName: "links" */ '@/pages/links')

const Privacy = () => import(/* webpackChunkName: "privacy" */ '@/pages/info/privacy')
const Quality = () => import(/* webpackChunkName: "quality" */ '@/pages/info/quality')
const Terms = () => import(/* webpackChunkName: "terms" */ '@/pages/info/terms')
const LegalTerms = () => import(/* webpackChunkName: "terms" */ '@/pages/info/legal-terms')
const Cookies = () => import(/* webpackChunkName: "cookies" */ '@/pages/info/cookies')
const HttpError = () => import(/* webpackChunkName: "serverError" */'@/pages/http-error')

const createRouter = () => {
  Vue.use(Router)

  return new Router({
    mode: 'history',

    routes: [
      {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
          tracking: tracking('homepage'),
        },
      },
      {
        path: '/condominio/(.*)',
        name: 'condominium',
        component: Condominium,
        meta: {
          tracking: tracking('condominio'),
        },
      },
      {
        path: '/descobrir/(.*)',
        name: 'discover',
        component: Discover,
        meta: {
          tracking: tracking({
            type: 'descobrir',
            pageInfo: { flowType: 'descobrir' },
          }),
        },
      },
      {
        path: '/(imovel|imoveis-lancamento|oferta|superdestaque|alugafacil|lancamento)/:listing+',
        name: 'listing',
        component: Listing,
        meta: {
          tracking: tracking({
            type: 'pagina-produto',
            pageInfo: getListingPageInfo,
            adInfo: getListingAdInfo,
            sellerInfo: getListingSellerInfo,
          }),
        },
      },
      {
        path: '/(imobiliaria|venda|aluguel|lancamentos|feirao)/(.*)',
        name: 'results',
        component: Results,
        meta: {
          tracking: tracking((route) => {
            let getName
            let campaign
            let type = 'resultado-pesquisa'
            let name = '/zapimoveis/resultado-pesquisa'

            if (route.path.startsWith('/feirao')) {
              campaign = route.path.replace(/^\/feirao\//g, '').replace(/\/$/g, '')
              name += '/feirao'
            } else if (route.path.startsWith('/imobiliaria')) {
              getName = getNamePage
              type = 'imobiliaria'
            }

            return ({
              name,
              type,
              getName,
              campaign,
              pageInfo: getResultsPageInfo,
            })
          }),
        },
      },
      {
        path: '/(imobiliarias-e-corretores)/(.*)',
        name: 'advertisers',
        component: Advertisers,
      },
      {
        path: '/(comprar|alugar)(-imovel-barato)/:city?',
        name: 'markdown-base',
        component: Links,
        props: () => ({ widgetCategory: 'MARKDOWN_PAGE_LINK' }),
      },
      {
        path: '/(comprar|alugar)(-imovel-barato)/:city/(.*)',
        name: 'markdown',
        component: Markdown,
      },
      {
        path: '/salesforce-footer',
        name: 'salesforce',
        component: SalesforceFooter,
      },
      {
        path: '/informacao/',
        redirect: '/',
      },
      {
        path: '/informacao/politica-de-privacidade',
        name: 'privacy',
        component: Privacy,
      },
      {
        path: '/informacao/politica-de-qualidade',
        name: 'quality',
        component: Quality,
      },
      {
        path: '/informacao/termos-de-uso',
        name: 'terms',
        component: Terms,
      },
      {
        path: '/informacao/termos-anunciante',
        name: 'legal-terms',
        component: LegalTerms,
      },
      {
        path: '/informacao/politica-de-cookies',
        name: 'cookies',
        component: Cookies,
      },
      {
        path: '/guia-de-informacoes/guia-de-bairros/:neighborhood',
        name: 'neighborhood-guide',
        component: NeighborhoodGuide,
      },
      {
        path: '/mapa-do-site/:businessType?/:city?',
        component: Links,
        props: () => ({ widgetCategory: 'SITEMAP_LINK' }),
      },
      {
        path: '/http-error',
        name: 'httpError',
        component: HttpError,
        meta: {
          tracking: tracking((route) => {
            const code = route.hash.replace(/^#/, '')
            return {
              type: 'erro',
              name: code ? `/zapimoveis/erro/${code}` : '/zapimoveis/erro',
            }
          }),
        },
      },
      {
        path: '/calculadora-poder-compra',
        name: 'buying-power',
        component: BuyingPower,
        meta: {
          tracking: tracking({
            name: '/zapimoveis/financiamento/calculadora-potencial',
            type: 'financiamento-calculadora-potencial',
          }),
        },
      },
    ],

    scrollBehavior (to, from, savedPosition) {
      if (to.name === 'results' && to.params.pathMatch !== 'imobiliaria' && from.name === 'listing') {
        return savedPosition
      }

      return { x: 0, y: 0 }
    },
  })
}

export default createRouter
