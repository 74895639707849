import isObject from 'lodash/isObject'
import { normalizedAddressTransform } from '@/utils/tracking/address'
import { useSessionId } from '@vivareal/tracking'

const CLICKSTREM_DATA_KEY = 'V_CLICKSTREAM_DATA'
const GZUID_KEY = 'GZUID'
const CONCIERGE_SURVEY_KEY = 'CONCIERGE_SURVEY'
const Z_SEEN_LISTINGS = 'Z_SEEN_LISTINGS'
const FAVORITE_LISTING_STATE = 'FAVORITE_LISTING_STATE'
const SIMILAR_ALERT_STATE = 'SIMILAR_ALERT_STATE'
const SAVED_SEARCH_STATE = 'SAVED_SEARCH_STATE'
const REDIRECT_ACTION = 'REDIRECT_ACTION'
const SOCIAL_CONTEXT_KEY = 'social_context'
const REFERER_KEY = 'referer'
const TRIG_KEY = 'trig'
const DEDUP_LISTING_DATA = 'DEDUP_LISTING_DATA'

const objectValuesToArray = (fields) => Object.keys(fields)
  .filter((subKey) => fields[subKey])
  .map((subKey) => fields[subKey])

const arrayValuesToString = (fields) => fields.map((subFields) => {
  const formatedFields = isObject(subFields)
    ? objectValuesToArray(subFields)
    : subFields
  return formatedFields
}).join(',')

const baseStorage = {}

export default {
  storage: typeof window === 'undefined' ? false : window.localStorage,

  get (key, fallback = '{}', parse = true) {
    if (!this.storage) return ''

    let data = baseStorage[key]
    if (!data) {
      const stored = this.storage.getItem(key) || fallback
      data = parse ? JSON.parse(stored) : stored
    }
    return data
  },

  set (key, rawData, stringify = true) {
    if (!this.storage) return

    const data = stringify ? JSON.stringify(rawData) : rawData
    baseStorage[key] = rawData
    this.storage.setItem(key, data)
  },

  getBusinessTypeContext () {
    const { businessTypeContext } = this.get(CLICKSTREM_DATA_KEY)
    return businessTypeContext
  },

  setGzuid (gzuid) {
    return this.set(GZUID_KEY, gzuid, false)
  },

  getGzuid () {
    return this.get(GZUID_KEY, undefined, false)
  },

  setSocialContext (value) {
    return this.set(SOCIAL_CONTEXT_KEY, value, false)
  },

  getSocialContext () {
    return this.get(SOCIAL_CONTEXT_KEY, '', false)
  },

  cleanSocialContext () {
    this.abstractCleanState(SOCIAL_CONTEXT_KEY)
  },

  setReferer (value) {
    return this.set(REFERER_KEY, value, false)
  },

  getReferer () {
    return this.get(REFERER_KEY, '', false)
  },

  cleanReferer () {
    this.abstractCleanState(REFERER_KEY)
  },

  setTrig (value) {
    return this.set(TRIG_KEY, value, false)
  },

  getTrig () {
    return this.get(TRIG_KEY, '', false)
  },

  cleanTrig () {
    this.abstractCleanState(TRIG_KEY)
  },

  setConciergeSurvey (survey) {
    return this.set(CONCIERGE_SURVEY_KEY, survey)
  },

  getConciergeSurvey () {
    return this.get(CONCIERGE_SURVEY_KEY)
  },

  getSessionId () {
    return useSessionId()
  },

  setShowcaseId ({ recommender, version }, screen) {
    const showcaseId = [recommender, version, screen]
      .filter((item) => !!item).join('_')

    const { details, ...source } = this.getSourceData()

    this.set(CLICKSTREM_DATA_KEY, {
      ...source,
      source: 'SHOWCASE',
      id: showcaseId,
    })
  },

  setRankingId (filters) {
    const { business: businessTypeContext } = filters
    const keys = Object.keys(filters).sort()

    const rankingId = keys.map((key) => {
      const fields = filters[key]
      let formatedFields = fields
      if (Array.isArray(fields)) {
        if (key === 'addresses') {
          const addressesFields = normalizedAddressTransform(fields[0])
          formatedFields = objectValuesToArray(addressesFields).join(',')
        } else {
          formatedFields = arrayValuesToString(fields)
        }
      } else if (isObject(fields)) {
        formatedFields = objectValuesToArray(fields).join(',')
      }

      return `${key}=${formatedFields}`
    }).join('&')

    const { details, ...source } = this.getSourceData()
    this.set(CLICKSTREM_DATA_KEY, {
      ...source,
      source: 'RANKING',
      id: rankingId,
      businessTypeContext,
    })
  },

  setCollectionId ({ lista: collectionId }) {
    const { details, ...source } = this.getSourceData()
    this.set(CLICKSTREM_DATA_KEY, {
      ...source,
      source: 'FAVORITES',
      id: collectionId,
    })
  },

  setDeduplicationDetails () {
    this.set(CLICKSTREM_DATA_KEY, {
      ...this.getSourceData(),
      source: 'RANKING',
      id: undefined,
      details: 'LISTING DETAIL PAGE DEDUPLICATION MODAL',
    })
  },

  getSourceData () {
    return this.get(CLICKSTREM_DATA_KEY)
  },

  getSource () {
    const data = this.getSourceData()

    const { source: type = '' } = data
    const { details = '', id = '' } = data

    return { type, id, ...(details && { details }) }
  },

  setSeenListings (seenListing) {
    const getAllSeenListings = this.getSeenListings()
    let newSeenListings

    if (Array.isArray(getAllSeenListings)) {
      const newList = new Set(getAllSeenListings)
      newList.add(seenListing)

      newSeenListings = [...newList]
    } else {
      newSeenListings = [seenListing]
    }

    return this.set(Z_SEEN_LISTINGS, newSeenListings)
  },

  getSeenListings () {
    return this.get(Z_SEEN_LISTINGS)
  },

  abstractSetState (key, params) {
    if (!params || typeof params !== 'object') {
      throw new Error('The "params" must be a valid Object')
    }
    this.set(key, params)
  },

  abstractCleanState (key) {
    if (baseStorage[key]) {
      delete baseStorage[key]
    }

    if (this.storage.getItem(key)) {
      this.storage.removeItem(key)
    }
  },

  setFavoriteState (params) {
    this.abstractSetState(FAVORITE_LISTING_STATE, params)
  },

  getFavoriteState () {
    return this.get(FAVORITE_LISTING_STATE)
  },

  cleanFavoriteState () {
    this.abstractCleanState(FAVORITE_LISTING_STATE)
  },

  setSimilarAlertState (open = true) {
    this.set(SIMILAR_ALERT_STATE, {
      data: { open },
    })
  },

  getSimilarAlertState () {
    return this.get(SIMILAR_ALERT_STATE)
  },

  cleanSimilarAlertState () {
    this.abstractCleanState(SIMILAR_ALERT_STATE)
  },

  setSavedSearchState (data) {
    this.abstractSetState(SAVED_SEARCH_STATE, data)
  },

  getSavedSearchState () {
    return this.get(SAVED_SEARCH_STATE)
  },

  cleanSavedSearchState () {
    this.abstractCleanState(SAVED_SEARCH_STATE)
  },

  setRedirectAction (data) {
    this.set(REDIRECT_ACTION, data, false)
  },

  getRedirectAction () {
    return this.get(REDIRECT_ACTION, '', false)
  },

  setDedupListingData (data) {
    this.set(DEDUP_LISTING_DATA, data)
  },

  getDedupListingData () {
    return this.get(DEDUP_LISTING_DATA, null, true)
  },

  getBaseStorage () {
    return baseStorage
  },

}
