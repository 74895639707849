import get from 'lodash/get'
import { getUF } from '@/filters/address'

const humanize = ({
  glossary, key, value, isSingular = true, isState,
}) => {
  if (isState) return getUF(value, glossary)

  const type = value ? `items.${key}.items.${value}` : `items.${key}.items`

  if (!value) return get(glossary, type)
  if (isSingular) return get(glossary, `${type}.singular`)
  return get(glossary, `${type}.plural`)
}

export default humanize
