import { finCalcPotencialSucesso } from '@/pages/buying-power/buying-power.tracker'

const setIncome = (state, income) => {
  state.income = income
}

const setDownPayment = (state, downPayment) => {
  state.downPayment = downPayment
}

const calculateBuyingPower = (state) => {
  const juros = 0.0050
  const mip = 0.000001046125093
  const dfi = 0.00006875
  const prazo = 360
  const tarifa = 25

  const income = state.income ? parseFloat(state.income.replaceAll('.', '').replace(',', '.')) : 0
  const downPayment = state.downPayment ? parseFloat(state.downPayment.replaceAll('.', '').replace(',', '.')) : 0

  if (income && downPayment) {
    const financialValue = ((income * 0.3 - tarifa) / (1 / prazo + juros + mip + dfi))
    const downPaymentMin = (financialValue / 4)
    const buyingPowerValue = downPayment > downPaymentMin
      ? financialValue + downPayment
      : downPayment * 5
    state.buyingPowerData = {
      downPayment,
      downPaymentMin,
      buyingPowerValue,
      itbi: buyingPowerValue * 0.05,
      financialValue: downPayment > downPaymentMin ? financialValue : downPayment * 4,
    }
    finCalcPotencialSucesso()
  } else {
    state.buyingPowerData = null
  }
}

export default {
  setIncome,
  setDownPayment,
  calculateBuyingPower,
}
