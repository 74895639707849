const LEAD_SCREEN = {
  MODAL_FORM: 'MODAL FORM',
  INACTIVE_LEAD_FORM: 'INACTIVE LEAD FORM',
  VISIBLE_FORM: 'VISIBLE FORM',
  LDP_DESCRIPTION: 'DESCRIPTION',
  PHONE_ACCEPTED_TERMS: 'ONE CLICK TO LEAD BUTTON',
  WPP_ACCEPTED_TERMS: 'ONE CLICK TO LEAD BUTTON',
  WPP_NO_ACCEPTED_TERMS: 'WHATSAPP MODAL FORM',
  WPP_ACCEPTED_TERMS_VISIBLE_FORM: 'VISIBLE FORM',
  DEFAULTER_ADVERTISER_FORM: 'DEFAULTER ADVERTISER FORM',
  SHOWCASE: 'SHOWCASE',
}

export default LEAD_SCREEN
