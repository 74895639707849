import mapValues from 'lodash/mapValues'
import pick from '@/utils/transformers/pick'
import isEmpty from 'lodash/isEmpty'
import kebabCase from 'lodash/kebabCase'
import { remove as replaceDiacritics } from 'diacritics'
import { snakeCaseKeys } from '@/utils/transform-case'

const ALLOWED_ADDRESS_FIELDS = [
  'country',
  'state',
  'city',
  'value_zone',
  'zone',
  'neighborhood',
  'street',
  'street_number',
]

const EMPTY_ADDRESS = Object.freeze({
  country: 'BR',
  state: '',
  city: '',
  value_zone: '',
  zone: '',
  neighborhood: '',
  street: '',
  street_number: '',
})

// falsy values to string
const stringValue = (value) => (value ? value.toString() : '')

// Normalize address with kebab case values and with no
// special chars, e.g.: "São Paulo" -> "sao-paulo"
const normalizedKebabValue = (value) => kebabCase(replaceDiacritics(stringValue(value)))

// transform fields with snake case keys, for allowed fields
const transformFields = (address, valueHandler) => {
  // pointLat and pointLon are not used in tracking objects
  const { pointLat, pointLon, ...addressData } = address

  return pick(
    snakeCaseKeys({
      ...mapValues(addressData, valueHandler),
    }),
    ALLOWED_ADDRESS_FIELDS,
  )
}

const transform = (address, valueHandler) => {
  if (isEmpty(address)) {
    return transformFields(EMPTY_ADDRESS, valueHandler)
  }

  return transformFields(address, valueHandler)
}

const addressTransform = (address) => transform(address, stringValue)

const normalizedAddressTransform = (address) => transform(address, normalizedKebabValue)

export {
  stringValue,
  normalizedKebabValue,
  addressTransform,
  normalizedAddressTransform,
}
