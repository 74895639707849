const buildLeadData = ({
  listing, publisher, moreInfoOptIn = false, anonymousId,
}) => {
  const {
    id: listingId,
    listingType,
    externalId: listingExternalId,
    advertiserId: publisherId,
    legacyId: legacyListingId,
  } = listing

  const {
    legacyZapId: legacyPublisherId,
  } = publisher

  const leadData = {
    params: {
      category: listingType,
      fingerprint: anonymousId,
      id: listingId,
    },
    listingId,
    listingExternalId,
    publisherId,
    legacyPublisherId,
    legacyListingId,
    moreInfoOptIn,
  }

  return leadData
}

export default { buildLeadData }
