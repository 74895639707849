import Vue from 'vue'

const buildLeadPanelClickedEvent = ({
  action = 'OPEN',
  subject,
  listingId,
  senseOfUrgency,
  screen = '',
}) => ({
  leadPanel: {
    action,
    subject,
    listingId,
    senseOfUrgency,
    screen,
  },
  event: 'leadPanelClicked',
})

export default {
  leadPanelClicked (data) {
    const event = buildLeadPanelClickedEvent(data)
    Vue.$clickstream.trackEvent(event)
  },
}

export { buildLeadPanelClickedEvent }
