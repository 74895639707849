/* eslint-disable */
// this is a copy from moment-from-now :D

// 146097 days in 400 years (moment.js:daysToMonths()):
const DAYS_PER_MONTH = 146097 / 400 / 12

const first = (arr, func) => {
  for (let i = 0; i < arr.length; ++i)
    if (func(arr[i])) return arr[i]
}

const thresh = (t, val) => {
  val = Math.round(val)
  return val < t ? val : 0
}

const threshNoTreatment = (val) => {
  val = Math.round(val)
  return val
}

const humanize = (duration) => {
  let ago = duration < 0
  duration = Math.abs(duration)
  duration = [
    { n: thresh(45, duration / 1000), units: 'segundos', unit: 'segundo' },
    { n: thresh(45, duration / (60 * 1000)), units: 'minutos', unit: 'minuto' },
    { n: thresh(22, duration / (60 * 60 * 1000)), units: 'horas', unit: 'hora' },
    { n: thresh(6, duration / (24 * 60 * 60 * 1000)), units: 'dias', unit: 'dia' },
    { n: thresh(4, duration / (6 * 24 * 60 * 60 * 1000)), units: 'semanas', unit: 'semana' },
    { n: thresh(11, duration / (DAYS_PER_MONTH * 24 * 60 * 60 * 1000)), units: 'meses', unit: 'mês' },
    { n: thresh(Number.MAX_VALUE, duration / (365 * 24 * 60 * 60 * 1000)), units: 'anos', unit: 'ano' },
    { n: 'poucos segundos', units: '' },
  ]
  duration = first(duration, part => part.n !== 0)
  ago = ago && duration.n != 'now'
  if (duration.n == 1) duration.units = duration.unit
  duration = duration ? `${duration.n} ${duration.units}` : 'agora'
  return duration.trim()
}

const fromNowPerMonth = (date) => {
    if (!(date instanceof Date)) date = new Date(date)
    const duration = Math.abs(date.getTime() - Date.now())
    return threshNoTreatment(duration / (DAYS_PER_MONTH * 24 * 60 * 60 * 1000))-1
}

const pastMonthsDate = (dateParam, minusMonths) => {
  const date = new Date(dateParam)
  date.setMonth(date.getMonth() - minusMonths)
  return date.toISOString()
}

export default { pastMonthsDate }
export { fromNowPerMonth }
