const RENTAL_TYPES = [
  {
    text: 'Diária',
    value: 'DAILY',
  },
  {
    text: 'Semanal',
    value: 'WEEKLY',
  },
  {
    text: 'Mensal',
    value: 'MONTHLY',
  },
  {
    text: 'Anual',
    value: 'YEARLY',
  },
]

export default RENTAL_TYPES
