export const UNIT_TYPES_URL = {
  APARTMENT: 'apartamento_residencial',
  KITNET: 'kitnet_residencial',
  UnitType_NONE: 'studio_residencial', // verificar o porque não esta vindo como a string studio
  HOME: 'casa_residencial',
  CONDOMINIUM: 'condominio_residencial',
  VILLAGE_HOUSE: 'casa-vila_residencial',
  PENTHOUSE: 'cobertura_residencial',
  FLAT: 'flat_residencial',
  LOFT: 'loft_residencial',
  RESIDENTIAL_ALLOTMENT_LAND: 'lote-terreno_residencial',
  FARM: 'granja_residencial',
  BUSINESS: 'ponto-comercial_comercial',
  OFFICE: 'sala_comercial',
  COMMERCIAL_PROPERTY: 'imovel-comercial_comercial',
  HOTEL: 'hotel-motel-pousada_comercial',
  FLOOR: 'andar-loja-corporativa_comercial',
  COMMERCIAL_BUILDING: 'predio_comercial',
  COMMERCIAL_ALLOTMENT_LAND: 'lote-terreno_comercial',
  SHED_DEPOSIT_WAREHOUSE: 'galpao_comercial',
  PARKING_SPACE: 'garagem_comercial',
}
