// @ts-check

import aldo from '@/api/lead'
import { getEnv, isFeatureEnabled } from '../context/selectors'
import { useSelect } from './use-select'
import { getAnonymousId } from '../auth/selector'
import { LEADS, LEAD_TYPE } from '@/utils/constants'
import leads from '@/utils/leads'
import defineSelector from './define-selector'
import { getPlatform } from '../device'
import storage from '@/storage/local-storage'
import cuid from 'cuid'

const getOriginType = defineSelector((_, select) => {
  const useMSite = select(isFeatureEnabled('verification_is_msite'))
  return useMSite && select(getPlatform) === 'MOBILE' ? 'MSITE' : 'WEBSITE'
})

const phoneNumberMinValue = 10000000
const phoneNumberMaxValue = 999999999
const dddMinValue = 10
const dddMaxValue = 99

/**
 * @param {number} ddd
 * @returns {boolean}
 */
function isValidDDD (ddd) {
  return ddd > dddMinValue && ddd < dddMaxValue
}

/**
 * @param {number} number
 * @returns {boolean}
 */
function isValidNumber (number) {
  return number > phoneNumberMinValue && number < phoneNumberMaxValue
}

const parsePhoneV3 = (value = '') => {
  const digitsOnly = value.replace(/\D/g, '')

  const ddd = Number(digitsOnly.substring(0, 2))
  const number = Number(digitsOnly.substring(2, digitsOnly.length))

  if (!isValidDDD(ddd) || !isValidNumber(number)) return null

  return {
    ddd,
    number,
  }
}

/**
 * @param {boolean | undefined} reUse
 * @returns {import('@/api/lead').LeadReuseTermsOptIn}
 */
function encodeLeadReuseTermsOptIn (reUse) {
  return { accepted: reUse ?? false }
}

/**
 * @param {import('@/utils/constants/leads').LeadType} type
 * @param {{ aliasesEnabled: boolean }} options
 * @returns {string}
 */
// eslint-disable-next-line complexity
function encodeLeadType (type, { aliasesEnabled }) {
  switch (type) {
    case LEAD_TYPE.PHONE:
      return LEADS.TYPE_PHONE_VALUE
    case LEAD_TYPE.CHAT:
      return 'CHAT_VIEW'
    case LEAD_TYPE.WHATSAPP:
      return aliasesEnabled ? 'CLICK_WHATSAPP' : 'CONTACT_FORM'
    case LEAD_TYPE.TOUR:
      return aliasesEnabled ? 'CLICK_SCHEDULE' : LEADS.TYPE_FORM_VALUE
    case LEAD_TYPE.EMAIL:
    default:
      return LEADS.TYPE_FORM_VALUE
  }
}

/**
 * @param {{ legal?: string }} input
 * @returns { import('@/api/lead').PrivacyTermsOptIn }
 */
function encodePrivacyTermsOptIn ({ legal }) {
  return {
    accepted: true,
    version: legal ?? 'v2',
  }
}

/**
 * @param {string} businessType
 * @returns {string}
 */
function encodeTransactionType (businessType) {
  let transactionType = businessType

  if (businessType.includes('_')) {
    transactionType = storage.getBusinessTypeContext() || businessType.split('_')[0]
  }

  switch (transactionType) {
    case 'RENTAL':
    case 'RENT':
      return 'RENT'
    case 'SALE':
    case 'SELL':
    default:
      return 'SELL'
  }
}

/**
 * @template {import('./use-aldo').LeadBase} T
 * @param {T} input
 * @param {{ aliasesEnabled: boolean, originType: string, businessType: string }} options
 * @returns {import('@/api/lead').LeadPayloadBase}
 */
function handleBaseParams (
  {
    phoneNumber, type, reUse, legal, ...base
  },
  { originType, businessType, ...options },
) {
  return {
    ...base,
    phone: parsePhoneV3(phoneNumber) ?? undefined,
    leadType: encodeLeadType(type, options),
    leadReuseTermsOptIn: encodeLeadReuseTermsOptIn(reUse),
    transactionType: encodeTransactionType(businessType),
    privacyTermsOptIn: encodePrivacyTermsOptIn({ legal }),
    phoneNumber,
    originType,
    origin: 'Zap',
  }
}

/**
 * @param {import('vuex').ActionContext<any, any>} store
 * @param {import('./use-aldo').LeadInput} input
 */
function encodeLeadPayload (store, input) {
  const select = useSelect(store)
  const {
    listing, publisher, legal, ...lead
  } = input

  const anonymousId = select(getAnonymousId)
  const contract = leads.buildLeadData({ listing, publisher, anonymousId })

  return {
    ...contract,
    ...handleBaseParams(lead, {
      originType: select(getOriginType),
      businessType: listing.pricingInfo?.businessType ?? '',
      aliasesEnabled: select(isFeatureEnabled('verification_is_msite')),
    }),
  }
}

/**
 * @param {any} response
 * @param {Partial<Omit<import('./use-aldo').Lead, 'id'>>} payload
 * @returns {import('./use-aldo').Lead}
 */
function encodeLead (response, payload) {
  return {
    id: response['lead-id'] ?? cuid(),
    email: response.email || payload.email,
    name: response.name || payload.name,
    phoneNumber: response.phoneNumber || payload.phoneNumber,
    acceptTerms: response.acceptTerms || payload.acceptTerms,
  }
}

/** @type {import('./use-aldo').UseAldo} */
export default function useAldo (context) {
  const select = useSelect(context)

  return {
    async send (input) {
      const response = await aldo.send(select(getEnv('aldoApiEndpoint')), encodeLeadPayload(context, input))
      return encodeLead(response, input)
    },
    async batch ({ businessType, listings, ...input }) {
      const base = handleBaseParams(input, {
        originType: select(getOriginType),
        businessType,
        aliasesEnabled: select(isFeatureEnabled('verification_is_msite')),
      })

      const response = await aldo.batch({
        ...base,
        listingIdList: listings.map(({ id }) => id),
      }, {
        endpoint: select(getEnv('aldoApiEndpoint')),
        fingerprint: select(getAnonymousId),
      })

      return encodeLead(response, base)
    },
  }
}
