const setLinks = (state, links) => {
  Object.assign(state, { links })
}

const setFilters = (state, filters) => {
  Object.assign(state, { filters })
}

const setPagination = (state, pagination) => {
  Object.assign(state, { pagination })
}

const paginate = (state, page) => {
  Object.assign(state.pagination, { currentPage: page })
}

export default {
  setLinks,
  setFilters,
  setPagination,
  paginate,
}
