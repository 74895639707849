import { icArrowSmallUp, icArrowSmallDown } from '@vivareal/lina-icons'

export default {
  name: 'oz-dropdown-menu',

  props: {
    menuItem: { type: Object },
  },

  data () {
    return {
      isToggled: false,
    }
  },

  computed: {
    icon () {
      return this.isToggled ? icArrowSmallUp : icArrowSmallDown
    },
  },

  methods: {
    track (item) {
      this.$emit('track', item)
    },

    toggle () {
      this.isToggled = !this.isToggled
    },
  },

}
