const setListing = (state, listing) => {
  Object.assign(state, { listing })
}

const clearListing = (state) => {
  Object.assign(state, { listing: null })
}

const setPublisher = (state, publisher) => {
  Object.assign(state, { publisher })
}

const setLastModified = (state, lastModified) => {
  Object.assign(state, { lastModified })
}

const setAdvertiserPartner = (state, { advertiserPartner }) => {
  Object.assign(state, { advertiserPartner })
}

export default {
  setListing,
  clearListing,
  setPublisher,
  setLastModified,
  setAdvertiserPartner,
}
