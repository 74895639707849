// @ts-check

import defineSelectorsOf from '../core/define-selectors-of'
import { makeFlowType } from '../core/tracking'

/** @type {SelectorFactory<import('./state').ResultsState>} */
const defineSelector = defineSelectorsOf('results')

/**
 * @typedef {{
 *  flowType: import('../core/tracking').FlowType
 * }} ResultsPageInfo
 */

/** @type {Selector<import('../core/tracking').FlowType>} */
const getFlowType = defineSelector((state) => {
  const { filters: { business, listingType } } = state
  return makeFlowType({ businessType: business, listingType })
})

/** @type {Selector<ResultsPageInfo>} */
export const getResultsPageInfo = defineSelector((state, select) => ({
  flowType: select(getFlowType),
}))
