const accountFields = 'id,name,logoUrl,licenseNumber,showAddress,legacyVivarealId,legacyZapId,config,createdDate'
const pageFields = 'metaContent,widgets,advertiserLinks,uriFragments,listingLinks'
const listingFields = 'sourceId,listingsCount,displayAddressType,amenities,usableAreas,constructionStatus,listingType,description,title,stamps,createdAt,deletedAt,floors,unitTypes,nonActivationReason,providerId,propertyType,unitSubTypes,unitsOnTheFloor,legacyId,id,portal,unitFloor,parkingSpaces,updatedAt,address,suites,publicationType,externalId,bathrooms,usageTypes,totalAreas,advertiserId,advertiserContact,bedrooms,whatsappNumber,acceptExchange,pricingInfos,showPrice,resale,buildings,capacityLimit,status,priceSuggestion,contractType'
const INCLUDE_FIELDS = `seasonalCampaigns,listing(${listingFields}),account(${accountFields}),medias,accountStats(totalCount),page(${pageFields}),preview,priceHistory,listingSummary,schema,lastModified,abmi(advertiserPartner)`
const externalSchedulerHost = 'woliver.net/agendamento'
const VALUE_STAMPS = {
  SALE: 'DATAZAP_APPROVED_SALE',
  RENTAL: 'DATAZAP_APPROVED_RENTAL',
}
export { INCLUDE_FIELDS, externalSchedulerHost, VALUE_STAMPS }
