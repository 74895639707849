import Vue from 'vue'
import ozProgressBar from '@/components/oz-progress-bar'
import deviceUtil from '@/utils/device'
import cookieStorage from '@/storage/cookie-storage'
import { routerReady, handleRouteGuardError } from '@/utils/core'
import { HttpStatusError } from '@/utils/error'
import { removeUnsafeQueries } from '@/router/policy/query'
import logger from '@/utils/logger'
import cuid from 'cuid'
import loginTracker from './app/login.tracker'
import createApp from '.'

const PREVIOUS_ANONYMOUS_ID = 'z_anonymous_id'
const ANONYMOUS_ID = 'z_user_id'

const makeAnonymousId = () => {
  let anonymousId = cookieStorage.get(ANONYMOUS_ID) ?? cookieStorage.get(PREVIOUS_ANONYMOUS_ID)

  if (!anonymousId) {
    if (!__DEV__) {
      logger.warn('Não foi possível identificar o `anonymousId`, um novo foi gerado temporariamente')
    }

    anonymousId = cuid()
    cookieStorage.set(ANONYMOUS_ID, anonymousId, { expires: 1 })
  }

  return anonymousId
}

/**
 * @param {import('@/store/auth/state').User | null} user
 * @returns {import('./store/leads/state').Lead}
 */
export function toContact (user) {
  if (!user) {
    return null
  }

  const {
    nome, sobrenome, email, dddCelular, celular,
  } = user

  return {
    name: `${nome ?? ''} ${sobrenome ?? ''}`.trim(),
    email,
    phoneNumber: dddCelular && celular ? `${dddCelular}${celular}` : undefined,
    acceptTerms: user.termsOptIns,
  }
}

const invokeClientEntry = async () => {
  const { __INITIAL_STATE__ } = window
  removeUnsafeQueries()

  /* eslint-disable-next-line no-multi-assign */
  const bar = Vue.prototype.$bar = new Vue(ozProgressBar).$mount()
  window.document.body.appendChild(bar.$el)
  const { context = {} } = __INITIAL_STATE__
  const device = deviceUtil.getCurrentMediaConfig()

  // Fix scrollRestoration conflict with vue-router behavior
  // https://github.com/vuejs/vue-router/pull/1814
  if ('scrollRestoration' in window.history) {
    window.history.scrollRestoration = 'manual'
  }

  const { app, router, store } = await createApp({
    context,
    device,
    async afterApp ({ store: appStore }) {
      // Keep store modules from localstorage
      const {
        leads: leadsState, recommendations, auth:
        { token: stateToken, user: stateUser },
      } = appStore.state || {}

      const token = stateToken || cookieStorage.getAccessToken()
      const user = cookieStorage.getUser() || stateUser

      let leads = leadsState

      if (user) {
        // The portal-webapp didn't store the contact information in the `lead` structure
        // so we need to adapt it from the `user` structure
        leads = { ...leads, lead: { ...leads.lead, ...toContact(user) } }
      }

      const auth = {
        authenticated: !!token,
        user,
        token,
        anonymousId: makeAnonymousId(),
      }

      appStore.replaceState({
        ...__INITIAL_STATE__, device, leads, recommendations, auth,
      })

      window.googleOneTapLogin = ({ credential }) => {
        loginTracker.loginFlow({
          type: 'CLICK',
          linkLabel: 'ONE TAP',
          trig: 'login google',
          refererUrl: window.location.href,
        })

        store.dispatch('auth/googleOneTapLogin', credential, { root: true })
      }
    },
  })

  router.beforeEach((to, from, next) => {
    bar.start()
    next()
  })

  router.afterEach(() => {
    bar.finish()
  })

  Vue.mixin({
    async beforeRouteUpdate (to, from, next) {
      try {
        const { asyncData } = await this.$options
        if (asyncData) {
          await asyncData({
            store: this.$store,
            route: to,
          })
        }
        next()
      } catch (err) {
        next(handleRouteGuardError(err))
      }
    },
  })

  router.beforeResolve(async (to, from, next) => {
    if (!from.name) return next() // do not resolve asyncData on server render - already been done

    const matched = router.getMatchedComponents(to)
    const prevMatched = router.getMatchedComponents(from)

    let diffed = false
    const activated = matched.filter((c, i) => {
      if (diffed) return diffed
      diffed = prevMatched[i] !== c
      return diffed
    })

    const asyncDataHooks = activated.map((c) => c.asyncData).filter((_) => _)
    if (!asyncDataHooks.length) {
      return next()
    }

    try {
      await Promise.all(asyncDataHooks.map(async (hook) => {
        await hook({ store, route: to })
      }))
      next()
    } catch (err) {
      next(handleRouteGuardError(err))
    }

    return null
  })

  // Wait until router has resolved all async before hooks
  // and async components...
  async function main () {
    await routerReady(router)

    if (router.getMatchedComponents().length === 0) {
      throw new HttpStatusError(
        'Http server error',
        404,
        router.currentRoute.path,
      )
    }
  }

  const mountApp = (appComp) => {
    appComp.$mount('#app')
  }

  main()
    .then(() => {
      mountApp(app)
    })
    .catch((err) => {
      const errorHandler = Vue.config.errorHandler || logger.error
      if (errorHandler(err)) {
        mountApp(app)
      }
    })
}

invokeClientEntry()
