const mapUser = ({ name, emails: [{ email }], uuid }) => {
  const arr = name.split(' ')
  const [firstName] = arr
  const [lastName] = arr.reverse()

  return {
    celular: '',
    cep: '',
    ddd: '',
    dddCelular: '',
    email,
    nome: firstName,
    sexo: '',
    sobrenome: lastName,
    telefone: '',
    termsOptIns: '',
    uuid,
  }
}

export default {
  mapUser,
}
