import apiUserLocation from '@/api/user-location'

const fetchIpLocation = async (store, { resolve, reject }) => {
  try {
    const { data } = await apiUserLocation.getIPLocation()
    store.commit('setUserLocation', { ...data, source: 'ip' })
    resolve(data)
  } catch (e) {
    reject(e)
  }
}

/**
 * Tries to get native user's location so, in case of denial, try to estimate by IP address
 *
 * @param {Object} store Vuex Store
 * @param {Object} [config] Config object
 * @param {Object} [config.options] {@link https://developer.mozilla.org/pt-BR/docs/Web/API/PositionOptions|PositionOptions}
 * @param {Boolean} [config.ipFallback] try to estimate via IP in case of user's denial
 * @param {Boolean} [config.ipOnly] skip native API and go direct into IP fetching
 * @param {Boolean} [config.skipCache] skip cached IP position, asks API
 * @returns {Promise} Promise object with geolocation data or error object
 */
const setUserLocation = (store, {
  options,
  ipFallback = true,
  ipOnly = false,
  skipCache = false,
} = {}) => new Promise((resolve, reject) => {
  if (ipOnly) {
    const cached = store.state.userLocation
    if (!cached || skipCache) {
      store.dispatch('fetchIpLocation', { resolve, reject })
      return
    }
    resolve(cached)
    return
  }

  navigator.geolocation.getCurrentPosition(
    (data) => {
      const { coords: { latitude, longitude, accuracy } } = data
      const userLocation = {
        accuracy,
        latitude,
        longitude,
        source: 'native',
      }

      store.commit('setUserLocation', userLocation)
      resolve(userLocation)
    },
    (e) => {
      if (ipFallback) {
        store.dispatch('fetchIpLocation', { resolve, reject })
        return
      }
      reject(e)
    },
    options,
  )
})

export default {
  setUserLocation,
  fetchIpLocation,
}
