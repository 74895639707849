// @ts-check

/**
 * @template {keyof Console} T
 * @param {T} method
 * @returns {Console[T]}
 */
function bind (method) {
  // eslint-disable-next-line no-console
  return console[method].bind(console)
}

const logger = {
  warn: bind('warn'),
  error: bind('error'),
}

export default logger
