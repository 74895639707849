const AMENITIES = {
  ELEVATOR: {
    singular: 'Elevador',
    icon: 'icElevator',
    nameSynonym: 'ELEVATOR',
  },
  POOL: {
    singular: 'Piscina',
    icon: 'icPool',
    nameSynonym: 'POOL',
  },
  HEATED_POOL: {
    singular: 'Piscina aquecida',
    icon: 'icPool',
    nameSynonym: 'POOL',
  },
  COVERED_POOL: {
    singular: 'Piscina coberta',
    icon: 'icPool',
    nameSynonym: 'POOL',
  },
  CHILDRENS_POOL: {
    singular: 'Piscina infantil',
    icon: 'icPool',
    nameSynonym: 'POOL',
  },
  ADULT_POOL: {
    singular: 'Piscina para adulto',
    icon: 'icPool',
    nameSynonym: 'POOL',

  },
  PRIVATE_POOL: {
    singular: 'Piscina privativa',
    icon: 'icPool',
    nameSynonym: 'POOL',
  },
  SEMI_OLYMPIC_POOL: {
    singular: 'Piscina semi-olímpica',
    icon: 'icPool',
    nameSynonym: 'POOL',
  },
  BARBECUE_GRILL: {
    singular: 'Churrasqueira',
    icon: 'icDrink',
    nameSynonym: 'BARBECUE_GRILL',
  },
  GYM: {
    singular: 'Academia',
    icon: 'icGymFilled',
    nameSynonym: 'GYM',
  },
  DISABLED_ACCESS: {
    singular: 'Acesso para deficientes',
    icon: 'icChair',
    nameSynonym: 'DISABLED_ACCESS',
  },
  BICYCLES_PLACE: {
    singular: 'Bicicletário',
    icon: 'icBike',
    nameSynonym: 'BICYCLES_PLACE',
  },
  TOYS_PLACE: {
    singular: 'Brinquedoteca',
    icon: 'icBrinquedoteca',
    nameSynonym: 'TOYS_PLACE',
  },
  COWORKING: {
    singular: 'Coworking',
    icon: 'icCowork',
    nameSynonym: 'COWORKING',
  },
  FOOTBALL_FIELD: {
    singular: 'Campo de futebol',
    icon: 'icBall',
    nameSynonym: 'FIELD',
  },
  GOLF_FIELD: {
    singular: 'Campo de golfe',
    icon: 'icBall',
    nameSynonym: 'FIELD',
  },
  INDOOR_SOCCER: {
    singular: 'Quadra de futebol de salão',
    icon: 'icBall',
    nameSynonym: 'FIELD',
  },
  SQUASH: {
    singular: 'Quadra de squash',
    icon: 'icBall',
    nameSynonym: 'FIELD',
  },
  TENNIS_COURT: {
    singular: 'Quadra de tênis',
    icon: 'icBall',
    nameSynonym: 'FIELD',
  },
  SPORTS_COURT: {
    singular: 'Quadra poliesportiva',
    icon: 'icBall',
    nameSynonym: 'FIELD',
  },
  MINI_SQUARE: {
    singular: 'Mini quadra',
    icon: 'icBall',
    nameSynonym: 'FIELD',
  },
  SAND_PIT: {
    singular: 'Quadra de areia',
    icon: 'icBall',
    nameSynonym: 'FIELD',
  },
  GOURMET_SPACE: {
    singular: 'Espaço gourmet',
    icon: 'icGlassMusic',
    nameSynonym: 'GOURMET_SPACE',
  },
  GREEN_SPACE: {
    singular: 'Espaço verde',
    icon: 'icTree',
    nameSynonym: 'GREEN_SPACE',
  },
  GUEST_PARKING: {
    singular: 'Estacionamento para visitantes',
    icon: 'icCar',
    nameSynonym: 'GUEST_PARKING',
  },
}

export default AMENITIES
