const setListings = (state, listings) => {
  state.listings = Object.freeze(listings)
}

const setSeasonalCampaigns = (state, seasonalCampaigns) => {
  state.seasonalCampaigns = [...seasonalCampaigns]
}

const SPGABsetSuperPremiumListings = (state, listings) => {
  state.superPremiumListings = Object.freeze(listings)
}

const SPGABappendSuperPremiumListings = (state, listings) => {
  state.superPremiumListings = [...state.superPremiumListings, ...listings]
}

const SPGABclearSuperPremiumListings = (state) => {
  state.superPremiumListings = []
}

const setFilters = (state, filters) => {
  Object.assign(state, { filters })
}

const setLevels = (state, levels) => {
  state.levels = levels
}

const setShowMainLocations = (state, isMainLocationVisible) => {
  Object.assign(state, { isMainLocationVisible })
}

const setPagination = (state, pagination) => {
  Object.assign(state, { pagination })
}

const setLoading = (state, loading) => {
  Object.assign(state, { loading })
}

const paginate = (state, page) => {
  state.pagination = {
    ...state.pagination,
    currentPage: page,
  }
}

const sort = (state, sortParam) => {
  Object.assign(state.filters, { sort: sortParam })
}

const setContentResultPage = (
  state,
  contentResultPage = { publicityContent: {} },
) => {
  state.contentResultPage = contentResultPage
}

const setIsShowMobileMapButton = (state, isShowMobileMapButton) => {
  state.isShowMobileMapButton = isShowMobileMapButton
}

const setIsShowRefineSearchOnMobile = (state, isShowRefineSearchOnMobile) => {
  state.isShowRefineSearchOnMobile = isShowRefineSearchOnMobile
}

const setIsMapOpen = (state, isMapOpen) => {
  state.isMapOpen = isMapOpen
}

const setFacets = (state, facets = {}) => {
  state.facets = facets
}

export default {
  setListings,
  setSeasonalCampaigns,
  SPGABsetSuperPremiumListings,
  SPGABappendSuperPremiumListings,
  SPGABclearSuperPremiumListings,
  setFilters,
  setPagination,
  setLoading,
  paginate,
  sort,
  setShowMainLocations,
  setIsShowMobileMapButton,
  setIsShowRefineSearchOnMobile,
  setIsMapOpen,
  setLevels,
  setContentResultPage,
  setFacets,
}
