const transformAdvertisersLinks = (links, category) => {
  if (!Array.isArray(links)) return []

  if (category === 'ADVERTISERS_AVAILABLE') {
    return links.map((item) => {
      if (item.href && !item.id) {
        const [id] = item.href.match(/\d+/g)
        item.id = id
      }

      return item
    })
  }

  return links
}

export default transformAdvertisersLinks
