import baseSchema from '@/utils/tracking/base-schema'

const mountEventData = (store, eventData) => {
  // TODO Think how to handle error pages for tracking
  if (store.state.errors.httpStatus !== null) {
    return {}
  }

  return {
    _clear: true,
    ...baseSchema(store),
    ...eventData,
  }
}

export default mountEventData
