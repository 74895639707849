const FALLBACK_HOME_PAGE_DATA = {
  header: {
    title: 'Default',
    image: {
      url: 'https://portal-cms-prod.s3.amazonaws.com/NEW_HERO_ZAP_c35d24a89f.jpeg',
    },
    altImage: 'homem sorrindo',
    h2: 'Pronto pra descobrir mais de __6 milhões__ de imóveis?',
    h3: 'ZAP, pronto pra toda mudança.',
  },
  showcase: [
    {
      h3: 'Mobiliados para locação',
      image: {
        url: 'https://portal-cms-prod.s3.amazonaws.com/mobiliados_locacao_b7a42f9934.png',
      },
      altImage: 'Dois homens pendurando um quadro na parede.',
      linkReference: 'aluguel/?transacao=Aluguel&amenities=Mobiliado&tipo=Imóvel%20usado&pagina=1',
      subject: 'FURNISHED',
      bodyCopy: 'Não quer gastar com móveis agora? Veja os melhores __imóveis mobiliados para alugar__. Pronto para entrar e morar.',
      order: 1,
    },
    {
      h3: 'Mais espaço para a família',
      image: {
        url: 'https://portal-cms-prod.s3.amazonaws.com/mais_espaco_familia_04b9acc3e5.png',
      },
      altImage: 'Casal dando risada',
      linkReference: 'venda/?quartos=2&transacao=Venda&areaMinima=80&tipo=Imóvel%20usado&pagina=1',
      subject: 'SPACE FAMILY',
      bodyCopy: 'Se é varanda e áreas comuns que você procura, veja essa seleção de __apartamentos e casas à venda__.',
      order: 2,
    },
    {
      h3: 'Aceita pets',
      image: {
        url: 'https://portal-cms-prod.s3.amazonaws.com/aceita_pets_3b4c659004.png',
      },
      altImage: 'Duas mulheres dando risada com dois cachorros',
      linkReference: 'aluguel/?transacao=Aluguel&amenities=Aceita%20animais&tipo=Imóvel%20usado&pagina=1',
      subject: 'ACCEPTS PET',
      bodyCopy: 'Seus patudos junto com você.  Encontre as melhores ofertas de __aluguel de imóveis__ para você e seu pet.',
      order: 3,
    },
    {
      h3: 'Aluguel sem fiador',
      image: {
        url: 'https://portal-cms-prod.s3.amazonaws.com/aluguel_sem_fiador_329cb13490.png',
      },
      altImage: 'Mulher sorrindo com seu celular na mão',
      linkReference: 'aluguel/?warranties=zapway&transacao=Aluguel&tipo=Imóvel%20usado',
      subject: 'NO GUARANTOR',
      bodyCopy: 'Alugar o imóvel dos sonhos nunca foi tão fácil! Encontre os melhores __imóveis para alugar__.',
      order: 4,
    },
  ],
  banner: {
    title: 'Default',
    image: {
      url: 'https://portal-cms-prod.s3.amazonaws.com/happy_family_b4aca42f24.png',
    },
    altImage: 'Banner roxo: Família feliz de 3 pessoas sorrindo',
    textColor: '#FFFFFF',
    backgroundColor: '#5908AD',
    icon: {
      url: 'https://portal-cms-prod.s3.amazonaws.com/orange_white_arrow_81825c5391.svg',
    },
    linkReference: 'https://www.zapimoveis.com.br/aluguel/?warranties=zapway&transacao=Aluguel&tipo=Im%C3%B3vel%20usado',
    subject: 'Alugar sem fiador',
    bodyCopy: 'Simples, rápido e sem burocracia.',
    h3: 'Aluguel sem fiador? \nConheça o ZAPway+',
  },
  blog: {
    title: 'Blog do Zap',
    h2: 'Blog do ZAP',
    linkReference: 'https://www.zapimoveis.com.br/blog/',
  },
}

export default { FALLBACK_HOME_PAGE_DATA }
