// @ts-check

import apiLead from '@/api/lead'
import { getRecommendations } from '@/api/recommendations'
import { transformRecommendation } from '@/utils/transformers/recommendations'
import { LEAD_TYPE } from '@/utils/constants'
import { getAnonymousId } from '@/store/auth/selector'
import { useSelect } from '../core/use-select'
import tracker from '@/utils/tracking/lead/lead-clicked'
import useAldo from '../core/use-aldo'
import assert from '@/utils/assert'
import { PAGE_CATEGORY, getPageCategory } from '../page/selectors'
import defineSelector from '../core/define-selector'
import { isDeduplicationEnabled } from '../listing-deduplication'

/**
 * @param {import('@/store/listing/state').Listing} listing
 * @returns {import('@/store/core/types').BusinessType}
 */
function inferBusinessType (listing) {
  return listing.pricingInfo?.businessType ?? listing.pricingInfos[0]?.businessType ?? 'SALE'
}

/** @typedef {import('vuex').ActionContext<import('./state').LeadsState, any>} ActionContext */

/**
 * @typedef {Object} TrackingData
 * @property {import('@/utils/tracking/lead/lead-clicked').TrackingProps} email
 * @property {import('@/utils/tracking/lead/lead-clicked').TrackingProps} phone
 * @property {import('@/utils/tracking/lead/lead-clicked').TrackingProps} whatsapp
 * @property {import('@/utils/tracking/lead/lead-clicked').PaginationProps} [pagination]
 * @property {number} [listingPosition]
 */

/**
 * @typedef {Object} TrackingOptions
 * @property {TrackingData} data
 * @property {object} [filters]
 * @property {object} [rpBusiness]
 * @property {object} [metaContent]
 * @property {string} [subject]
 */

/**
 * @typedef {Object} SendLeadOptions
 * @property {TrackingOptions} [tracking]
 * @property {{subject?: string; screen?: string} | boolean} [feedback]
 */

/**
 * @template {object} T
 * @typedef {T & { options: SendLeadOptions }} WithOptions
 */

/**
 * @param {import('../core/use-aldo').LeadInput} input
 */
function mustAcceptTerms (input) {
  return input.type === LEAD_TYPE.EMAIL
}

/**
 * @param {WithOptions<import('../core/use-aldo').LeadInput>} input
 */
// eslint-disable-next-line complexity
const handleNecessaryParams = (input) => {
  const errorMessage = 'is necessary to send a lead'
  if (!input.listing) throw new Error(`Listing ${errorMessage}`)
  if (!input.publisher) throw new Error(`Publisher ${errorMessage}`)
  if (!input.type) throw new Error(`Lead type ${errorMessage}`)
  if (mustAcceptTerms(input) && !input.acceptTerms) {
    throw new Error(`Accept terms ${errorMessage}`)
  }
  if (input.options?.feedback && !input.options?.tracking?.data) throw new Error(`TrackingData ${errorMessage} when feedback is true`)
}

/**
 * @param {ActionContext} context
 */
function closeModals (context) {
  context.commit('ui/modals/setLeadModal', { leadModal: { show: false } }, { root: true })
  context.commit('ui/modals/setWhatsappFormModal', { whatsappFormModal: { show: false } }, { root: true })
}

/**
 * @param {ActionContext} context
 * @param {import('../core/use-aldo').LeadInput
 *  & { lead: import('../core/use-aldo').Lead }} input
 * @param {SendLeadOptions} options
 */
function handleFeedback (context, input, { feedback, tracking }) {
  feedback = typeof feedback === 'boolean' ? {} : feedback

  context.commit('ui/modals/setPostLeadModal', {
    postLeadModal: {
      type: input.type,
      show: true,
      listingId: input.listing.id,
      listing: input.listing,
      publisher: input.publisher,
      trackingData: tracking?.data,
      subject: feedback?.subject,
      screen: feedback?.screen,
    },
  }, { root: true })

  closeModals(context)
}

/**
 *
 * @param {TrackingData} data
 * @param {{type: import('@/utils/constants/leads').LeadType, subject?: string}} type
 * @returns {import('@/utils/tracking/lead/lead-clicked').TrackingProps}
 */
function encodeTrackingData (data, { type, subject }) {
  const base = (() => {
    switch (type) {
      case LEAD_TYPE.EMAIL:
        return data.email
      case LEAD_TYPE.PHONE:
        return data.phone
      case LEAD_TYPE.WHATSAPP:
        return data.whatsapp
      default:
        throw new Error(`Invalid lead type ${type}`)
    }
  })()

  return { ...base, subject: subject ?? base.subject }
}

/**
 * @param {TrackingOptions & { type: import('@/utils/constants/leads').LeadType }} options
 */
function encodeTrackingProps ({ data, subject, type }) {
  return {
    tracking: encodeTrackingData(data, { type, subject }),
    pagination: data.pagination,
    listingPosition: data.listingPosition,
  }
}

/**
 * @param {ActionContext} context
 * @param {import('../core/use-aldo').LeadInput & { lead: import('../core/use-aldo').Lead }} lead
 * @param {SendLeadOptions} options
 */
function handleTracking (context, { lead, type, listing }, { tracking }) {
  if (!tracking) {
    throw new Error('Tracking options are required when feedback is true')
  }

  const filters = context.rootGetters['results/filters']

  tracker.leadClicked({
    type,
    listing,
    lead,
    ...encodeTrackingProps({ ...tracking, type }),
    filters,
    businessType: tracking.rpBusiness || filters.business || inferBusinessType(listing),
  })
}

const PAGES_WITH_DEDUPLICATION = new Set([PAGE_CATEGORY.listing, PAGE_CATEGORY.inactive])

/**
 * @param {WithOptions<import('../core/use-aldo').LeadInput>} payload
*/
function isDeduplicationEnabledOf (payload) {
  return defineSelector((_, select) => select(isDeduplicationEnabled)
      && (payload.type === LEAD_TYPE.EMAIL || payload.type === LEAD_TYPE.PHONE)
      && PAGES_WITH_DEDUPLICATION.has(select(getPageCategory)))
}

/**
 * @param {ActionContext} store
 * @param {WithOptions<import('../core/use-aldo').LeadInput>} input
 */
const sendLead = async (store, input) => {
  handleNecessaryParams(input)

  const { options, ...payload } = input

  const select = useSelect(store)
  const aldo = useAldo(store)

  store.commit('setLoading', true)

  try {
    if (select(isDeduplicationEnabledOf(input))) {
      const encoded = options.tracking && encodeTrackingProps({
        ...options.tracking,
        type: payload.type,
      })

      assert(encoded)

      const { tracking, ...trackingProps } = encoded

      await store.dispatch('listingDeduplication/submit', {
        ...payload,
        tracking: {
          ...tracking,
          ...trackingProps,
        },
      }, { root: true })

      closeModals(store)
      return
    }

    const lead = await aldo.send(payload)

    store.commit('setLead', lead)
    store.commit('auth/updateContactInfo', lead, { root: true })

    const withLead = { ...payload, lead }

    if (input.options?.feedback) {
      handleFeedback(store, withLead, options)
    }

    if (input.options?.tracking) {
      handleTracking(store, withLead, options)
    }
  } finally {
    store.commit('setLoading', false)
  }
}

// TODO: this is almost duplicated
// with fetchRecommendations in store/recommendations/actions.js
// @ts-ignore
const fetchRecommendations = async (store, listing) => {
  const { id, listingType } = listing
  const source = listingType === 'DEVELOPMENT' ? 'DEVELOPMENT_POST_LEAD' : 'POST_LEAD'
  const { simplifiedPlatform: platform, glueEndpoint } = store.rootGetters
  const { domain } = store.rootState.context

  const params = {
    listing: id,
    source,
    user: getAnonymousId(store.rootState),
    slots: 1,
    quantity: 12,
    platform,
    domain,
    images: 'webp',
  }

  try {
    store.commit('setRecommendationsLoading', true)
    const {
      data: { recommendations: [recommendation] = [] },
    } = await getRecommendations(glueEndpoint, params)

    store.commit('setRecommendations', transformRecommendation(recommendation))
  } catch (e) {
    store.commit('setRecommendations', null)
  } finally {
    store.commit('setRecommendationsLoading', false)
  }
}

// @ts-ignore
const setLoading = async (store, loading) => {
  store.commit('setLoading', loading)
}

// @ts-ignore
const sendSimilarAlert = async ({ rootGetters, rootState }, { leadData, listing }) => {
  const { id, isDevelopment } = listing
  const xDeviceId = getAnonymousId(rootState)
  const { userInterestEndpoint } = rootGetters

  await apiLead.sendSimilar(userInterestEndpoint, {
    xDeviceId, lead: leadData, id, isDevelopment,
  })
}

export default {
  sendLead,
  fetchRecommendations,
  setLoading,
  sendSimilarAlert,
}

