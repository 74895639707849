const isBannerRendered = (id) => {
  let element = document.getElementById(id)
  const isRendered = !!(element && element.innerHTML)
  element = null
  return isRendered
}

const hasNotRenderedBanner = (ids = []) => (
  ids.some((bannerId) => !isBannerRendered(bannerId))
)

export default hasNotRenderedBanner
