import getBusinessType from '@/filters/business-type'
import { fromNowPerMonth } from '../moment'
import formatDecimal from '../format-decimal'

const getPrice = (value, fallback = '') => {
  if (value && value !== '' && value !== '0') return formatDecimal({ prefix: 'R$ ', value })
  return fallback
}

const maxPriceInPeriod = (priceHistory, period) => {
  const recentPhy = priceHistory.filter((item, i) => fromNowPerMonth(item.updatedAt) <= period
    || (i < priceHistory.length - 1 && fromNowPerMonth(priceHistory[i + 1].updatedAt) < period))
  return recentPhy.length && recentPhy.reduce((lastPrice, { price }) => (
    Math.max(price, lastPrice)
  ), 0)
}

const getPriceVariation = (priceHistory, price, businessType, rentalPeriod) => {
  let history
  priceHistory.forEach((findPriceHistory) => {
    if (findPriceHistory.businessType === businessType && (rentalPeriod !== 'RENTAL' || findPriceHistory.rentalPeriod === rentalPeriod)) {
      history = findPriceHistory
    }
  })

  if (!history) return null
  const { priceHistory: listPriceHistory } = history

  let maxPrice
  if (businessType === 'SALE') maxPrice = maxPriceInPeriod(listPriceHistory, 6)
  if (businessType === 'RENTAL') maxPrice = maxPriceInPeriod(listPriceHistory, 12)

  if (maxPrice) {
    const percentage = Math.floor(100 - ((price / maxPrice) * 100))

    if (percentage <= 0) return null
    return {
      average: getPrice(maxPrice),
      basePrice: maxPrice,
      percentage,
      difference: getPrice(Math.abs(Math.floor(price - maxPrice))),
      valued: price >= maxPrice,
    }
  }
  return null
}

const getSimpleRentalPeriod = (value) => {
  const map = {
    DAILY: 'dia',
    WEEKLY: 'semana',
    MONTHLY: 'mês',
    YEARLY: 'ano',
  }

  return map[value]
}

const formatPrice = (price, showPrice, fallback = 'Sob consulta') => {
  if (price && showPrice) return getPrice(price, fallback)
  return ''
}

const getRentalPrice = (pricingInfo, showPrice) => {
  const {
    price: rawPrice,
    yearlyIptu,
    businessType,
    monthlyCondoFee,
    rentalInfo: {
      period: rawPeriod,
      monthlyRentalTotalPrice,
      warranties = [],
    } = {},
  } = pricingInfo

  const rentalPrice = formatPrice(rawPrice, showPrice)
  const rentalTotal = getPrice(monthlyRentalTotalPrice)
  const rentalTotalPrice = rentalTotal !== rentalPrice ? rentalTotal : ''
  const period = getSimpleRentalPeriod(rawPeriod)

  return {
    isRent: true,
    price: rentalPrice,
    rawPrice,
    period,
    rawPeriod,
    warranties,
    rentalPrice,
    businessType,
    rentalTotalPrice,
    yearlyIptu: getPrice(yearlyIptu),
    monthlyCondoFee: getPrice(monthlyCondoFee),
  }
}

const getSalePrice = (pricingInfo, showPrice) => {
  const {
    price: rawPrice,
    yearlyIptu,
    monthlyCondoFee,
    businessType,
  } = pricingInfo

  const salePrice = formatPrice(rawPrice, showPrice)

  return {
    isSale: true,
    price: salePrice,
    rawPrice,
    salePrice,
    businessType,
    yearlyIptu: getPrice(yearlyIptu),
    monthlyCondoFee: getPrice(monthlyCondoFee),
  }
}

const mountPriceByBusinessType = {
  RENTAL: getRentalPrice,
  SALE: getSalePrice,
}

const isSmaller = (valueA, valueB) => Number(valueA) < Number(valueB)

const listingPricingInfo = (pricingInfos, showPrice, businessTypeContext, priceHistory) => {
  if (!pricingInfos?.length) { return {} }
  // TODO: add priceMap to this method return object, to allow
  // the usage in other places that needs to access the price
  // by the businessTypeContext value directly
  const priceMap = {}
  const result = {}
  const businessTypesSet = new Set()
  const [{ businessType: firstBusinessType } = {}] = pricingInfos

  pricingInfos.forEach((pricingInfo) => {
    const {
      businessType,
      price,
    } = pricingInfo

    let mappedPrice = priceMap[businessType]
    businessTypesSet.add(businessType)

    if (!mappedPrice || isSmaller(price, mappedPrice.rawPrice)) {
      mappedPrice = mountPriceByBusinessType[businessType](pricingInfo, showPrice)
      priceMap[businessType] = mappedPrice
      Object.assign(result, mappedPrice)
    }
  })

  const {
    price,
    rawPrice,
    yearlyIptu,
    monthlyCondoFee,
  } = priceMap[businessTypeContext] || priceMap[firstBusinessType]

  const businessTypeName = [...businessTypesSet].join('_')

  const priceVariation = priceHistory
    ? getPriceVariation(priceHistory, rawPrice, businessTypeName, result.rawPeriod) : null

  return {
    ...result,
    ...getBusinessType(businessTypeName),
    priceMap,
    showPrice,
    yearlyIptu,
    priceVariation,
    monthlyCondoFee,
    businessTypeContext,
    price,
  }
}

export default listingPricingInfo
export { getPriceVariation }
