// @ts-check

import UAParser from 'ua-parser-js'
import { isFeatureEnabled } from '../context/selectors'
import defineSelectorsOf from '../core/define-selectors-of'

const GOOGLE_CHROME = 'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/51.0.2704.103 Safari/537.36'

/**
 * @type {SelectorFactory<{
 *  platform: 'mobile' | 'desktop'
 * }>}
 */
const defineSelector = defineSelectorsOf('device')

/**
 * @param {string} ua
 * @returns {'MOBILE' | 'DESKTOP'}
 */
function inferPlatform (ua) {
  const parsed = new UAParser(ua)
  const device = parsed.getDevice().type ?? 'embedded'
  return device === 'mobile' ? 'MOBILE' : 'DESKTOP'
}

function userAgent () {
  return (typeof window === 'undefined' ? undefined : window.navigator.userAgent) ?? GOOGLE_CHROME
}

const getPlatformFromState = defineSelector((state) => (state.platform === 'mobile' ? 'MOBILE' : 'DESKTOP'))

export const getPlatform = defineSelector((state, select) => {
  const inferPlatformFromUserAgent = select(isFeatureEnabled('newPlatformTrackingZap'))

  const platform = inferPlatformFromUserAgent
    ? inferPlatform(userAgent())
    : select(getPlatformFromState)

  return platform
})
