const BUSINESS_TYPE_MAP = {
  SALE: 'SALE',
  RENTAL: 'RENTAL',
}

const BUSINESS_TYPE_OBJECT_MAP = {
  SALE: {
    businessType: 'SALE',
    businessLabel: 'Comprar',
    businessDescription: 'para comprar',
    isSale: true,
    isRent: false,
  },
  RENTAL: {
    businessType: 'RENTAL',
    businessLabel: 'Alugar',
    businessDescription: 'para alugar',
    isSale: false,
    isRent: true,
  },
  SALE_RENTAL: {
    businessType: 'SALE_RENTAL',
    businessLabel: 'Comprar/Alugar',
    businessDescription: 'para comprar e alugar',
    isSale: true,
    isRent: true,
  },
  RENTAL_SALE: {
    businessType: 'RENTAL_SALE',
    businessLabel: 'Alugar/Comprar',
    businessDescription: 'para alugar e comprar',
    isSale: true,
    isRent: true,
  },
}

const BUSINESS_FILTER_MAP = [
  { listingType: 'USED', ...BUSINESS_TYPE_OBJECT_MAP.SALE },
  { listingType: 'USED', ...BUSINESS_TYPE_OBJECT_MAP.RENTAL },
  {
    businessType: 'SALE',
    businessLabel: 'Lançamentos',
    businessDescription: 'para comprar',
    isSale: true,
    isRent: false,
    listingType: 'DEVELOPMENT',
  },
]

const BUSINESS_FILTER_RENTAL_SALE_MAP = [
  { listingType: 'USED', ...BUSINESS_TYPE_OBJECT_MAP.SALE },
  { listingType: 'USED', ...BUSINESS_TYPE_OBJECT_MAP.RENTAL },
]

const getBusinessType = (type) => (BUSINESS_TYPE_OBJECT_MAP[type])

export default getBusinessType
export { BUSINESS_TYPE_MAP, BUSINESS_FILTER_MAP, BUSINESS_FILTER_RENTAL_SALE_MAP }
