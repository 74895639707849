import { getByPath } from '@/api/page'
import transformerListing from '@/utils/transformers/listing'
import transformWidgets from '@/utils/transformers/widgets'
import localStorage from '@/storage/local-storage'
import handlePagination from '@/utils/pagination'

const chunkListings = (listings, size) => {
  const list = []

  for (let i = 0; i < listings.length; i += size) {
    list.push(listings.slice(i, i + size))
  }

  return list
}

const sortListings = (listings, sort, business) => {
  if (sort !== 'price') return listings

  const sortedListings = listings.sort((a, b) => {
    const { rawPrice: aPrice } = a.listing.pricingInfo.priceMap[business]
    const { rawPrice: bPrice } = b.listing.pricingInfo.priceMap[business]
    return Number(aPrice) - Number(bPrice)
  })

  return sortedListings
}

// eslint-disable-next-line complexity
const getListings = async (store, { translatedQuery, routeQuery = {} }) => {
  try {
    store.commit('markdown/setLoading', true, { root: true })

    const { glueEndpoint, currentPath } = store.rootGetters
    const { domain } = store.rootState.context

    const variations = store.rootGetters['ab/getAllVariationNames']
    const hideSummary = true

    const { pagina: page } = routeQuery
    const sort = routeQuery.ordem === 'Valor' ? 'price' : undefined

    const response = await getByPath(
      glueEndpoint,
      currentPath,
      {
        ...translatedQuery,
        variations,
        domain,
        hideSummary,
        images: 'webp',
        ...(page && { page }),
        ...(sort && { sort }),
      },
      routeQuery,
    ) || {}

    if (!response.data) return

    const {
      data: {
        page: {
          uriFragments: { business, address },
          uriPagination,
          metaContent,
          widgets,
        },
        search: {
          result: { listings: items },
        },
      },
    } = response

    const businessTypeContext = localStorage.getBusinessTypeContext()

    const {
      rootState: {
        context: {
          glossary,
        },
      },
    } = store

    const listings = items.map((item) => ({
      link: item.link,
      account: item.account,
      listing: transformerListing({
        ...item,
        alternateAddress: item.link.data,
        businessTypeContext,
        glossary,
      }),
      type: item.type,
    }))

    const { size } = uriPagination

    const filters = {
      addresses: [address],
      business,
      listingType: 'USED',
      size,
      sort,
    }

    const pagination = handlePagination(uriPagination)

    if (listings.length === 0) {
      metaContent.title = 'Resultado não encontrado'
      metaContent.description = 'Não encontramos nenhum imóvel'
    }

    store.commit('markdown/setListings', { listings }, { root: true })
    store.commit('markdown/setFilters', filters, { root: true })
    store.commit('markdown/setPagination', pagination, { root: true })
    store.commit('page/setMetaContent', metaContent, { root: true })
    store.commit('page/setWidgets', transformWidgets(widgets), { root: true })
    store.commit('markdown/setLoading', false, { root: true })
  } catch (e) {
    const { code, location } = e

    if (location) {
      throw e
    }

    store.dispatch('errors/setHttpStatus', code, { root: true })
  }
}

export default {
  chunkListings,
  sortListings,
  getListings,
}
